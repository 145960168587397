:root {
    --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
      "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
      "Source Han Sans CN", sans-serif;
  }
  
  .main_container {
    overflow: hidden;
  }
  
  .main_container,
  .main_container * {
    box-sizing: border-box;
  }
  
  input,
  select,
  textarea,
  button {
    outline: 0;
  }
  
  .main_container {
    position: relative;
    width: 1440px;
    height: 3500px;
    margin: 0 auto;
    background: #ededed;
    border-radius: 20px;
    overflow: hidden;
  }

  .rectangle {
    position: relative;
    width: 1714px;
    height: 527px;
    margin: -40px 0 0 -95px;
    background: #ffffff;
    z-index: 207;
    overflow: visible auto;
  }
  .flex_row_a {
    position: relative;
    width: 1123px;
    height: 232px;
    margin: 67px 0 0 253px;
    z-index: 213;
  }
  .ellipse {
    position: absolute;
    width: 17.54%;
    height: 84.91%;
    top: 0;
    left: 5.97%;
    background: url(../assets/report_pages/images/26f6e9b1-e937-4577-b9b8-14103bd28c38.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 212;
    border-radius: 50%;
  }
  .overall {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    width: 8.28%;
    height: 15.52%;
    top: 11.21%;
    left: 10.69%;
    color: #ffffff;
    font-family: Microsoft Sans Serif, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    white-space: nowrap;
    z-index: 213;
  }
  .rectangle_1 {
    position: relative;
    width: 1123px;
    height: 194px;
    top: 38px;
    left: 50%;
    background: rgba(217, 217, 217, 0.5);
    transform: translate(-50%, 0);
    z-index: 209;
    border-radius: 25px;
  }
  .operational_command {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    width: 769px;
    height: 143px;
    /* margin-bottom: 40px; */
    top: 28px;
    left: 328px;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    white-space: normal;        /* 允许内容换行 */
    overflow-wrap: break-word;  /* 在单词过长时断行 */
    overflow: auto; 
    z-index: 210;
  }
  .operational_command::-webkit-scrollbar {
    width: 10px; /* 滚动条的宽度 */
    background-color: #f9f9f9; /* 滚动条的背景色 */
  }
  
  /* 滚动条滑块 */
  .operational_command::-webkit-scrollbar-thumb {
    background-color: #b3b3b3; /* 滑块的颜色 */
    border-radius: 5px; /* 滑块的圆角 */
    border: 2px solid #ffffff; /* 滑块边缘的颜色 */
  }

  .dot_eight_five {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 11.29%;
    height: 46.69%;
    top: 21%;
    left: 9.26%;
    color: #ffffff;
    font-family: Nunito, var(--default-font-family);
    font-size: 80px;
    font-weight: 400;
    line-height: 90.575px;
    text-align: center;
    white-space: nowrap;
    z-index: 800;
  }
  
  .flex_row_bff {
    position: relative;
    width: 1440px;
    height: 189px;
    margin: 39px 0 0 95px;
    z-index: 229;
  }
  .part_tab {
    position: absolute;
    width: 480px;
    height: 189px;
    top: 0;
    left: 0;
    background: #ffffff;
    border-bottom: 10px solid #505f98;
    z-index: 229;
  }
  .part_icon {
    position: absolute;
    width: 103px;
    height: 97px;
    top: 40px;
    left: 65px;
    background: url(../assets/report_pages/images/5c707f85d23443c2d24daaa936de90a361697288.png)
      no-repeat center;
    background-size: cover;
    z-index: 233;
  }
  .part_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 9px;
    position: absolute;
    width: 203px;
    height: 108px;
    top: 41px;
    left: 238px;
    z-index: 230;
  }
  .part_one {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 117px;
    height: 36px;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 40px;
    font-weight: 800;
    line-height: 36px;
    text-align: center;
    white-space: nowrap;
    z-index: 231;
  }
  .introduction_interview {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    width: 203px;
    height: 63px;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    z-index: 232;
  }
  .part_tab_2 {
    position: absolute;
    width: 480px;
    height: 189px;
    top: 0;
    left: 480px;
    background: #ffffff;
    z-index: 223;
  }
  .part_icon_3 {
    position: absolute;
    width: 103px;
    height: 97px;
    top: 40px;
    left: 65px;
    background: url(../assets/report_pages/images/42192e6691a340fee8f3290b4afd8912f3c487fc.png)
      no-repeat center;
    background-size: cover;
    z-index: 227;
  }
  .flex_column_c {
    position: absolute;
    width: 111px;
    height: 81px;
    top: 55px;
    left: 238px;
    font-size: 0px;
    z-index: 226;
  }
  .part_two {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    width: 111px;
    height: 36px;
    margin: 0 0 0 0;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 40px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    white-space: nowrap;
    z-index: 225;
  }
  .topic {
    display: block;
    position: relative;
    height: 36px;
    margin: 9px 0 0 0;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    white-space: nowrap;
    z-index: 226;
  }
  .part_tab_4 {
    position: absolute;
    width: 480px;
    height: 189px;
    top: 0;
    left: 960px;
    background: #ffffff;
    z-index: 217;
  }
  .part_icon_5 {
    position: absolute;
    width: 103px;
    height: 97px;
    top: 40px;
    left: 65px;
    background: url(../assets/report_pages/images/50ec18080ab536e70e6ddf226ee2637773e53955.png)
      no-repeat center;
    background-size: cover;
    z-index: 221;
  }
  .flex_column {
    position: absolute;
    width: 135px;
    height: 81px;
    top: 53px;
    left: 238px;
    font-size: 0px;
    z-index: 220;
  }
  .part_three {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    width: 111px;
    height: 36px;
    margin: 0 0 0 0;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 40px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    white-space: nowrap;
    z-index: 219;
  }
  .discussion {
    display: block;
    position: relative;
    height: 36px;
    margin: 9px 0 0 0;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    white-space: nowrap;
    z-index: 220;
  }
  .transcript {
    position: relative;
    width: 1380px;
    height: 392px;
    margin: 40px 0 0 30px;
    font-size: 0px;
    background: #ffffff;
    z-index: 300 ;
    overflow: visible auto;
    border-radius: 20px;
  }
  .recordings_transcripts {
    display: block;
    position: relative;
    height: 30px;
    margin: 15px 0 0 40px;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 30px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
    white-space: nowrap;
    z-index: 173;
  }
  .line {
    position: relative;
    width: 1303px;
    height: 3px;
    margin: 13.5px 0 0 38.5px;
    background: url(../assets/report_pages/images/a9534f8a-6847-4524-86c6-781af489b8ea.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 172;
  }
  .flex_row_aaf {
    position: relative;
    width: 1300px;
    height: 270px;
    margin: 28.5px 0 0 40px;
    z-index: 206;
  }
  .rectangle_6 {
    position: absolute;
    width: 43.77%;
    height: 12.59%;
    top: 0;
    left: 0;
    background: #dfe1eb;
    border-top: 3px solid #505f98;
    border-right: 3px solid #505f98;
    z-index: 192;
    border-radius: 20px;
  }
  .work_or_student {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 67.65%;
    top: 23.53%;
    left: 2.28%;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    z-index: 193;
  }
  .play_box {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 50%;
    background: #f7f7fa;
    border: 1px solid #dfe1eb;
    z-index: 174;
    border-radius: 15px;
  }
  .flex_row_bc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 612px;
    height: 40px;
    margin: 15px 0 0 15px;
    z-index: 184;
  }
  .question {
    flex-shrink: 0;
    position: relative;
    width: 110px;
    height: 40px;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    z-index: 184;
  }
  .question_two {
    position: relative;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
  .line_break {
    position: relative;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .bottled_water_or_machines {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    width: 500px;
    height: 40px;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    z-index: 183;
  }
  .rectangle_7 {
    position: relative;
    width: 620px;
    height: 45px;
    margin: 16px 0 0 15px;
    background: #dfe1eb;
    z-index: 177;
    border-radius: 45px;
  }
  .play_button {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 6.979px;
    left: 10.386px;
    background: url(../assets/report_pages/images/92e74ce0-5b56-41e1-adfe-21918b9ca9e9.png)
      no-repeat center;
    background-size: cover;
    z-index: 178;
  }
  .time_indicator {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 20.877px;
    top: 12.329px;
    left: 51.507px;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    z-index: 181;
  }
  .ellipse_8 {
    position: absolute;
    width: 15.149px;
    height: 15px;
    top: 14.706px;
    left: 155.319px;
    cursor: pointer;
    background: url(../assets/report_pages/images/666a4d77-e0d9-417d-a044-f00ef7e473fb.png)
      no-repeat center;
    background-size: cover;
    z-index: 180;
  }
  .line_9 {
    position: absolute;
    width: 448.707px;
    height: 8px;
    top: 18.283px;
    left: 156.294px;
    cursor: pointer;
    background: url(../assets/report_pages/images/1c03ecd6-7955-42de-a9d3-2c683568aa0f.png)
      no-repeat center;
    background-size: cover;
    
    z-index: 179;
  }
  .work_description {
    position: relative;
    width: 607.914px;
    height: 107.881px;
    margin: 16px 0 0 15px;
    overflow-y: auto; 
    z-index: 185;
  }

  .work_description::-webkit-scrollbar {
    width: 10px; /* 滚动条的宽度 */
    background-color: #f9f9f9; /* 滚动条的背景色 */
  }
  
  /* 滚动条滑块 */
  .work_description::-webkit-scrollbar-thumb {
    background-color: #b3b3b3; /* 滑块的颜色 */
    border-radius: 5px; /* 滑块的圆角 */
    border: 2px solid #ffffff; /* 滑块边缘的颜色 */
  }
  .work_description_a {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    width: 573px;
    height: 47px;
    top: 0;
    left: 0;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    z-index: 182;
  }

  .line_b {
    position: absolute;
    width: 1.32%;
    height: 100.45%;
    top: 3.25%;
    left: 99.34%;
    background: url(../assets/report_pages/images/83d3d052-219e-47d7-bac2-c22802cdb574.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 185;
  }
  .line_c {
    position: absolute;
    width: 0.62%;
    height: 58.15%;
    top: -0.37%;
    left: 45.77%;
    background: url(../assets/report_pages/images/ef339355-76d0-4c13-9e99-863e4cf3f624.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 206;
  }
  .rectangle_d {
    position: absolute;
    width: 43.77%;
    height: 12.59%;
    top: 16.3%;
    left: 0;
    background: #f7f7fa;
    z-index: 189;
    border-radius: 20px;
  }
  .work_or_student_e {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 67.65%;
    top: 23.53%;
    left: 2.28%;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    z-index: 190;
  }
  .work_or_student_f {
    position: absolute;
    width: 43.77%;
    height: 12.59%;
    top: 32.59%;
    left: 0;
    background: #f7f7fa;
    z-index: 195;
    border-radius: 20px;
  }
  .work_or_student_10 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 67.65%;
    top: 23.53%;
    left: 2.28%;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    z-index: 196;
  }
  .work_or_student_11 {
    position: absolute;
    width: 43.77%;
    height: 12.59%;
    top: 48.89%;
    left: 0;
    background: #f7f7fa;
    z-index: 198;
    border-radius: 20px;
  }
  .work_or_student_12 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 67.65%;
    top: 23.53%;
    left: 2.28%;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    z-index: 199;
  }
  .work_or_student_13 {
    position: absolute;
    width: 43.77%;
    height: 12.59%;
    top: 65.19%;
    left: 0;
    background: #f7f7fa;
    z-index: 201;
    border-radius: 20px;
  }
  .work_or_student_14 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 67.65%;
    top: 23.53%;
    left: 2.28%;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    z-index: 202;
  }
  .work_or_student_15 {
    position: absolute;
    width: 43.77%;
    height: 12.59%;
    top: 81.48%;
    left: 0;
    background: #f7f7fa;
    z-index: 204;
    border-radius: 20px;
  }
  .text_15 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 67.65%;
    top: 23.53%;
    left: 2.28%;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    z-index: 205;
  }
  .flex_row_e {
    position: relative;
    width: 1380px;
    height: 2734px;
    margin: 23px 0 0 30px;
    z-index: 257;
  }
  .evaluation_bar {
    position: absolute;
    top: 960px;
    left: 400px;
    z-index: 254;
  }
  .suggestion_bar {
    position: absolute;
    /* min-height: 1031px; */
    top: 0;
    /* left: 200px; */
    z-index: 254;
  }

  .Improvement_bar {
    position: absolute;
    /* min-height: 1031px; */
    top: 940px;
    left: 400px;
    z-index: 254;
  }
  .evaluation_rectangle_16 {
    position: absolute;
    width: 200px;
    height: 120px;
    top: 0;
    left: 40px;
    background: #f7f7fa;
    z-index: 2;
    border-radius: 20px;
  }
  .evaluation_vocab_icon {
    position: relative;
    width: 45px;
    height: 47px;
    margin: 9px 0 0 77px;
    background: url(../assets/report_pages/images/68c335b7c58f6633df387927a7598f121725c367.png)
      no-repeat center;
    background-size: cover;
    z-index: 4;
  }
  .evaluation_vocabulary {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    width: 133.668px;
    height: 23.116px;
    margin: 16px 0 0 33px;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 24px;
    font-weight: 700;
    line-height: 23.116px;
    text-align: center;
    white-space: nowrap;
    z-index: 3;
  }
  .evaluation_rectangle_17 {
    position: absolute;
    width: 200px;
    height: 120px;
    top: 0;
    left: 260px;
    background: #dfe1eb;
    z-index: 6;
    border-radius: 20px;
  }
  .evaluation_grammar_icon {
    position: relative;
    width: 50px;
    height: 60px;
    margin: 10px 0 0 75px;
    background: url(../assets/report_pages/images/21a1de561f5b7d455271db7a23c96b6d465315a8.png)
      no-repeat center;
    background-size: cover;
    z-index: 8;
  }
  .evaluation_grammar {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    width: 133.668px;
    height: 23.116px;
    margin: 3px 0 0 33px;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 24px;
    font-weight: 700;
    line-height: 23.116px;
    text-align: center;
    white-space: nowrap;
    z-index: 7;
  }
  .evaluation_rectangle_18 {
    position: absolute;
    width: 200px;
    height: 120px;
    top: 0;
    left: 480px;
    background: #dfe1eb;
    z-index: 10;
    border-radius: 20px;
  }
  .evaluation_fluency_icon {
    position: relative;
    width: 55px;
    height: 53px;
    margin: 10px 0 0 72px;
    background: url(../assets/report_pages/images/ef4e8196125455ea5b0d61e38e023e7d4703851e.png)
      no-repeat center;
    background-size: cover;
    z-index: 12;
  }
  .evaluation_fluency {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    width: 133.668px;
    height: 23.116px;
    margin: 10px 0 0 33px;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 24px;
    font-weight: 700;
    line-height: 23.116px;
    text-align: center;
    white-space: nowrap;
    z-index: 7;
  }
  .evaluation_rectangle_19 {
    position: absolute;
    width: 200px;
    height: 120px;
    top: 0;
    left: 700px;
    background: #dfe1eb;
    z-index: 14;
    border-radius: 20px;
  }
  .evaluation_pronounciation_icon {
    position: relative;
    width: 50px;
    height: 50px;
    margin: 10px 0 0 75px;
    background: url(../assets/report_pages/images/27df830c83767039071b9d311f36eea7252c86e1.png)
      no-repeat center;
    background-size: cover;
    z-index: 16;
  }
  .evaluation_pronunciation {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    width: 155px;
    height: 30px;
    margin: 10px 0 0 22px;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    white-space: nowrap;
    z-index: 15;
  }
  
  .sidebar {
    position: absolute;
    width: 200px;
    height: 100px;
    top: 950px;
    left: 30px;
    z-index: 254;
    border-radius: 20px;
  }
  .detailed_breakdown {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 41px;
    top: 7px;
    left: 0;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 30px;
    font-weight: 900;
    line-height: 40.92px;
    text-align: left;
    white-space: nowrap;
    z-index: 257;
  }
  .rectangle_1a {
    position: absolute;
    width: 340px;
    height: 110px;
    top: 80px;
    left: 0;
    background: #ffffff;
    border-bottom: 5px solid #505f98;
    border-right: 5px solid #505f98;
    z-index: 239;
    border-radius: 20px;
  }
  .evaluation_icon {
    position: absolute;
    width: 55px;
    height: 55px;
    top: 28px;
    left: 30px;
    background: url(../assets/report_pages/images/46e3ca0b11605e20cbe9eb18c32f823115ed6dac.png)
      no-repeat center;
    background-size: cover;
    z-index: 256;
  }
  .evaluations {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 30px;
    top: calc(50% - 16px);
    right: 33px;
    left: 122px;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 30px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
    white-space: nowrap;
    z-index: 255;
  }
  .rectangle_1b {
    position: absolute;
    width: 1000px;
    height: 2628px;
    top: 106px;
    left: 380px;
    background: #f7f7fa;
    z-index: 17;
    border-radius: 20px;
  }
  .rectangle_1c {
    position: relative;
    width: 940px;
    height: 536px;
    margin: 212px 0 0 30px;
    font-size: 0px;
    background: #ffffff;
    z-index: 20;
    overflow: visible auto;
    border-radius: 30px;
  }
  .vocabulary_distribution {
    display: block;
    position: relative;
    height: 30px;
    margin: 24px 0 0 30px;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 30px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
    white-space: nowrap;
    z-index: 103;
  }
  .line_1d {
    position: relative;
    width: 880px;
    height: 3px;
    margin: 7px 0 0 30px;
    background: url(../assets/report_pages/images/eadaaf4e-e4b4-4351-87c0-1afc964d3a4a.png)
      no-repeat center;
    background-size: cover;
    z-index: 104;
  }
  .advanced_vocabulary {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    width: 880px;
    height: 51px;
    margin: 10px 0 0 30px;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    z-index: 101;
  }
  .flex_row_cb {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 776px;
    height: 24px;
    margin: 23px 0 0 108px;
    z-index: 100;
  }
  .your_vocabulary_distribution {
    flex-shrink: 0;
    position: relative;
    height: 24px;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 61;
  }
  .typical_band_vocabulary_distribution {
    flex-shrink: 0;
    position: relative;
    width: 374px;
    height: 24px;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 100;
  }
  .typical {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    text-align: left;
  }
  .band_8 {
    position: relative;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    text-align: left;
  }
  .vocabulary_distribution_1e {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    text-align: left;
  }
  .flex_row {
    position: relative;
    width: 795px;
    height: 40px;
    margin: 15px 0 0 64px;
    z-index: 68;
  }
  .ellipse_1f {
    position: absolute;
    width: 5.03%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(../assets/report_pages/images/7a1137a5-fd25-4c98-b88b-4fc202bbbe5a.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 27;
    border-radius: 50%;
  }
  .rectangle_20 {
    position: absolute;
    width: 37.74%;
    height: 100%;
    top: 0;
    left: 6.29%;
    background: #dfe1eb;
    z-index: 29;
    border-radius: 5px;
  }
  .rectangle_21 {
    position: relative;
    width: 16px;
    height: 40px;
    margin: 0 0 0 0;
    background: #626fa2;
    z-index: 30;
    border-radius: 5px;
  }
  .ellipse_22 {
    position: absolute;
    width: 5.03%;
    height: 100%;
    top: 0;
    left: 55.97%;
    background: url(../assets/report_pages/images/c0de2ef6-0e91-469e-a809-19dce07862a2.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 66;
    border-radius: 50%;
  }
  .rectangle_23 {
    position: absolute;
    width: 37.74%;
    height: 100%;
    top: 0;
    left: 62.26%;
    background: #dfe1eb;
    z-index: 68;
    border-radius: 5px;
  }
  .rectangle_24 {
    position: relative;
    width: 16px;
    height: 40px;
    margin: 0 0 0 0;
    background: #626fa2;
    z-index: 69;
    border-radius: 5px;
  }
  .c2 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 60%;
    top: 20%;
    left: 0.75%;
    color: #ffffff;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 28;
  }
  .c2_25 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 60%;
    top: 20%;
    left: 56.73%;
    color: #ffffff;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 67;
  }
  .flex_row_f {
    position: relative;
    width: 795px;
    height: 40px;
    margin: 5px 0 0 64px;
    z-index: 74;
  }
  .ellipse_26 {
    position: absolute;
    width: 5.03%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(../assets/report_pages/images/7df266a5-92bc-4b35-8e96-be342e406713.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 33;
    border-radius: 50%;
  }
  .rectangle_27 {
    position: absolute;
    width: 37.74%;
    height: 100%;
    top: 0;
    left: 6.29%;
    background: #dfe1eb;
    z-index: 35;
    border-radius: 5px;
  }
  .rectangle_28 {
    position: relative;
    width: 16px;
    height: 40px;
    margin: 0 0 0 0;
    background: #626fa2;
    z-index: 36;
    border-radius: 5px;
  }
  .ellipse_29 {
    position: absolute;
    width: 5.03%;
    height: 100%;
    top: 0;
    left: 55.97%;
    background: url(../assets/report_pages/images/1611d33a-7cbe-4d39-b105-1b5d73709095.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 72;
    border-radius: 50%;
  }
  .rectangle_2a {
    position: absolute;
    width: 37.74%;
    height: 100%;
    top: 0;
    left: 62.26%;
    background: #dfe1eb;
    z-index: 74;
    border-radius: 5px;
  }
  .rectangle_2b {
    position: relative;
    width: 16px;
    height: 40px;
    margin: 0 0 0 0;
    background: #626fa2;
    z-index: 75;
    border-radius: 5px;
  }
  .c1 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 60%;
    top: 20%;
    left: 0.75%;
    color: #ffffff;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 34;
  }
  .c1_2c {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 60%;
    top: 20%;
    left: 56.73%;
    color: #ffffff;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 73;
  }
  .flex_row_cd {
    position: relative;
    width: 795px;
    height: 40px;
    margin: 5px 0 0 64px;
    z-index: 80;
  }
  .ellipse_2d {
    position: absolute;
    width: 5.03%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(../assets/report_pages/images/e1769173-8ea3-4885-a19f-c204b07f3a86.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 39;
    border-radius: 50%;
  }
  .rectangle_2e {
    position: absolute;
    width: 37.74%;
    height: 100%;
    top: 0;
    left: 6.29%;
    background: #dfe1eb;
    z-index: 41;
    border-radius: 5px;
  }
  .rectangle_2f {
    position: relative;
    width: 39px;
    height: 40px;
    margin: 0 0 0 0;
    background: #737fad;
    z-index: 42;
    border-radius: 5px;
  }
  .ellipse_30 {
    position: absolute;
    width: 5.03%;
    height: 100%;
    top: 0;
    left: 55.97%;
    background: url(../assets/report_pages/images/046783c6-55b7-4b9d-85a1-85a826177fc5.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 78;
    border-radius: 50%;
  }
  .rectangle_31 {
    position: absolute;
    width: 37.74%;
    height: 100%;
    top: 0;
    left: 62.26%;
    background: #dfe1eb;
    z-index: 80;
    border-radius: 5px;
  }
  .rectangle_32 {
    position: relative;
    width: 39px;
    height: 40px;
    margin: 0 0 0 0;
    background: #737fad;
    z-index: 81;
    border-radius: 5px;
  }
  .b2 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 60%;
    top: 20%;
    left: 0.75%;
    color: #ffffff;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 40;
  }
  .b2_33 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 60%;
    top: 20%;
    left: 56.73%;
    color: #ffffff;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 79;
  }
  .flex_row_d {
    position: relative;
    width: 795px;
    height: 40px;
    margin: 6px 0 0 64px;
    z-index: 86;
  }
  .ellipse_34 {
    position: absolute;
    width: 5.03%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(../assets/report_pages/images/c78d1144-f81d-4745-bc74-987a16a85687.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 45;
    border-radius: 50%;
  }
  .rectangle_35 {
    position: absolute;
    width: 37.74%;
    height: 100%;
    top: 0;
    left: 6.29%;
    background: #dfe1eb;
    z-index: 47;
    border-radius: 5px;
  }
  .rectangle_36 {
    position: relative;
    width: 71px;
    height: 40px;
    margin: 0 0 0 0;
    background: #848fb7;
    z-index: 48;
    border-radius: 5px;
  }
  .ellipse_37 {
    position: absolute;
    width: 5.03%;
    height: 100%;
    top: 0;
    left: 55.97%;
    background: url(../assets/report_pages/images/70a6980f-d17e-46a1-8b06-5882af1150f9.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 84;
    border-radius: 50%;
  }
  .rectangle_38 {
    position: absolute;
    width: 37.74%;
    height: 100%;
    top: 0;
    left: 62.26%;
    background: #dfe1eb;
    z-index: 86;
    border-radius: 5px;
  }
  .rectangle_39 {
    position: relative;
    width: 71px;
    height: 40px;
    margin: 0 0 0 0;
    background: #848fb7;
    z-index: 87;
    border-radius: 5px;
  }
  .b {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 60%;
    top: 20%;
    left: 0.88%;
    color: #ffffff;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 46;
  }
  .b_3a {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 60%;
    top: 20%;
    left: 56.86%;
    color: #ffffff;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 85;
  }
  .flex_row_b {
    position: relative;
    width: 795px;
    height: 40px;
    margin: 6px 0 0 64px;
    z-index: 92;
  }
  .ellipse_3b {
    position: absolute;
    width: 5.03%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(../assets/report_pages/images/769c3b8d-6fb7-48a6-8e1a-666e508935af.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 51;
    border-radius: 50%;
  }
  .rectangle_3c {
    position: absolute;
    width: 37.74%;
    height: 100%;
    top: 0;
    left: 6.29%;
    background: #dfe1eb;
    z-index: 53;
    border-radius: 5px;
  }
  .rectangle_3d {
    position: relative;
    width: 105px;
    height: 40px;
    margin: 0 0 0 0;
    background: #969fc1;
    z-index: 54;
    border-radius: 5px;
  }
  .ellipse_3e {
    position: absolute;
    width: 5.03%;
    height: 100%;
    top: 0;
    left: 55.97%;
    background: url(../assets/report_pages/images/90e099fd-e90b-4dd0-bb2d-6ded1e19a663.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 90;
    border-radius: 50%;
  }
  .rectangle_3f {
    position: absolute;
    width: 37.74%;
    height: 100%;
    top: 0;
    left: 62.26%;
    background: #dfe1eb;
    z-index: 92;
    border-radius: 5px;
  }
  .rectangle_40 {
    position: relative;
    width: 105px;
    height: 40px;
    margin: 0 0 0 0;
    background: #969fc1;
    z-index: 93;
    border-radius: 5px;
  }
  .a2 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 60%;
    top: 20%;
    left: 0.75%;
    color: #ffffff;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 52;
  }
  .a2_41 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 60%;
    top: 20%;
    left: 56.73%;
    color: #ffffff;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 91;
  }
  .flex_row_ac {
    position: relative;
    width: 795px;
    height: 40px;
    margin: 6px 0 0 64px;
    z-index: 98;
  }
  .ellipse_42 {
    position: absolute;
    width: 5.03%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(../assets/report_pages/images/7bbf8f29-9fb4-46ac-88c6-e5691c5bb976.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 57;
    border-radius: 50%;
  }
  .rectangle_43 {
    position: absolute;
    width: 37.74%;
    height: 100%;
    top: 0;
    left: 6.29%;
    background: #dfe1eb;
    z-index: 59;
    border-radius: 5px;
  }
  .rectangle_44 {
    position: relative;
    width: 191px;
    height: 40px;
    margin: 0 0 0 0;
    background: #a7afcb;
    z-index: 60;
    border-radius: 5px;
  }
  .ellipse_45 {
    position: absolute;
    width: 5.03%;
    height: 100%;
    top: 0;
    left: 55.97%;
    background: url(../assets/report_pages/images/daa6356b-d296-427b-9ed2-bae79f5ccca7.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 96;
    border-radius: 50%;
  }
  .rectangle_46 {
    position: absolute;
    width: 37.74%;
    height: 100%;
    top: 0;
    left: 62.26%;
    background: #dfe1eb;
    z-index: 98;
    border-radius: 5px;
  }
  .rectangle_47 {
    position: relative;
    width: 191px;
    height: 40px;
    margin: 0 0 0 0;
    background: #a7afcb;
    z-index: 99;
    border-radius: 5px;
  }
  .a1 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 60%;
    top: 20%;
    left: 0.75%;
    color: #ffffff;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 58;
  }
  .a1_48 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 60%;
    top: 20%;
    left: 56.73%;
    color: #ffffff;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 97;
  }
  .dont_get_too_caught_up {
    position: relative;
    width: 704px;
    height: 24px;
    margin: 30px 0 0 34px;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 21;
  }
  .dont_get_too_caught_up_49 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .accuracy_and_correctness_are_key {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
  .dot {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .rectangle_4a {
    position: relative;
    width: 940px;
    height: 1047px;
    margin: 20px 0 0 30px;
    font-size: 0px;
    background: #ffffff;
    z-index: 106;
    overflow: visible auto;
    border-radius: 30px;
  }
  .your_top_errors {
    display: block;
    position: relative;
    height: 30px;
    margin: 24px 0 0 30px;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 30px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
    white-space: nowrap;
    z-index: 134;
  }
  .line_4b {
    position: relative;
    width: 880px;
    height: 3px;
    margin: 7px 0 0 30px;
    background: url(../assets/report_pages/images/d43c3fc9-ba31-49b0-a77e-4d03f96490cb.png)
      no-repeat center;
    background-size: cover;
    z-index: 135;
  }
  .rectangle_4c {
    position: relative;
    width: 880px;
    height: 168px;
    margin: 20px 0 0 30px;
    background: #f7f7fa;
    z-index: 109;
    overflow: visible auto;
    border-radius: 20px;
  }
  .boring_in_question {
    position: relative;
    width: 220px;
    height: 30px;
    margin: 15px 0 0 330px;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 110;
  }
  .quote {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .boring {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .quote_4d {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .question_1 {
    position: relative;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .sentence {
    position: relative;
    width: 840px;
    height: 30px;
    margin: 8px 0 0 20px;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 111;
  }
  .i_was_so {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .space {
    position: relative;
    color: #f40d0d;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  .boring_4e {
    position: relative;
    color: #f40d0d;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  .i_was_so_4f {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .bored {
    position: relative;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  .space_uring_the_movie {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .explanation {
    position: relative;
    width: 840px;
    height: 60px;
    margin: 10px 0 0 20px;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    z-index: 112;
  }
  .explanation_50 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  .explan_detail {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .rectangle_51 {
    position: relative;
    width: 880px;
    height: 198px;
    margin: 10px 0 0 30px;
    background: #f7f7fa;
    z-index: 124;
    overflow: visible auto;
    border-radius: 20px;
  }
  .sympathetic_in_question {
    position: relative;
    width: 277px;
    height: 30px;
    margin: 15px 0 0 330px;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 125;
  }
  .quote_52 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .sympathetic {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .quote_53 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .question_54 {
    position: relative;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .empathetic_sympathetic {
    position: relative;
    width: 840px;
    height: 60px;
    margin: 8px 0 0 20px;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    z-index: 126;
  }
  .empathetic_sympathetic_55 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .sympathetic_56 {
    position: relative;
    color: #f40d0d;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  .empathetic_sympathetic_57 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .empathetic {
    position: relative;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  .empathetic_sympathetic_58 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .explanation_59 {
    position: relative;
    width: 840px;
    height: 60px;
    margin: 10px 0 0 20px;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    z-index: 127;
  }
  .explanation_5a {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  .boring_bored {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .rectangle_5b {
    position: relative;
    width: 880px;
    height: 168px;
    margin: 10px 0 0 30px;
    background: #f7f7fa;
    z-index: 114;
    overflow: visible auto;
    border-radius: 20px;
  }
  .boring_5c {
    position: relative;
    width: 220px;
    height: 30px;
    margin: 15px 0 0 330px;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 115;
  }
  .quote_5d {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .boring_5e {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .quote_5f {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .question_60 {
    position: relative;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .boring_bored_61 {
    position: relative;
    width: 840px;
    height: 30px;
    margin: 8px 0 0 20px;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 116;
  }
  .quote_62 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .empty {
    position: relative;
    color: #f40d0d;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  .boring_63 {
    position: relative;
    color: #f40d0d;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  .quote_64 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .bored_65 {
    position: relative;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  .during_movie {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .explanation_66 {
    position: relative;
    width: 840px;
    height: 60px;
    margin: 10px 0 0 20px;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    z-index: 117;
  }
  .explanation_67 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  .boring_bored_68 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .rectangle_69 {
    position: relative;
    width: 880px;
    height: 198px;
    margin: 10px 0 0 30px;
    background: #f7f7fa;
    z-index: 129;
    overflow: visible auto;
    border-radius: 20px;
  }
  .sympathetic_6a {
    position: relative;
    width: 277px;
    height: 30px;
    margin: 15px 0 0 330px;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 130;
  }
  .quote_6b {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .sympathetic_6c {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .quote_6d {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .question_6e {
    position: relative;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .empathetic_sympathetic_6f {
    position: relative;
    width: 840px;
    height: 60px;
    margin: 8px 0 0 20px;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    z-index: 131;
  }
  .empathetic_sympathetic_70 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .sympathetic_71 {
    position: relative;
    color: #f40d0d;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  .empathetic_sympathetic_72 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .empathetic_73 {
    position: relative;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  .empathetic_sympathetic_74 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .explanation_75 {
    position: relative;
    width: 840px;
    height: 60px;
    margin: 10px 0 0 20px;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    z-index: 132;
  }
  .explanation_76 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  .boring_bored_77 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .rectangle_78 {
    position: relative;
    width: 880px;
    height: 168px;
    margin: 10px 0 0 30px;
    background: #f7f7fa;
    z-index: 119;
    overflow: visible auto;
    border-radius: 20px;
  }
  .boring_79 {
    position: relative;
    width: 220px;
    height: 30px;
    margin: 15px 0 0 330px;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 120;
  }
  .quote_7a {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .boring_7b {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .quote_7c {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .question_7d {
    position: relative;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .boring_bored_7e {
    position: relative;
    width: 840px;
    height: 30px;
    margin: 8px 0 0 20px;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 121;
  }
  .quote_7f {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .empty_80 {
    position: relative;
    color: #f40d0d;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  .boring_81 {
    position: relative;
    color: #f40d0d;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  .quote_82 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .bored_83 {
    position: relative;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  .during_movie_84 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .explanation_85 {
    position: relative;
    width: 840px;
    height: 60px;
    margin: 10px 0 0 20px;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    z-index: 122;
  }
  .explanation_86 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  .explanation_87 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .rectangle_88 {
    position: relative;
    width: 940px;
    height: 763px;
    margin: 20px 0 0 30px;
    font-size: 0px;
    background: #ffffff;
    z-index: 138;
    overflow: visible auto;
    border-radius: 30px;
  }
  .lexical_resources {
    display: block;
    position: relative;
    height: 30px;
    margin: 24px 0 0 30px;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 30px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
    white-space: nowrap;
    z-index: 140;
  }
  .line_89 {
    position: relative;
    width: 880px;
    height: 3px;
    margin: 7px 0 0 30px;
    background: url(../assets/report_pages/images/c2a6cffb-d456-4917-b549-eb0611a87a95.png)
      no-repeat center;
    background-size: cover;
    z-index: 141;
  }
  .rectangle_8a {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    width: 880px;
    height: 48px;
    margin: 10px 0 0 30px;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    z-index: 142;
  }
  .rectangle_8b {
    position: relative;
    width: 880px;
    height: 574px;
    margin: 27px 0 0 30px;
    background: #f7f7fa;
    z-index: 144;
    overflow: visible auto;
    border-radius: 20px;
  }
  .important_crucial_significant {
    position: relative;
    width: 502px;
    height: 30px;
    margin: 15px 0 0 189px;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 145;
  }
  .important {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .crucial_significant_pivotal {
    position: relative;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .rectangle_8c {
    position: relative;
    width: 821.999px;
    height: 112px;
    margin: 24px 0 0 28px;
    background: #ffffff;
    z-index: 148;
    overflow: visible auto;
    border-radius: 10px;
  }
  .crucial {
    position: relative;
    width: 773.646px;
    height: 90px;
    margin: 11px 0 0 24.176px;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    z-index: 149;
  }
  .crucial_8d {
    position: relative;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .crucial_8e {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .crucial_8f {
    position: relative;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .significant {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .significant_90 {
    position: relative;
    width: 821.999px;
    height: 112px;
    margin: 10px 0 0 28px;
    background: #ffffff;
    z-index: 152;
    overflow: visible auto;
    border-radius: 10px;
  }
  .significant_91 {
    position: relative;
    width: 773.646px;
    height: 90px;
    margin: 11px 0 0 24.176px;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    z-index: 153;
  }
  .significant_92 {
    position: relative;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .significant_93 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .significant_94 {
    position: relative;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .significant_95 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  .significant_96 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .rectangle_97 {
    position: relative;
    width: 821.999px;
    height: 112px;
    margin: 10px 0 0 28px;
    background: #ffffff;
    z-index: 156;
    overflow: visible auto;
    border-radius: 10px;
  }
  .significant_98 {
    position: relative;
    width: 773.646px;
    height: 90px;
    margin: 11px 0 0 24.176px;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    z-index: 157;
  }
  .significant_99 {
    position: relative;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .significant_9a {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .significant_9b {
    position: relative;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .significant_9c {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  .significant_9d {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .rectangle_9e {
    position: relative;
    width: 821.999px;
    height: 112px;
    margin: 10px 0 0 28px;
    background: #ffffff;
    z-index: 160;
    overflow: visible auto;
    border-radius: 10px;
  }
  .significant_9f {
    position: relative;
    width: 773.646px;
    height: 90px;
    margin: 11px 0 0 24.176px;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    z-index: 161;
  }
  .significant_a0 {
    position: relative;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .significant_a1 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .significant_a2 {
    position: relative;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
  }
  .significant_a3 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  .significant_a4 {
    position: relative;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .frame {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 783px;
    height: 160px;
    top: 32px;
    left: 187px;
    padding: 10px 10px 10px 10px;
    background: #ffffff;
    z-index: 166;
    overflow: hidden;
    border-radius: 20px;
  }
  .frame_input {
    flex-shrink: 0;
    position: absolute;
    width: 783px;
    height: 160px;
    top: 0;
    left: 0;
    background: transparent;
    border: none;
    z-index: 168;
    outline: none;
  }
  .rectangle_a5 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    width: 734px;
    height: 120px;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    text-overflow: initial;
    z-index: 167;
    overflow: hidden;
  }
  .rectangle_a6 {
    position: absolute;
    width: 120px;
    height: 120px;
    top: 40px;
    left: 40px;
    background: #505f98;
    z-index: 164;
    border-radius: 30px;
  }
  .rectangle_a7 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    width: 87px;
    height: 60px;
    top: 30px;
    left: 17px;
    color: #ffffff;
    font-family: Nunito, var(--default-font-family);
    font-size: 60px;
    font-weight: 700;
    line-height: 60px;
    text-align: center;
    white-space: nowrap;
    z-index: 165;
  }
  .rectangle_a8 {
    position: absolute;
    width: 340px;
    height: 110px;
    top: 210px;
    left: 0;
    background: #ffffff;
    z-index: 239;
    border-radius: 20px;
  }
  .rectangle_a9 {
    position: absolute;
    width: 340px;
    height: 110px;
    top: 0;
    left: 0;
    background: #ffffff;
    z-index: 240;
    border-radius: 20px;
  }
  .suggestion_icon {
    position: absolute;
    width: 55px;
    height: 55px;
    top: 28px;
    left: 30px;
    background: url(../assets/report_pages/images/4bf5f07d37d6fbf9b84d67cebd1bf25f4cfd711f.png)
      no-repeat center;
    background-size: cover;
    z-index: 242;
  }
  .suggestions {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 30px;
    top: 39px;
    left: 123px;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    white-space: nowrap;
    z-index: 241;
  }
  .rectangle_aa {
    position: absolute;
    width: 340px;
    height: 110px;
    top: 340px;
    left: 0;
    background: #ffffff;
    z-index: 239;
    border-radius: 20px;
  }
  .icon_ai {
    position: absolute;
    width: 20.59%;
    height: 51.66%;
    top: 24.55%;
    left: 5.88%;
    background: url(../assets/report_pages/images/b4c28a09421c213c0650ff3ebc7a525ba101752f.png)
      no-repeat center;
    background-size: cover;
    z-index: 250;
  }
  .flex_column_d {
    position: absolute;
    width: 58.82%;
    height: 51.82%;
    top: 35.45%;
    left: 35.59%;
    font-size: 0px;
    z-index: 249;
  }
  .improvements {
    display: block;
    position: relative;
    height: 30px;
    left: -5px;
    margin: 0 0 0 0;
    color: #d4d7dd;
    font-family: Nunito, var(--default-font-family);
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    white-space: nowrap;
    z-index: 248;
  }
  .for_part_2_3 {
    display: block;
    position: relative;
    height: 20px;
    margin: 7px 0 0 0;
    color: #d4d7dd;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    z-index: 249;
  }

  .part_question_list {
    position: absolute;
    /* left: 100px; */
    overflow-y: auto;     /* 内容超出时显示垂直滚动条 */
    width: 48%;     
    height: 100%;     
  
  }
  
  /* 为 .part1-question-list 定制滚动条 */
  .part_question_list::-webkit-scrollbar {
      width: 10px; /* 滚动条的宽度 */
      background-color: #f9f9f9; /* 滚动条的背景色 */
  }
  
  /* 滚动条滑块 */
  .part_question_list::-webkit-scrollbar-thumb {
      background-color: #b3b3b3; /* 滑块的颜色 */
      border-radius: 5px; /* 滑块的圆角 */
      border: 2px solid #ffffff; /* 滑块边缘的颜色 */
  }
  .play_button.playing {
    background: url(../assets/record_pages/test_equipment/start.png) 
    no-repeat center;
    background-size: cover;
  }
  .part2_transcript {
    position: relative;
    width: 1380px;
    height: 392px;
    margin: 40px 0 0 30px;
    font-size: 0px;
    background: #ffffff;
    z-index: 300 ;
    overflow: visible auto;
    border-radius: 20px;
  }
  .part2_flex_column_f {
    position: absolute;
    width: 1300px;
    height: 345px;
    top: 15px;
    left: 40px;
    font-size: 0px;
    z-index: 10;
  }
  .part2_recordings_transcripts {
    display: block;
    position: relative;
    height: 30px;
    margin: 0 0 0 0;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 30px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
    white-space: nowrap;
    z-index: 2;
  }
  .part2_line {
    position: relative;
    width: 1303px;
    height: 3px;
    margin: 13.5px 0 0 -1.5px;
    background: url(../assets/report_pages/images/ae2f2740-83be-473b-919d-bf946a0222f9.png)
      no-repeat center;
    background-size: cover;
    z-index: 3;
  }
  .part2_rectangle {
    position: relative;
    width: 969px;
    height: 60px;
    margin: 18.5px 0 0 331px;
    background: #dfe1eb;
    z-index: 10;
    border-radius: 45px;
  }
  .part2_play_button {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 9.305px;
    left: 20.855px;
    background: url(../assets/report_pages/images/ad27d3d0-ff73-490c-b54e-b88d497fa2d2.png)
      no-repeat center;
    background-size: cover;
    z-index: 11;
  }
  .part2_play_button.playing {
    background: url(../assets/record_pages/test_equipment/start.png) 
    no-repeat center;
    background-size: cover;
  }
  .part2_time_indicator {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 17px;
    top: 21px;
    left: 70.854px;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    white-space: nowrap;
    z-index: 14;
  }
  .part2_ellipse {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 21px;
    left: 196.594px;
    cursor: pointer;
    background: url(../assets/report_pages/images/0c7a38de-331a-40a2-862e-e03ebd7342c0.png)
      no-repeat center;
    background-size: cover;
    z-index: 13;
    border-radius: 50%;
  }
  .part2_line_1 {
    position: absolute;
    width: 748px;
    height: 8px;
    top: 27px;
    left: 197.584px;
    cursor: pointer;
    background: url(../assets/report_pages/images/7bfb18ff-48fd-4b17-9767-496a503d8eb0.png)
      no-repeat center;
    background-size: cover;
    z-index: 12;
  }
  .part2_passionate_gamer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    width: 960px;
    height: 195px;
    margin: 25px 0 0 340px;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    overflow-y: auto; 
    z-index: 4;
  }
  .part2_passionate_gamer::-webkit-scrollbar {
    width: 10px; /* 滚动条的宽度 */
    background-color: #f9f9f9; /* 滚动条的背景色 */
  }
  
  /* 滚动条滑块 */
  .part2_passionate_gamer::-webkit-scrollbar-thumb {
    background-color: #b3b3b3; /* 滑块的颜色 */
    border-radius: 5px; /* 滑块的圆角 */
    border: 2px solid #ffffff; /* 滑块边缘的颜色 */
  }
  .part2_rectangle_2 {
    position: absolute;
    width: 316px;
    height: 280px;
    top: 84px;
    left: 40px;
    font-size: 0px;
    background: #f7f7fa;
    z-index: 8;
    border-radius: 20px;
  }
  .part2_activity_description {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    width: 260px;
    height: 60px;
    margin: 29px 0 0 25px;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    z-index: 7;
  }
  .part2_activity_details {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    width: 260px;
    height: 120px;
    margin: 20px 0 0 27px;
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    z-index: 8;
  }
  
  
  
.vol_main_container {
  position: relative;
  width: 1000px;
  /* height: 2628px; */
  height: auto;
  top: 80px;
  margin: 0 auto;
  background-color: #F7F7FA;
  border-radius: 20px;
  padding-bottom: 20px;
  z-index: 300;
}
.vol_small_overview_area {
  position: relative;
  width: 930px;
  height: 160px;
  top: 20px;
  margin: 32px 0 0 40px;
  /* z-index: 300; */
}
.vol_frame_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 783px;
  height: 160px;
  top: 0;
  left: 147px;
  padding: 10px 10px 10px 10px;
  background: #ffffff;
  z-index: 137;

  border-radius: 20px;
}
.vol_small_overview_text {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  width: 734px;
  height: 120px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  text-overflow: initial;
  z-index: 138;
  overflow-y: auto;

}
.vol_small_overview_text::-webkit-scrollbar {
  width: 10px; /* 滚动条的宽度 */
  background-color: #f9f9f9; /* 滚动条的背景色 */
}

/* 滚动条滑块 */
.vol_small_overview_text::-webkit-scrollbar-thumb {
  background-color: #b3b3b3; /* 滑块的颜色 */
  border-radius: 5px; /* 滑块的圆角 */
  border: 2px solid #ffffff; /* 滑块边缘的颜色 */
}
.vol_score_display {
  position: absolute;
  width: 120px;
  height: 120px;
  top: 8px;
  left: 0;
  cursor: pointer;
  font-size: 0px;
  background: transparent;
  border: none;
  z-index: 134;
  border-radius: 30px;
}
.vol_score {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  width: 87px;
  height: 60px;
  margin: 0 0 0 10px;
  color: #ffffff;
  font-family: Nunito, var(--default-font-family);
  font-size: 60px;
  font-weight: 700;
  line-height: 60px;
  text-align: center;
  white-space: nowrap;
  z-index: 136;
}
.vol_rectangle {
  position: absolute;
  width: 120px;
  height: 120px;
  top: 0;
  left: 0;
  background: #505f98;
  z-index: 135;
  border-radius: 30px;
}
.vol_content {
  position: relative;
  width: 940px;
  /* height: 2386px; */
  margin: 20px 0 0 30px;
  z-index: 1;
  top: 20px;
  height: auto; /* 高度自适应内容 */
  /* overflow: visible auto; */
}
.vol_vocab_distribution {
  position: relative;
  width: 940px;
  height: 536px;
  margin: 0 0 0 0;
  z-index: 2;
  overflow: visible auto;
}
.vol_group {
  position: relative;
  width: 880px;
  height: 40px;
  margin: 24px 0 0 30px;
  font-size: 0px;
  z-index: 73;
  overflow: visible auto;
}
.vol_vocab_distribution_title {
  display: block;
  position: relative;
  height: 30px;
  margin: 0 0 0 0;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 30px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 74;
}
.vol_line {
  position: relative;
  width: 880px;
  height: 3px;
  margin: 7px 0 0 0;
  background: url(../assets/report_pages/images/41182ded-3de4-48e4-94a5-e355d17190ad.png)
    no-repeat center;
  background-size: cover;
  z-index: 75;
}
.vol_advanced_vocabulary_text {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 880px;
  height: 51px;
  margin: 10px 0 0 30px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 72;
}
.grammer_des {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 880px;
  height: 51px;
  margin: 10px 0 0 30px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 72;
}

.vol_chart {
  position: relative;
  width: 820px;
  height: 307px;
  margin: 23px 0 0 64px;
  z-index: 5;
}
.vol_user {
  position: absolute;
  width: 350px;
  height: 307px;
  top: 0;
  left: 0;
  font-size: 0px;
  z-index: 6;
}
.vol_user_vocab_distribution {
  display: block;
  position: relative;
  height: 24px;
  margin: 0 0 0 44px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 38;
}
.vol_group_2 {
  position: relative;
  width: 350px;
  height: 268px;
  margin: 15px 0 0 0;
  z-index: 7;
  overflow: visible auto;
}
.vol_flex_row {
  position: relative;
  width: 350px;
  height: 40px;
  margin: 0 0 0 0;
  z-index: 12;
}
.vol_group_3 {
  position: absolute;
  width: 11.43%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 0px;
  z-index: 8;
}
.vol_c_level_c2 {
  display: block;
  position: relative;
  height: 24px;
  margin: 8px 0 0 6px;
  color: #ffffff;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 10;
}
.vol_ellipse {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../assets/report_pages/images/d5d4fdb4-28a9-451d-9488-bcf83e95fe7e.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 9;
  border-radius: 50%;
}
.vol_rectangle_4 {
  position: absolute;
  width: 85.71%;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #dfe1eb;
  z-index: 11;
  border-radius: 5px;
}
.vol_rectangle_5 {
  position: absolute;
  width: 4.57%;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #626fa2;
  z-index: 12;
  border-radius: 5px;
}
.vol_flex_row_6 {
  position: relative;
  width: 350px;
  height: 40px;
  margin: 5px 0 0 0;
  z-index: 17;
}
.vol_group_7 {
  position: absolute;
  width: 11.43%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 0px;
  z-index: 13;
}
.vol_c_level_c1 {
  display: block;
  position: relative;
  height: 24px;
  margin: 8px 0 0 6px;
  color: #ffffff;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 15;
}
.vol_ellipse_8 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../assets/report_pages/images/e234c3d1-e3ea-4a71-8614-76ca51b770be.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 14;
  border-radius: 50%;
}
.vol_rectangle_9 {
  position: absolute;
  width: 85.71%;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #dfe1eb;
  z-index: 16;
  border-radius: 5px;
}
.vol_rectangle_a {
  position: absolute;
  width: 4.57%;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #626fa2;
  z-index: 17;
  border-radius: 5px;
}
.vol_rectangle_h {
  position: absolute;
  width: 4.57%;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #737FAD;
  z-index: 17;
  border-radius: 5px;
}
.vol_rectangle_l {
  position: absolute;
  width: 4.57%;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #848FB7;
  z-index: 17;
  border-radius: 5px;
}
.vol_flex_row_cb {
  position: relative;
  width: 350px;
  height: 40px;
  margin: 5px 0 0 0;
  z-index: 22;
}
.vol_rectangle_b {
  position: absolute;
  width: 85.71%;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #dfe1eb;
  z-index: 21;
  border-radius: 5px;
}
.vol_regroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 25.43%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 22;
}
.vol_group_c {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  font-size: 0px;
  z-index: 18;
}
.vol_b_level_b2 {
  display: block;
  position: relative;
  height: 24px;
  margin: 8px 0 0 6px;
  color: #ffffff;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 20;
}

.vol_ellipse_d {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../assets/report_pages/images/47fa28f2-8822-44ca-b02e-ea67161c5660.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 19;
  border-radius: 50%;
}
.vol_rectangle_e {
  flex-shrink: 0;
  position: relative;
  width: 10px;
  height: 40px;
  background: #737fad;
  z-index: 22;
  border-radius: 5px;
}
/* .vol_flex_row_dc {
  position: relative;
  width: 350px;
  height: 40px;
  margin: 6px 0 0 0;
  z-index: 27;
}
.vol_rectangle_f {
  position: absolute;
  width: 85.71%;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #dfe1eb;
  z-index: 26;
  border-radius: 5px;
}

.vol_regroup_10 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 34.57%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 27;
}
.vol_group_11 {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  font-size: 0px;
  z-index: 23;
}

.vol_b_level_b1 {
  display: block;
  position: relative;
  height: 24px;
  margin: 8px 0 0 6px;
  color: #ffffff;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 25;
}
.vol_ellipse_12 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../assets/report_pages/images/8eb5f953-3054-44f7-a92a-5e93069612ac.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 24;
  border-radius: 50%;
}
.vol_rectangle_13 {
  flex-shrink: 0;
  position: relative;
  width: 71px;
  height: 40px;
  left: 50px;
  background: #848fb7;
  z-index: 27;
  border-radius: 5px;
} */
.vol_flex_row_dc {
  position: relative;
  width: 350px;
  height: 40px;
  margin: 5px 0 0 0;
  z-index: 22;
}
.vol_rectangle_f {
  position: absolute;
  width: 85.71%;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #dfe1eb;
  z-index: 21;
  border-radius: 5px;
}
.vol_regroup_10 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 25.43%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 22;
}
.vol_group_11 {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  font-size: 0px;
  z-index: 18;
}
.vol_b_level_b1 {
  display: block;
  position: relative;
  height: 24px;
  margin: 8px 0 0 6px;
  color: #ffffff;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 20;
}
.vol_ellipse_12 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../assets/report_pages/images/47fa28f2-8822-44ca-b02e-ea67161c5660.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 19;
  border-radius: 50%;
}
.vol_rectangle_13 {
  flex-shrink: 0;
  position: relative;
  width: 39px;
  height: 40px;
  background: #737fad;
  z-index: 22;
  border-radius: 5px;
}

.vol_flex_row_bbc {
  position: relative;
  width: 350px;
  height: 40px;
  margin: 6px 0 0 0;
  z-index: 32;
}
.vol_group_14 {
  position: absolute;
  width: 11.43%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 0px;
  z-index: 28;
}
.vol_a_level_a2 {
  display: block;
  position: relative;
  height: 24px;
  margin: 8px 0 0 6px;
  color: #ffffff;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 30;
}
.vol_ellipse_15 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../assets/report_pages/images/0eabfbe3-5f53-413b-9bb0-86feb80f6c41.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 29;
  border-radius: 50%;
}
.vol_rectangle_16 {
  position: absolute;
  width: 85.71%;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #dfe1eb;
  z-index: 31;
  border-radius: 5px;
}
.vol_rectangle_17 {
  position: absolute;
  width: 30%;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #969fc1;
  z-index: 32;
  border-radius: 5px;
}
.vol_flex_row_eb {
  position: relative;
  width: 350px;
  height: 40px;
  margin: 6px 0 0 0;
  z-index: 37;
}
.vol_group_18 {
  position: absolute;
  width: 11.43%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 0px;
  z-index: 33;
}
.vol_span_a {
  display: block;
  position: relative;
  height: 24px;
  margin: 8px 0 0 6px;
  color: #ffffff;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 35;
}
.vol_ellipse_19 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../assets/report_pages/images/c62a992c-f35f-4292-9e3d-62046dfe6f32.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 34;
  border-radius: 50%;
}
.vol_rectangle_1a {
  position: absolute;
  width: 85.71%;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #dfe1eb;
  z-index: 36;
  border-radius: 5px;
}
.vol_rectangle_1b {
  position: absolute;
  width: 54.57%;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #a7afcb;
  z-index: 37;
  border-radius: 5px;
}
.vol_wrapper_6 {
  position: absolute;
  width: 375px;
  height: 307px;
  top: 0;
  left: 445px;
  z-index: 39;
}
.vol_typical_band_vocabulary {
  position: relative;
  width: 374px;
  height: 24px;
  margin: 0 0 0 1px;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 71;
}
.vol_typical_band_8_vocabulary-distribution {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  text-align: left;
}
.vol_group_1c {
  position: relative;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  text-align: left;
}
.vol_flex_row_ff {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  text-align: left;
}
.vol_group_1d {
  position: relative;
  width: 350px;
  height: 268px;
  margin: 15px 0 0 0;
  z-index: 40;
  overflow: visible auto;
}
.vol_span_c {
  position: relative;
  width: 350px;
  height: 40px;
  margin: 0 0 0 0;
  z-index: 45;
}
.vol_ellipse_1e {
  position: absolute;
  width: 11.43%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 0px;
  z-index: 41;
}
.vol_rectangle_1f {
  display: block;
  position: relative;
  height: 24px;
  margin: 8px 0 0 6px;
  color: #ffffff;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 43;
}
.vol_flex_row_bbb {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../assets/report_pages/images/232c326a-650b-4295-b025-178513c31524.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 42;
  border-radius: 50%;
}
.vol_button_group {
  position: absolute;
  width: 85.71%;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #dfe1eb;
  z-index: 44;
  border-radius: 5px;
}
.vol_span_c_20 {
  position: absolute;
  width: 4.57%;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #626fa2;
  z-index: 45;
  border-radius: 5px;
}
.vol_ellipse_21 {
  position: relative;
  width: 350px;
  height: 40px;
  margin: 5px 0 0 0;
  z-index: 50;
}
.vol_rectangle_22 {
  position: absolute;
  width: 11.43%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  font-size: 0px;
  background: transparent;
  border: none;
  z-index: 46;
}
.vol_flex_row_a {
  display: block;
  position: relative;
  height: 24px;
  margin: 8px 0 0 6px;
  color: #ffffff;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 48;
}
.vol_rectangle_23 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../assets/report_pages/images/3a9cc329-005e-4fe7-b6e7-6d20b88e409f.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 47;
  border-radius: 50%;
}
.vol_section_11 {
  position: absolute;
  width: 85.71%;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #dfe1eb;
  z-index: 49;
  border-radius: 5px;
}
.vol_button_group_24 {
  position: absolute;
  width: 4.57%;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #626fa2;
  z-index: 50;
  border-radius: 5px;
}
.vol_span_b {
  position: relative;
  width: 350px;
  height: 40px;
  margin: 5px 0 0 0;
  z-index: 55;
}
.vol_ellipse_25 {
  position: absolute;
  width: 85.71%;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #dfe1eb;
  z-index: 54;
  border-radius: 5px;
}
.vol_rectangle_26 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 25.43%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 55;
}
.vol_flex_row_c {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 0px;
  background: transparent;
  border: none;
  z-index: 51;
}
.vol_rectangle_27 {
  display: block;
  position: relative;
  height: 24px;
  margin: 8px 0 0 6px;
  color: #ffffff;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 63;
}
.vol_img_5 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../assets/report_pages/images/a51bf83a-1672-4b20-a151-05a8b6793a8d.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 52;
  border-radius: 50%;
}
.vol_group_28 {
  flex-shrink: 0;
  position: relative;
  width: 39px;
  height: 40px;
  background: #737fad;
  z-index: 55;
  border-radius: 5px;
}
.vol_button_b {
  position: relative;
  width: 350px;
  height: 40px;
  margin: 6px 0 0 0;
  z-index: 60;
}
.vol_ellipse_29 {
  position: absolute;
  width: 85.71%;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #dfe1eb;
  z-index: 59;
  border-radius: 5px;
}
.vol_rectangle_2a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 34.57%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 60;
}
.vol_flex_row_cb_2b {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 56;
}
.vol_group_2c {
  position: relative;
  width: 26px;
  height: 24px;
  margin: 8px 0 0 7px;
  cursor: pointer;
  color: #ffffff;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  white-space: nowrap;
  background: transparent;
  border: none;
  z-index: 58;
}
.vol_span_a_2d {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../assets/report_pages/images/07d6ab47-7173-42cb-9da1-9a9c166bbe09.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 57;
  border-radius: 50%;
}
.vol_ellipse_2e {
  flex-shrink: 0;
  position: relative;
  width: 71px;
  height: 40px;
  background: #848fb7;
  z-index: 60;
  border-radius: 5px;
}
.vol_rectangle_2f {
  position: relative;
  width: 350px;
  height: 40px;
  margin: 6px 0 0 0;
  z-index: 65;
}
.vol_rectangle_30 {
  position: absolute;
  width: 11.43%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 0px;
  z-index: 61;
}
.vol_flex_row_ef {
  display: block;
  position: relative;
  height: 24px;
  margin: 8px 0 0 6px;
  color: #ffffff;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 63;
}
.vol_group_32 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../assets/report_pages/images/e234c3d1-e3ea-4a71-8614-76ca51b770be.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 62;
  border-radius: 50%;
}
.vol_group_31 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../assets/report_pages/images/05a513dc-a35a-4f76-a51d-a98be6f8f2de.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 62;
  border-radius: 50%;
}
.vol_group_35 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../assets/report_pages/images/47fa28f2-8822-44ca-b02e-ea67161c5660.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 62;
  border-radius: 50%;
}
.vol_group_30 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../assets/report_pages/images/47fa28f2-8822-44ca-b02e-ea67161c5660.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 62;
  border-radius: 50%;
}
.vol_group_31 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../assets/report_pages/images/05a513dc-a35a-4f76-a51d-a98be6f8f2de.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 62;
  border-radius: 50%;
}
.vol_span_a_32 {
  position: absolute;
  width: 85.71%;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #dfe1eb;
  z-index: 64;
  border-radius: 5px;
}
.vol_ellipse_33 {
  position: absolute;
  width: 16px;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #626FA2;
  z-index: 65;
  border-radius: 5px;
}
.vol_ellipse_34 {
  position: absolute;
  width: 39px;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #737FAD;
  z-index: 65;
  border-radius: 5px;
}
.vol_ellipse_35 {
  position: absolute;
  width: 71px;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #848FB7;
  z-index: 65;
  border-radius: 5px;
}
.vol_ellipse_36 {
  position: absolute;
  width: 105px;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #969FC1;
  z-index: 65;
  border-radius: 5px;
}
.vol_rectangle_34 {
  position: relative;
  width: 350px;
  height: 40px;
  margin: 6px 0 0 0;
  z-index: 70;
}
.vol_group_11 {
  position: absolute;
  width: 11.43%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 0px;
  z-index: 66;
}
.vol_text_13 {
  display: block;
  position: relative;
  height: 24px;
  margin: 8px 0 0 6px;
  color: #ffffff;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 68;
}
.vol_img_6 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../assets/report_pages/images/1890d6ce-6e63-499f-bdbd-cd76441321e4.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 67;
  border-radius: 50%;
}
.vol_box_b {
  position: absolute;
  width: 85.71%;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #dfe1eb;
  z-index: 69;
  border-radius: 5px;
}
.vol_rectangle_35 {
  position: absolute;
  width: 54.57%;
  height: 100%;
  top: 0;
  left: 14.29%;
  background: #a7afcb;
  z-index: 70;
  border-radius: 5px;
}
.vol_dont_get_caught {
  position: relative;
  width: 704px;
  height: 24px;
  margin: 30px 0 0 34px;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 4;
}
.vol_dont_get_caught_36 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.vol_accuracy_and_correctness {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}
.vol_dot {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.vol_rectangle_37 {
  position: absolute;
  width: 940px;
  height: 536px;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 3;
  border-radius: 30px;
}
.vol_top_errors {
  display: flex;  /* 使用flex布局 */
  flex-direction: column;  /* 使内容垂直排列 */
  background-color: #ffffff;
  width: 940px;
  margin: 20px 0 0 0;
  z-index: 76;
  border-radius: 30px;
  overflow: visible auto;
}
.vol_group_38 {
  position: relative;
  width: 880px;
  height: 40px;
  margin: 24px 0 0 30px;
  font-size: 0px;
  z-index: 104;
  overflow: visible auto;
}
.vol_your_top_errors {
  display: block;
  position: relative;
  height: 30px;
  margin: 0 0 0 0;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 30px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 105;
}
.vol_line_39 {
  position: relative;
  width: 880px;
  height: 3px;
  margin: 7px 0 0 0;
  background: url(../assets/report_pages/images/14375755-6b51-4c7e-8555-c6d2371352ee.png)
    no-repeat center;
  background-size: cover;
  z-index: 106;
}
/* 设置背景颜色，并保持容器自适应内容的高度 */
.vol_error_vocabulary_explanation {
  position: relative;
  width: 860px;
  margin: 20px 0 0 30px;
  z-index: 78;
  /* overflow: visible auto; */
  border-radius: 20px;
  background-color: #F7F7FA;  /* 设置背景颜色 */
  height: auto; /* 自适应内容的高度 */
  display: flex;  /* 使用flex布局 */
  flex-direction: column; /* 垂直排列 */
  gap: 10px;  /* 设置子元素之间的间隔 */
}

.vol_error_word_explanation {
  position: relative;
  width: 880px;
  margin: 0;
  padding: 10px 0; /* Add padding instead of fixed height */
  z-index: 79;
  overflow: visible auto;
  border-radius: 20px;
  /* Remove fixed height and let it adapt to content */
  height: auto; 
}
.vol_boring_in_question {
  position: relative;
  width: 220px;
  margin: 0px 0 0 330px;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  z-index: 81;
  white-space: nowrap;
}
.vol_boring {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  font-style: italic; /* 添加斜体样式 */
  text-transform: capitalize;
}
.vol_question {
  position: relative;
  color: #505F98;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_quote, .vol_quote_3a {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_quote_sentence {
  position: relative;
  width: 840px;
  margin: 8px 0 0 20px;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  z-index: 82;
}
.vol_space, .vol_boring_3c, .vol_quote_sentence_3d, .vol_bored {
  position: relative;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}
/* .vol_space_3e {
  position: relative;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
} */

.vol_boring_3c {
  text-decoration: line-through; /* 添加删除线 */
  color: red;
}
.vol_bored {
  color: #505f98;
}

/* 解释文本样式 */
.vol_explanation {
  position: relative;
  width: 840px;
  margin: 10px 0 0 20px;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  z-index: 83;
}
.vol_explanation_3f {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}
.vol_explanation_40 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_rectangle_41 {
  position: absolute;
  width: 880px;
  background: #f7f7fa;
  z-index: 80;
  border-radius: 20px;
  /* Removed fixed height, let it adapt */
  height: auto; 
}
.vol_error_word_explanation_42 {
  position: relative;
  width: 880px;
  height: 198px;
  margin: 10px 0 0 0;
  z-index: 94;
  overflow: visible auto;
  border-radius: 20px;
}
.vol_sympathetic_in_question {
  position: relative;
  width: 277px;
  height: 30px;
  margin: 15px 0 0 330px;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 96;
}
.vol_quote_43 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_sympathetic {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_quote_44 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_question_2 {
  position: relative;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_quote_sentence_45 {
  position: relative;
  width: 840px;
  height: 60px;
  margin: 8px 0 0 20px;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  z-index: 97;
}
.vol_quote_sentence_46 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_sympathetic_47 {
  position: relative;
  color: #f40d0d;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}
.vol_quote_sentence_48 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_empathetic {
  position: relative;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}
.vol_quote_sentence_49 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_explanation_4a {
  position: relative;
  width: 840px;
  height: 60px;
  margin: 10px 0 0 20px;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  z-index: 98;
}
.vol_explanation_4b {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}
.vol_explanation_4c {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_rectangle_4d {
  position: absolute;
  width: 880px;
  height: 198px;
  top: 0;
  left: 0;
  background: #f7f7fa;
  z-index: 95;
  border-radius: 20px;
}
.vol_error_word_explanation_4e {
  position: relative;
  width: 880px;
  height: 168px;
  margin: 10px 0 0 0;
  z-index: 84;
  overflow: visible auto;
  border-radius: 20px;
}
.vol_boring_in_question_4f {
  position: relative;
  width: 220px;
  height: 30px;
  margin: 15px 0 0 330px;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 86;
}
.vol_quote_50 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_boring_51 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_quote_52 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_question_53 {
  position: relative;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_boring_movie {
  position: relative;
  width: 840px;
  height: 30px;
  margin: 8px 0 0 20px;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 87;
}
.vol_i_was_so {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_space_54 {
  position: relative;
  color: #f40d0d;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}
.vol_boring_55 {
  position: relative;
  color: #f40d0d;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}
.vol_i_was_so_56 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_bored_57 {
  position: relative;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}
.vol_during_movie {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_explanation_58 {
  position: relative;
  width: 840px;
  height: 60px;
  margin: 10px 0 0 20px;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  z-index: 88;
}
.vol_explanation_59 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}
.vol_explanation_text {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_rectangle_5a {
  position: absolute;
  width: 880px;
  height: 168px;
  top: 0;
  left: 0;
  background: #f7f7fa;
  z-index: 85;
  border-radius: 20px;
}
.vol_word_explanation {
  position: relative;
  width: 880px;
  height: 198px;
  margin: 10px 0 0 0;
  z-index: 99;
  overflow: visible auto;
  border-radius: 20px;
}
.vol_sympathetic_question {
  position: relative;
  width: 277px;
  height: 30px;
  margin: 15px 0 0 330px;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 101;
}
.vol_quote_5b {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_sympathetic_5c {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_quote_5d {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_question_5e {
  position: relative;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_i_am_very {
  position: relative;
  width: 840px;
  height: 60px;
  margin: 8px 0 0 20px;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  z-index: 102;
}
.vol_sympathetic_5f {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_space_60 {
  position: relative;
  color: #f40d0d;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}
.vol_sympathetic_61 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_space_62 {
  position: relative;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}
.vol_sick_or_unhappy {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_explanation_63 {
  position: relative;
  width: 840px;
  height: 60px;
  margin: 10px 0 0 20px;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  z-index: 103;
}
.vol_explanation_64 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}
.vol_explanation_text_65 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_rectangle_66 {
  position: absolute;
  width: 880px;
  height: 198px;
  top: 0;
  left: 0;
  background: #f7f7fa;
  z-index: 100;
  border-radius: 20px;
}
.vol_word_explanation_67 {
  position: relative;
  width: 880px;
  height: 168px;
  margin: 10px 0 0 0;
  z-index: 89;
  overflow: visible auto;
  border-radius: 20px;
}
.vol_boring_question {
  position: relative;
  width: 220px;
  height: 30px;
  margin: 15px 0 0 330px;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 91;
}
.vol_quote_68 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_boring_69 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_quote_6a {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_question_6b {
  position: relative;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_boring_movie_6c {
  position: relative;
  width: 840px;
  height: 30px;
  margin: 8px 0 0 20px;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 92;
}
.vol_i_was_so_6d {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_space_6e {
  position: relative;
  color: #f40d0d;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}
.vol_boring_6f {
  position: relative;
  color: #f40d0d;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}
.vol_i_was_so_70 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_bored_71 {
  position: relative;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}
.vol_during_movie_72 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_explanation_73 {
  position: relative;
  width: 840px;
  height: 60px;
  margin: 10px 0 0 20px;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  z-index: 93;
}
.vol_explanation_74 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}
.vol_explanation_text_75 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_rectangle_76 {
  position: absolute;
  width: 880px;
  height: 168px;
  top: 0;
  left: 0;
  background: #f7f7fa;
  z-index: 90;
  border-radius: 20px;
}
.vol_rectangle_77 {
  position: relative; /* 修改为relative，便于内容自适应 */
  width: 100%;
  background: #ffffff;
  z-index: 77;
  border-radius: 30px;
  padding: 20px;  /* 给背景区域添加内边距，使其看起来更有层次 */
  box-sizing: border-box;  /* 确保padding不影响背景的宽高 */
  overflow: hidden;  /* 防止内容溢出 */
  margin-top: 10px;  /* 每个div之间的间隔 */
}
.gra_rectangle_77 {
  position: relative; /* 修改为relative，便于内容自适应 */
  width: 100%;
  background: #ffffff;
  z-index: 77;
  border-radius: 30px;
  /* left: -20px; */
  padding: 20px;  /* 给背景区域添加内边距，使其看起来更有层次 */
  box-sizing: border-box;  /* 确保padding不影响背景的宽高 */
  overflow: hidden;  /* 防止内容溢出 */
  margin-left: -10px; 
}
.vol_lexical_resources {
  position: relative;
  width: 940px;
  margin: 20px 0 0 0;
  border-radius: 30px;
  background-color: #ffffff;
  z-index: 107;
  height: auto; /* 高度自适应内容 */
  padding-bottom: 20px;
  max-height: 1800px; /* 可以设置一个合理的最大高度 */
}
.vol_group_78 {
  position: relative;
  width: 880px;
  /* height: 574px; */
  height: auto; /* 高度自适应内容 */
  margin: 40px 0 0 30px;
  z-index: 114;
  background-color: #F7F7FA;
  border-radius: 20px;
  /* padding: 20px; */
  padding-bottom: 20px;
  /* overflow: visible auto; */
}
.vol_important_crucial_significant {
  position: relative;
  width: 502px;
  height: 30px;
  top: 10px;
  margin: 25px 0 0 189px;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  /* background-color: #F7F7FA; */
  z-index: 116;
}
.vol_important {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_crucial_significant_pivotal {
  position: relative;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_group_79 {
  position: relative;
  width: 821.999px;
  margin: 24px 0 0 28px;
  z-index: 117;
  background-color: #ffffff;
  border-radius: 10px;
  /* 移除固定的高度，改为自适应 */
  padding-bottom: 10px; /* 确保底部有适当的间隔 */
}

.vol_group_7a {
  position: relative; /* 更改为relative，而不是absolute，保持内容自适应 */
  width: 821.999px;
  z-index: 118;
  border-radius: 10px;
  
  /* 移除绝对定位，让其根据内容自适应 */
}

.vol_crucial_definition {
  position: relative;
  width: 773.646px;
  margin: 11px 0 0 24.176px;
  top: 10px;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  
  z-index: 120;
  /* 移除固定的height属性，使其自适应 */
  padding-bottom: 10px; /* 保持一定的间距 */
}

.vol_crucial {
  position: relative;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}

.vol_essential_definition {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}

.vol_crucial_7b {
  position: relative;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}

.vol_example_sentence {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}


/* .vol_rectangle_7c {
  position: absolute;
  width: 821.999px;
  height: 112px;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 119;
  border-radius: 10px;
} */
.vol_group_7d {
  position: relative;
  width: 821.999px;
  height: 112px;
  margin: 10px 0 0 28px;
  z-index: 121;
  border-radius: 10px;
}
.vol_group_7e {
  position: absolute;
  width: 821.999px;
  height: 112px;
  top: 0;
  left: 0;
  z-index: 122;
  border-radius: 10px;
}
.vol_significant_definition {
  position: relative;
  width: 773.646px;
  height: 90px;
  margin: 11px 0 0 24.176px;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  z-index: 124;
}
.vol_significant {
  position: relative;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_impact_definition {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_significant_7f {
  position: relative;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_example {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}
.vol_example_sentence_80 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_rectangle_81 {
  position: absolute;
  width: 821.999px;
  height: 112px;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 123;
  border-radius: 10px;
}
.vol_group_82 {
  position: relative;
  width: 821.999px;
  height: 112px;
  margin: 10px 0 0 28px;
  z-index: 125;
  border-radius: 10px;
}
.vol_group_83 {
  position: absolute;
  width: 821.999px;
  height: 112px;
  top: 0;
  left: 0;
  z-index: 126;
  border-radius: 10px;
}
.vol_significant_definition_84 {
  position: relative;
  width: 773.646px;
  height: 90px;
  margin: 11px 0 0 24.176px;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  z-index: 128;
}
.vol_significant_85 {
  position: relative;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_impact_definition_86 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_significant_87 {
  position: relative;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_example_88 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}
.vol_example_sentence_89 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_rectangle_8a {
  position: absolute;
  width: 821.999px;
  height: 112px;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 127;
  border-radius: 10px;
}
.vol_group_8b {
  position: relative;
  width: 821.999px;
  height: 112px;
  margin: 10px 0 0 28px;
  z-index: 129;
  border-radius: 10px;
}
.vol_group_8c {
  position: absolute;
  width: 821.999px;
  height: 112px;
  top: 0;
  left: 0;
  z-index: 130;
  border-radius: 10px;
}
.vol_significant_definition_8d {
  position: relative;
  width: 773.646px;
  height: 90px;
  margin: 11px 0 0 24.176px;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  z-index: 132;
}
.vol_significant_8e {
  position: relative;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_impact_definition_8f {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_significant_90 {
  position: relative;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.vol_example_91 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}
.vol_example_sentence_92 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.vol_rectangle_93 {
  position: absolute;
  width: 821.999px;
  height: 112px;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 131;
  border-radius: 10px;
}
.vol_rectangle_94 {
  position: absolute;
  width: 880px;
  height: 574px;
  top: 0;
  left: 0;
  background: #f7f7fa;
  z-index: 115;
  border-radius: 20px;
}
.vol_lexical_resources_95 {
  position: relative;
  width: 940px;
  margin: 0 0 0 30px;
  /* height: 763px; */
  height: auto;
  top: 20px;
  left: 0;
  z-index: 108;
  border-radius: 30px;
}
.vol_group_96 {
  position: relative;
  width: 830px;
  height: 50px;
  margin: 24px 0 0 30px;
  font-size: 0px;
  z-index: 110;
  /* overflow: visible auto; */
}
.vol_lexical_resources_97 {
  display: block;
  position: relative;
  height: 30px;
  margin: 0 0 0 0;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 30px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 111;
}
.vol_line_98 {
  position: relative;
  width: 830px;
  height: 3px;
  margin: 7px 0 0 0;
  white-space: normal;
  background: url(../assets/report_pages/images/cf051f8b-6b13-4e18-beb9-3dad01454d53.png)
    no-repeat center;
  background-size: cover;
  z-index: 112;
}
.vol_vocabulary_definition {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 830px;
  height: 48px;
  margin: 10px 0 0 30px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 113;
}
.vol_rectangle_99 {
  position: relative;
  width: 940px;
  height: 763px;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 109;
  border-radius: 30px;
}

.pace_main_container {
  position: relative;
  width: 940px;
  height: 694px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 0px;
  background: #ffffff;
  border-radius: 30px;
}
.pace_pace_1 {
  display: block;
  position: relative;
  height: 30px;
  /* padding-top: 20px; */
  margin: 0 0 0 30px;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 30px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 62;
}
.pace_line {
  position: relative;
  width: 880px;
  height: 3px;
  margin: 7px 0 0 30px;
  background: url(../assets/report_pages/images/f4f6f677-daeb-4137-9f9c-5ae51b4244b7.png)
    no-repeat center;
  background-size: cover;
  z-index: 63;
}
.pace_taking_regular_pauses {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 880px;
  height: 60px;
  margin: 15px 0 0 30px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  z-index: 60;
}
.pace_flex_row_f {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 700px;
  height: 30px;
  margin: 20px 0 0 83px;
  z-index: 41;
}
.pace_your_average_pace {
  flex-shrink: 0;
  position: relative;
  height: 30px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 2;
}
.pace_your_pace_throughout_part {
  flex-shrink: 0;
  position: relative;
  height: 30px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 41;
}
.pace_wpm {
  display: block;
  position: relative;
  height: 14px;
  margin: 12px 0 0 392px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  white-space: nowrap;
  z-index: 39;
}

.pace_natural {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 30px;
  top: 0;
  left: 62px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 7;
}
.pace_natural_2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 30px;
  top: 0;
  left: 172px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 8;
}
.pace_group {
  position: absolute;
  height: 110.026px;
  top: 14px;
  right: 639px;
  left: 28px;
  background: url(../assets/report_pages/images/37dbfa1a-6a93-4efe-a845-f7fe96848201.png)
    no-repeat center;
  background-size: cover;
  z-index: 4;
}
.pace_text_8 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 30px;
  top: 12px;
  left: 74px;
  color: #ffffff;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 10;
}
.pace_number_250 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 14px;
  top: 20.968px;
  left: 349px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  white-space: nowrap;
  z-index: 38;
}
.pace_line_3 {
  position: absolute;
  width: 500px;
  height: 1px;
  top: 25.613px;
  left: 380px;
  background: url(../assets/report_pages/images/076e31d0-1ab3-4eb4-befb-f01c23c49617.png)
    no-repeat center;
  background-size: cover;
  z-index: 22;
}
.pace_number_90 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 30px;
  top: 43px;
  left: 20px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 6;
}
.pace_number_210 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 30px;
  top: 43px;
  left: 225px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 9;
}
.pace_group_4 {
  position: absolute;
  width: 486px;
  height: 108.724px;
  top: 43px;
  left: 387px;
  background: url(../assets/report_pages/images/651bfcaa-092c-460b-b23a-8afc62e94b5b.png)
    no-repeat center;
  background-size: cover;
  z-index: 40;
}
.pace_group_5 {
  position: absolute;
  width: 46.47px;
  height: 73.798px;
  top: 60.851px;
  left: 99.266px;
  background: url(../assets/report_pages/images/b3758ab5-d0fb-4cd8-85e6-f9742db3fefb.png)
    no-repeat center;
  background-size: cover;
  z-index: 15;
}
.pace_rectangle {
  position: absolute;
  width: 500px;
  height: 40px;
  top: 64px;
  left: 380px;
  background: #dbeff3;
  z-index: 17;
}
.pace_line_6 {
  position: relative;
  width: 500px;
  height: 1px;
  margin: 18.064px 0 0 0;
  background: url(../assets/report_pages/images/d27b3191-eec0-4df3-8d0b-9842c43c6e72.png)
    no-repeat center;
  background-size: cover;
  z-index: 21;
}
.pace_number_150 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 14px;
  top: 79.032px;
  left: 349px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  white-space: nowrap;
  z-index: 37;
}
.pace_slow {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 30px;
  top: 98px;
  left: 31px;
  color: #ffffff;
  font-family: Nunito, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 11;
}
.pace_fast {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 30px;
  top: 98px;
  left: 205px;
  color: #ffffff;
  font-family: Nunito, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 12;
}
.pace_number_50 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 30px;
  top: 109px;
  left: 0;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 5;
}
.pace_number_250_7 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 30px;
  top: 109px;
  left: 246px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 13;
}
.pace_wpm_8 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 30px;
  top: 132px;
  left: 95px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 14;
}
.pace_empty {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 14px;
  top: 133.871px;
  left: 358px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  white-space: nowrap;
  z-index: 36;
}
.pace_line_9 {
  position: absolute;
  width: 500px;
  height: 1px;
  top: 138.516px;
  left: 380px;
  background: url(../assets/report_pages/images/79b61c96-d74e-4442-97d6-7583c96532f3.png)
    no-repeat center;
  background-size: cover;
  z-index: 20;
}
.pace_flex_row_a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 514px;
  height: 11.29px;
  margin: -0.71px 0 0 396px;
  z-index: 35;
}
.pace_zero {
  flex-shrink: 0;
  position: relative;
  height: 11.29px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 11.29px;
  text-align: left;
  white-space: nowrap;
  z-index: 35;
}
.pace_line_b {
  flex-shrink: 0;
  position: relative;
  width: 500px;
  height: 1px;
  background: url(../assets/report_pages/images/26f59e8a-643c-49ae-b2fd-c03cf2d54c3c.png)
    no-repeat center;
  background-size: cover;
  z-index: 19;
}
.fluency_meter {
  position: absolute;
  width: 320px;
  height: 240px;
  top: 180px;
  left: 30px;
  z-index: 40;
}
.fluency_line {
  position: absolute;
  width: 600px;
  height: 200px;
  top: 200px;
  left: 340px;
  z-index: 28;
}
.pace_line_d {
  flex-shrink: 0;
  position: relative;
  width: 1px;
  height: 8.064px;
  background: url(../assets/report_pages/images/968077e4-1834-4d8a-bcd7-de4d2bd94d60.png)
    no-repeat center;
  background-size: cover;
  z-index: 23;
}
.pace_line_e {
  flex-shrink: 0;
  position: relative;
  width: 1px;
  height: 8.064px;
  background: url(../assets/report_pages/images/721429fb-2afd-480f-b80d-28d8508e23bd.png)
    no-repeat center;
  background-size: cover;
  z-index: 24;
}
.pace_line_f {
  flex-shrink: 0;
  position: relative;
  width: 1px;
  height: 8.064px;
  background: url(../assets/report_pages/images/6066571e-a30d-4ece-b1ae-d8e057e18d60.png)
    no-repeat center;
  background-size: cover;
  z-index: 25;
}
.pace_line_10 {
  flex-shrink: 0;
  position: relative;
  width: 1px;
  height: 8.064px;
  background: url(../assets/report_pages/images/ac2f7cde-419f-46e6-989a-b2489c51cebc.png)
    no-repeat center;
  background-size: cover;
  z-index: 26;
}
.pace_line_11 {
  flex-shrink: 0;
  position: relative;
  width: 1px;
  height: 8.064px;
  background: url(../assets/report_pages/images/f7b96923-e555-461e-9f08-986d53559bed.png)
    no-repeat center;
  background-size: cover;
  z-index: 28;
}
.pace_line_12 {
  flex-shrink: 0;
  position: relative;
  width: 1px;
  height: 8.064px;
  background: url(../assets/report_pages/images/bde66c9c-898b-42cd-898e-06d59d4bfb25.png)
    no-repeat center;
  background-size: cover;
  z-index: 27;
}
.pace_flex_row_b {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 425px;
  height: 30px;
  margin: 0px 0 0 447px;
  z-index: 33;
}
.pace_q1 {
  flex-shrink: 0;
  position: relative;
  height: 30px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 29;
}
.pace_q2 {
  flex-shrink: 0;
  position: relative;
  height: 30px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 30;
}
.pace_q3 {
  flex-shrink: 0;
  position: relative;
  height: 30px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 31;
}
.pace_q4 {
  flex-shrink: 0;
  position: relative;
  height: 30px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 32;
}
.pace_q5 {
  flex-shrink: 0;
  position: relative;
  height: 30px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 33;
}
.pace_rectangle_13 {
  position: relative;
  width: 880px;
  height: 214px;
  margin: 29.193px 0 0 30px;
  top: 300px;
  font-size: 0px;
  background: #f7f7fa;
  z-index: 43;
  overflow: visible auto;
  border-radius: 20px;
}
.pace_question_5 {
  display: block;
  position: relative;
  height: 30px;
  margin: 10px 0 0 387px;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 56;
}
.pace_quiet_environment {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 820px;
  height: 60px;
  margin: -5px 0 0 30px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  z-index: 57;
}
.pace_flex_row_dc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 695px;
  height: 30px;
  margin: 15px 0 0 30px;
  z-index: 59;
}
.pace_slow_pace {
  flex-shrink: 0;
  position: relative;
  height: 30px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 58;
}
.pace_improve_tips {
  flex-shrink: 0;
  position: relative;
  height: 30px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 59;
}
.pace_flex_row_cb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 820px;
  height: 46px;
  margin: 13px 0 0 30px;
  z-index: 51;
}
.pace_rectangle_14 {
  flex-shrink: 0;
  position: relative;
  width: 377px;
  height: 45px;
  background: #dfe1eb;
  z-index: 45;
  border-radius: 45px;
}
.pace_play_button {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 6.979px;
  left: 13.387px;
  background: url(../assets/report_pages/images/55150bd3-a7fb-4525-9703-be22001d087d.png)
    no-repeat center;
  background-size: cover;
  z-index: 46;
}
.pace_play_button.playing {
  background: url(../assets/record_pages/test_equipment/start.png) 
  no-repeat center;
  background-size: cover;
}


.pitch_play_button.playing {
  background: url(../assets/record_pages/test_equipment/start.png) 
  no-repeat center;
  background-size: cover;
}
.pitch_play_button_6.playing {
  background: url(../assets/record_pages/test_equipment/start.png) 
  no-repeat center;
  background-size: cover;
}
.pace_time_display {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 20.877px;
  top: 12.329px;
  left: 51.508px;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  z-index: 49;
}
.pace_ellipse {
  position: absolute;
  width: 15.149px;
  height: 15px;
  top: 14.706px;
  left: 155.318px;
  background: url(../assets/report_pages/images/55bf2166-b5e8-41f6-b3c1-1255b37d03ab.png)
    no-repeat center;
  background-size: cover;
  cursor: pointer;
  z-index: 48;
}
.pace_line_15 {
  position: absolute;
  width: 191px;
  height: 7px;
  top: 50%;
  left: 163px;
  background: url(../assets/report_pages/images/1609b7a2-ef5c-410a-abbc-497c4fbff09d.png)
    no-repeat center;
  background-size: cover;
  transform: translate(0, -50%);
  z-index: 47;
}
.pace_rectangle_16 {
  flex-shrink: 0;
  position: relative;
  width: 377px;
  height: 45px;
  background: #dfe1eb;
  z-index: 51;
  border-radius: 45px;
}
.pace_play_button_17 {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 6.979px;
  left: 13.387px;
  background: url(../assets/report_pages/images/49ca3ee5-7079-4e57-8e7d-a0aa6856479b.png)
    no-repeat center;
  background-size: cover;
  z-index: 52;
}
.pace_play_button_17.playing {
  background: url(../assets/record_pages/test_equipment/start.png) 
  no-repeat center;
  background-size: cover;
}
.pace_time_display_18 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 20.877px;
  top: 12.329px;
  left: 51.508px;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  z-index: 55;
}
.pace_ellipse_19 {
  position: absolute;
  width: 15.149px;
  height: 15px;
  top: 14.706px;
  left: 155.318px;
  background: url(../assets/report_pages/images/e5dfb38a-406a-465a-88a5-9cc1fd91f808.png)
    no-repeat center;
  background-size: cover;
  cursor: pointer;
  z-index: 54;
}
.pace_line_1a {
  position: absolute;
  width: 191px;
  height: 7px;
  top: 50%;
  left: 163px;
  background: url(../assets/report_pages/images/2b219fe9-ad05-4e66-85cc-f2f4a894b9b1.png)
    no-repeat center;
  background-size: cover;
  transform: translate(0, -50%);
  z-index: 53;
}

.pauses_main_container {
  top: 20px;
  position: relative;
  width: 940px;
  height: 632px;
  margin: 0 auto;
  font-size: 0px;
  padding-top: 10px;
}
.pauses_pauses_1 {
  display: block;
  position: relative;
  height: 27.376px;
  margin: 30px 0 0 30px;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 30px;
  font-weight: 900;
  line-height: 27.376px;
  text-align: left;
  white-space: nowrap;
  z-index: 32;
}
.pauses_line {
  position: relative;
  width: 880px;
  height: 3px;
  margin: 7.624px 0 0 30px;
  background: url(../assets/report_pages/images/35231258-fc2d-4a5a-900c-2e3879f41a05.png)
    no-repeat center;
  background-size: cover;
  z-index: 33;
}
.pauses_regular_pauses {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 880px;
  height: 42px;
  margin: 15px 0 0 30px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 30;
}
.pauses_flex_row_ac {
  position: relative;
  width: 880px;
  height: 45px;
  margin: 21px 0 0 30px;
  z-index: 20;
}
.pauses_rectangle {
  position: absolute;
  width: 282px;
  height: 45px;
  top: 0;
  left: 0;
  border: 1px solid #d9d9d9;
  z-index: 14;
  border-radius: 10px;
}
.pauses_a_a_band_lower {
  position: absolute;
  width: 237px;
  height: 30px;
  top: 50%;
  left: 50%;
  cursor: pointer;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  white-space: nowrap;
  background: transparent;
  border: none;
  transform: translate(-50.21%, -41.67%);
  z-index: 18;
}
.pauses_rectangle_2 {
  position: absolute;
  width: 183px;
  height: 45px;
  top: 0;
  left: 419px;
  border: 1px solid #d9d9d9;
  z-index: 17;
  border-radius: 10px;
}
.pauses_b_band {
  position: absolute;
  width: 172px;
  height: 30px;
  top: 50%;
  left: 50%;
  cursor: pointer;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  white-space: nowrap;
  background: transparent;
  border: none;
  transform: translate(-50.29%, -41.67%);
  z-index: 16;
}
.pauses_rectangle_3 {
  position: absolute;
  width: 123px;
  height: 45px;
  top: 0;
  left: 757px;
  border: 1px solid #d9d9d9;
  z-index: 20;
  border-radius: 10px;
}
.pauses_c_band {
  position: absolute;
  width: 106px;
  height: 30px;
  top: 50%;
  left: 50%;
  cursor: pointer;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  white-space: nowrap;
  background: transparent;
  border: none;
  transform: translate(-49.53%, -41.67%);
  z-index: 19;
}
.pauses_flex_row_a {
  position: relative;
  width: 880px;
  height: 30px;
  margin: -5px 0 0 30px;
  z-index: 28;
}
.pauses_rectangle_4 {
  position: absolute;
  width: 60px;
  height: 30px;
  top: 0;
  left: 570px;
  background: #505f98;
  border: 2px solid #ffffff;
  z-index: 28;
  border-radius: 50px;
}
.pauses_you {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 20px;
  top: calc(50% - 10px);
  left: calc(50% - 20px);
  color: #ffffff;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  z-index: 29;
}
.pauses_rectangle_5 {
  position: absolute;
  width: 880px;
  height: 20px;
  top: 5px;
  left: 0;
  background: url(../assets/report_pages/images/d2ac7129-86a9-419f-b2c2-c8f895acfd98.png)
    no-repeat center;
  background-size: cover;
  z-index: 11;
  border-radius: 20px;
}
.pauses_flex_row_e {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 488px;
  height: 45px;
  margin: -5px 0 0 307px;
  z-index: 26;
}
.pauses_rectangle_6 {
  flex-shrink: 0;
  position: relative;
  width: 148px;
  height: 45px;
  border: 1px solid #d9d9d9;
  z-index: 23;
  border-radius: 10px;
}
.pauses_b_band_7 {
  position: absolute;
  width: 143px;
  height: 30px;
  top: 50%;
  left: 50%;
  cursor: pointer;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  white-space: nowrap;
  background: transparent;
  border: none;
  transform: translate(-48.95%, -38.33%);
  z-index: 22;
}
.pauses_rectangle_8 {
  flex-shrink: 0;
  position: relative;
  width: 165px;
  height: 45px;
  border: 1px solid #d9d9d9;
  z-index: 26;
  border-radius: 10px;
}
.pauses_button_c_band {
  position: absolute;
  width: 154px;
  height: 30px;
  top: 50%;
  left: 50%;
  cursor: pointer;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  white-space: nowrap;
  background: transparent;
  border: none;
  transform: translate(-50.32%, -38.33%);
  z-index: 25;
}
.pauses_good_job_pauses {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 880px;
  height: 48px;
  margin: 30px 0 0 30px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 8;
}
.pauses_rectangle_9 {
  position: relative;
  width: 780px;
  height: 230px;
  margin: 38px 0 0 80px;
  background: #f7f7fa;
  z-index: 2;
  overflow: visible auto;
  border-radius: 20px;
}
.pauses_flex_row_c {
  position: relative;
  width: 551px;
  height: 36px;
  margin: 20px 0 0 20px;
  z-index: 5;
}
.pauses_icon_tip {
  position: absolute;
  width: 7.26%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../assets/report_pages/images/42b91a77-410a-4fc4-8de6-f0dd403dea01.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 5;
}
.pauses_add_fillers {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 20px;
  top: 5px;
  left: 60px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  z-index: 4;
}
.pauses_flex_row_ad {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 442px;
  height: 108px;
  margin: 14px 0 0 200px;
  z-index: 7;
}
.pauses_filler_words {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  width: 123px;
  height: 108px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 27.28px;
  text-align: left;
  z-index: 6;
}
.pauses_expressions {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  width: 293px;
  height: 108px;
  left: 50px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 27.28px;
  text-align: left;
  z-index: 7;
}
.pauses_replace_fillers {
  display: block;
  position: relative;
  height: 24px;
  margin: 10px 0 0 33px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 3;
}
.pauses_rectangle_a {
  position: absolute;
  width: 940px;
  height: 632px;
  top: 0;
  left: 0;
  background: url(../assets/report_pages/images/f320b4cd-b527-4ac1-9772-2430f33f1dbc.png)
    no-repeat center;
  background-size: cover;
}
.linking_group_78 {
  position: relative;
  width: 880px;
  /* height: 574px; */
  height: auto; /* 高度自适应内容 */
  margin: 120px 0 0 30px;
  z-index: 114;
  background-color: #F7F7FA;
  border-radius: 20px;
  /* padding: 20px; */
  padding-bottom: 20px;
  /* overflow: visible auto; */
}
.linking_group_79 {
  position: relative;
  width: 880px;
  /* height: 574px; */
  height: auto; /* 高度自适应内容 */
  margin: 20px 0 0 30px;
  z-index: 114;
  background-color: #F7F7FA;
  border-radius: 20px;
  /* padding: 20px; */
  padding-bottom: 20px;
  /* overflow: visible auto; */
}
.linking_important_crucial_significant {
  position: relative;
  width: 502px;
  height: 30px;
  top: 10px;
  margin: 25px 0 0 30px;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  /* background-color: #F7F7FA; */
  z-index: 116;
}
.linking_crucial_significant_pivotal {
  position: relative;
  color: black;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.flu_essential_definition {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
}
.flu_missed {
  position: relative;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 800;
  line-height: 30px;
  text-align: left;
}
.flu_sum {
  position: relative;
  /* color: #505f98; */
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 800;
  line-height: 30px;
  text-align: left;
}
.flu_misused {
  position: relative;
  color: red;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 800;
  line-height: 30px;
  text-align: left;
  text-decoration: line-through;
}

.pro_main_container {
  position: relative;
  box-sizing: border-box;
  width: 900px;
  height: auto;
  padding-bottom: 20px;
  margin: 0 auto;
}
.pro_rectangle {
  position: relative;
  width: 900px;
  height: 150px;
  /* padding-bottom: 20px;
  padding-top: 20px; */
  top: 0;
  left: 0;
  background: #f7f7fa;
  border-radius: 20px;
}
.pro_group_1 {
  position: absolute;
  width: 90px;
  height: 100px;
  top: 22px;
  left: 15px;
  font-size: 0px;
  z-index: 4;
}
.pro_you_said {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  width: 90px;
  height: 30px;
  margin: 0 0 0 0;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  white-space: nowrap;
  z-index: 5;
}
.pro_play_button {
  position: relative;
  width: 60px;
  height: 60px;
  margin: 10px 0 0 16px;
  background: url(../assets/report_pages/images/7ca7c4f9-b3a1-4b3e-b245-88387f8345d5.png)
    no-repeat center;
  background-size: cover;
  z-index: 6;
}
.pro_line {
  position: absolute;
  width: 2px;
  height: 105px;
  top: 50%;
  left: 115px;
  background: url(../assets/report_pages/images/eefa276a-8c00-458f-bcfd-89a2d22f25c0.png)
    no-repeat center;
  background-size: cover;
  transform: translate(0, -49.52%);
  z-index: 9;
}
.pro_line_2 {
  position: absolute;
  width: 2px;
  height: 105px;
  top: 50%;
  left: 785px;
  background: url(../assets/report_pages/images/7e7c82d1-f248-4410-adf8-eea635c3b958.png)
    no-repeat center;
  background-size: cover;
  transform: translate(0, -49.52%);
  z-index: 10;
}
.pro_group_3 {
  position: absolute;
  width: 80px;
  height: 100px;
  top: 26px;
  left: 800px;
  font-size: 0px;
  z-index: 1;
}
.pro_try_this {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  width: 80px;
  height: 30px;
  margin: 0 0 0 0;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  white-space: nowrap;
  z-index: 2;
}
.pro_play_button_4 {
  position: relative;
  width: 60px;
  height: 60px;
  margin: 10px 0 0 9px;
  background: url(../assets/report_pages/images/8593476c-9982-4f55-96e6-c33cc1bce5ac.png)
    no-repeat center;
  background-size: cover;
  z-index: 3;
}
.pro_development_in_question {
  position: absolute;
  width: 325px;
  height: 30px;
  top: 15px;
  left: 50%;
  font-family: Nunito, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  text-overflow: initial;
  white-space: nowrap;
  transform: translate(-52%, 0);
  z-index: 8;
}
.pro_development {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
}
.pro_in {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}
.pro_empty {
  position: relative;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}
.pro_question_4 {
  position: relative;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
}
.pro_different_development_stages {
  position: absolute;
  width: 573px;
  height: 20px;
  top: 50px;
  left: 50%;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  text-overflow: initial;
  white-space: normal;
  transform: translate(-51.13%, 0);
  z-index: 7;
}
.pro_countries_in_different {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.pro_development_5 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}
.pro_empty_6 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.pro_countries_in_different_7 {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.pro_play_button.playing {
  background: url(../assets/record_pages/test_equipment/start.png) 
  no-repeat center;
  background-size: cover;
}
.pro_play_button_4.playing {
  background: url(../assets/record_pages/test_equipment/start.png) 
  no-repeat center;
  background-size: cover;
}

.pitch_main_container {
  position: relative;
  width: 940px;
  height: 536px;
  margin: 0 auto;
  /* padding-top: 20px; */
}
.pitch_rectangle {
  position: relative;
  width: 940px;
  height: 536px;
  top: 0;
  left: 0;
  font-size: 0px;
  background: #ffffff;
  z-index: 4;
  border-radius: 30px;
}
.pitch_intonation_pitch_variation {
  display: block;
  position: relative;
  height: 30px;
  margin: 26px 0 0 30px;
  padding-top: 5px;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 30px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 6;
}
.pitch_line {
  position: relative;
  width: 880px;
  height: 3px;
  margin: 7px 0 0 30px;
  background: url(../assets/report_pages/images/eb805c1e-2bf0-4a18-a0b1-5e9dba661628.png)
    no-repeat center;
  background-size: cover;
  z-index: 7;
}
.pitch_pitch_variation {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 880px;
  height: 48px;
  margin: 15px 0 0 30px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 8;
}
.pitch_average_pitch_variation {
  display: block;
  position: relative;
  height: 30px;
  margin: 16px 0 0 30px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 22;
}
.pitch_group {
  position: absolute;
  width: 20px;
  height: 316px;
  top: 195px;
  left: 134px;
  background: url(../assets/report_pages/images/798e3cfe-00ff-4f70-aa94-479841692513.png)
    no-repeat center;
  background-size: cover;
  z-index: 12;
}
.pitch_question_div {
  position: absolute;
  width: 354px;
  height: 30px;
  top: 180px;
  left: 440px;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 27;
}
.pitch_answering_span {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.pitch_question_span {
  position: relative;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
}
.pitch_said_span {
  position: relative;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.pitch_too_high_span {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 30px;
  top: 225px;
  left: 191px;
  color: #999999;
  font-family: Nunito, var(--default-font-family);
  font-size: 24px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 18;
}
.pitch_treat_people_span {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 80px;
  width: 500px;
  top: 220px;
  left: 439px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  white-space: normal ;
  z-index: 28;
}
.pitch_hz_span {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 30px;
  top: 269px;
  left: 50px;
  color: #999999;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 21;
}
.pitch_group_1 {
  position: absolute;
  width: 223px;
  height: 90px;
  top: 286px;
  left: 146px;
  background: url(../assets/report_pages/images/1dd3bc3c-2794-43f2-9994-8f0dd0371d27.png)
    no-repeat center;
  background-size: cover;
  z-index: 17;
}
.pitch_natural_button {
  position: absolute;
  width: 128px;
  height: 30px;
  top: 33px;
  left: 47px;
  cursor: pointer;
  color: #6d7ebf;
  font-family: Nunito, var(--default-font-family);
  font-size: 24px;
  font-weight: 900;
  line-height: 30px;
  white-space: nowrap;
  background: transparent;
  border: none;
  z-index: 19;
}
.pitch_rectangle_2 {
  position: absolute;
  width: 471px;
  height: 45px;
  top: 320px;
  left: 439px;
  background: #dfe1eb;
  z-index: 30;
  border-radius: 45px;
}
.pitch_play_button {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 6.979px;
  left: 13.387px;
  background: url(../assets/report_pages/images/6af0d24e-d98e-4295-aa52-472774a8d895.png)
    no-repeat center;
  background-size: cover;
  z-index: 31;
}
.pitch_time_display {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 20.877px;
  top: 12.329px;
  left: 51.508px;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  z-index: 34;
}
.pitch_ellipse {
  position: absolute;
  width: 15.149px;
  height: 15px;
  cursor: pointer;
  top: 14.706px;
  left: 155.318px;
  background: url(../assets/report_pages/images/7a8a6bfa-48b7-4fbf-9944-a0a61aa63938.png)
    no-repeat center;
  background-size: cover;
  z-index: 33;
}
.pitch_line_3 {
  position: absolute;
  width: 288px;
  height: 7px;
  top: 50%;
  cursor: pointer;
  left: 163px;
  background: url(../assets/report_pages/images/a4b4e884-4623-4361-b5c4-ddcf440d2f13.png)
    no-repeat center;
  background-size: cover;
  transform: translate(0, -50%);
  z-index: 32;
}
.pitch_frame_button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 77px;
  height: 29px;
  top: 303px;
  left: 107px;
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  background: linear-gradient(180deg, #7081c3, #505f98);
  border: none;
  z-index: 23;
  border-radius: 30px;
}
.pitch_span_hz {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 30px;
  color: #ffffff;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 24;
}
.pitch_rectangle_4 {
  position: absolute;
  width: 223px;
  height: 90px;
  top: 366px;
  left: 146px;
  background: rgba(219, 239, 243, 0.5);
  z-index: 15;
  border-radius: 0 20px 20px 0;
}
.pitch_span_natural_m {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 29px;
  top: 31px;
  left: 47px;
  color: #539fb1;
  font-family: Nunito, var(--default-font-family);
  font-size: 24px;
  font-weight: 900;
  line-height: 29px;
  text-align: left;
  white-space: nowrap;
  z-index: 16;
}
.pitch_span_natural_f {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 29px;
  top: 31px;
  left: 47px;
  color: #6D7EBF;
  font-family: Nunito, var(--default-font-family);
  font-size: 24px;
  font-weight: 900;
  line-height: 29px;
  text-align: left;
  white-space: nowrap;
  z-index: 16;
}
.pitch_span_improve {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 30px;
  top: 376px;
  left: 439px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 36;
}
.pitch_rectangle_5 {
  position: absolute;
  width: 471px;
  height: 45px;
  top: 421px;
  left: 439px;
  background: #dfe1eb;
  z-index: 38;
  border-radius: 45px;
}
.pitch_play_button_6 {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 6.979px;
  left: 13.387px;
  background: url(../assets/report_pages/images/7e491d9c-2593-4d45-9aed-edc2aa549e54.png)
    no-repeat center;
  background-size: cover;
  z-index: 39;
}
.pitch_time_progress {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 20.877px;
  top: 12.329px;
  left: 51.508px;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  z-index: 42;
}
.pitch_ellipse_7 {
  position: absolute;
  width: 15.149px;
  cursor: pointer;
  height: 15px;
  top: 14.706px;
  left: 155.318px;
  background: url(../assets/report_pages/images/9a23aa3b-d3b1-4069-8a78-f5d062c03f04.png)
    no-repeat center;
  background-size: cover;
  z-index: 41;
}
.pitch_line_8 {
  position: absolute;
  width: 288px;
  height: 7px;
  top: 50%;
  cursor: pointer;
  left: 163px;
  background: url(../assets/report_pages/images/c5958272-d6c8-4812-a4df-679e1787cd34.png)
    no-repeat center;
  background-size: cover;
  transform: translate(0, -50%);
  z-index: 40;
}
.pitch_frequency {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 30px;
  top: 444px;
  left: 62px;
  color: #999999;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 20;
}
.pitch_too_flat {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 30px;
  top: 467px;
  left: 208px;
  color: #999999;
  font-family: Nunito, var(--default-font-family);
  font-size: 24px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  z-index: 13;
}

.suggestion_main_container {
  position: relative;
  top: 960px;
  left: 400px;
  z-index: 254;
  min-height: 1187px; /* 添加最小高度防止初次渲染为 0 */
  height: auto; 
  
  display: flex;
  flex-direction: column; /* 使子元素垂直排列 */
}
.suggestion_frame {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 296px;
  height: 120px;
  top: 0;
  left: 26px;
  padding: 10px 10px 10px 10px;
  background: #f7f7fa;
  z-index: 1;
  border-radius: 15px;
}
.suggestion_strategy {
  flex-shrink: 0;
  position: relative;
  width: 160px;
  height: 30px;
  cursor: pointer;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 40px;
  font-weight: 700;
  line-height: 30px;
  white-space: nowrap;
  background: transparent;
  border: none;
  z-index: 2;
}
.suggestion_frame_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 296px;
  height: 120px;
  top: 0;
  left: 352px;
  padding: 10px 10px 10px 10px;
  background: #dfe1eb;
  z-index: 3;
  border-radius: 15px;
}
.suggestion_tips {
  flex-shrink: 0;
  position: relative;
  width: 81px;
  height: 30px;
  cursor: pointer;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 40px;
  font-weight: 700;
  line-height: 30px;
  white-space: nowrap;
  background: transparent;
  border: none;
  z-index: 4;
}
.suggestion_frame_2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 296px;
  height: 120px;
  top: 0;
  left: 678px;
  padding: 10px 10px 10px 10px;
  background: #dfe1eb;
  z-index: 5;
  border-radius: 15px;
}
.suggestion_variations {
  flex-shrink: 0;
  position: relative;
  width: 188px;
  height: 30px;
  cursor: pointer;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 40px;
  font-weight: 700;
  line-height: 30px;
  white-space: nowrap;
  background: transparent;
  border: none;
  z-index: 6;
}
.suggestion_frame_3 {
  position: absolute;
  width: 1000px;
  /* height: 925px; */
  height: auto;
  top: 106px;
  left: 0;
  background: #f7f7fa;
  z-index: 7;
  border-radius: 15px;
}
.suggestion_frame_4 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 940px;
  margin: 30px 0 0 30px;
  padding: 30px 30px 30px 30px;
  background: #ffffff;
  z-index: 8;
  border-radius: 15px;
}
.suggestion_thinking_process {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 945px;
  /* height: 280px; */
  height: auto;
  margin: 13.5px 0 0 30px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  z-index: 2;
  /* overflow-y: auto; */
}
.improvement_main_container {
  position: relative;
  width: 1005px;
  min-height: 185px;
  height: auto;
  margin: 0 auto;
  font-size: 0px;
}
.improvement_customized_sample_answer {
  display: block;
  position: relative;
  height: 23px;
  margin: 15px 0 0 30px;
  padding-top: 10px;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 30px;
  font-weight: 900;
  line-height: 23px;
  text-align: left;
  white-space: nowrap;
  z-index: 9;
}
.improvement_line {
  position: relative;
  width: 948px;
  height: 3px;
  margin: 13.5px 0 0 28.5px;
  top: 5px;
  background: url(../assets/report_pages/images/efd9ac56-5043-4c1e-b17c-da2ae26e00e2.png)
    no-repeat center;
  background-size: cover;
  z-index: 2;
}
.improvement_tailor_made_sample {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 945px;
  height: 40px;
  margin: 18.5px 0 0 30px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  z-index: 14;
}
.improvement_group_1 {
  position: relative;
  width: 569px;
  height: 34px;
  margin: 15px 0 0 148px;
  z-index: 10;
}
.improvement_rectangle {
  position: absolute;
  width: 700px;
  height: 100%;
  top: 0;
  left: 10px;
  /* background: #dfe1eb; */
  z-index: 11;
  border-radius: 20px;
}
.improvement_bottle_water {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  width: 850px;
  height: 50px;
  white-space: normal;
  top: 20%;
  left: 4%;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  z-index: 12;
  
}
.improvement_question {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  top: 8px;
  right: 584px;
  bottom: 6px;
  left: -109px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  z-index: 13;
}
.improvement_play_bar {
  position: relative;
  width: 945px;
  height: 44px;
  margin: 35px 0 0 30px;
  z-index: 3;
}
.improvement_rectangle_2 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #dfe1eb;
  z-index: 4;
  border-radius: 45px;
}
.improvement_play_button {
  position: absolute;
  top: 6.823px;
  right: 898.911px;
  bottom: 6.556px;
  left: 16.089px;
  background: url(../assets/report_pages/images/ee5ca53b-1c90-4ef9-b690-03705b22a1fa.png)
    no-repeat center;
  background-size: cover;
  z-index: 5;
}
.improvement_time_info {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  top: 11.227px;
  right: 786px;
  bottom: 12.359px;
  left: 57px;
  color: #505f98;
  font-family: Nunito, var(--default-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  z-index: 8;
}
.improvement_ellipse {
  position: absolute;
  width: 15.149px;
  height: 15px;
  top: 14.706px;
  left: 160px;
  cursor: pointer;
  background: url(../assets/report_pages/images/9a23aa3b-d3b1-4069-8a78-f5d062c03f04.png)
    no-repeat center;
  background-size: cover;
  z-index: 41;
}
.improvement_line_3 {
  position: absolute;
  top: 17.617px;
  right: 29.385px;
  bottom: 18.383px;
  left: 168px;
  cursor: pointer;
  background: url(../assets/report_pages/images/9c09c426-12ab-4cef-85d3-3c3c286e9fb0.png)
    no-repeat center;
  background-size: cover;
  z-index: 6;
}
.improvement_evaluation_results {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 945px;
  min-height: 250px;
  height: auto;
  margin: 10px 0 0 30px;
  padding-bottom: 20px;
  color: #000000;
  font-family: Nunito, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  overflow-y: auto;
  z-index: 1;
}
.improvement_evaluation_results::-webkit-scrollbar {
  width: 10px; /* 滚动条的宽度 */
  background-color: #f9f9f9; /* 滚动条的背景色 */
}

/* 滚动条滑块 */
.improvement_evaluation_results::-webkit-scrollbar-thumb {
  background-color: #b3b3b3; /* 滑块的颜色 */
  border-radius: 5px; /* 滑块的圆角 */
  border: 2px solid #ffffff; /* 滑块边缘的颜色 */
}
.improvement_transcript {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #ffffff;
  border-radius: 20px;
}
.improvement_play_button.playing {
  background: url(../assets/record_pages/test_equipment/start.png) 
  no-repeat center;
  background-size: cover;
}
#overall {
  position: relative;
  z-index: 9999; /* 确保在高亮层上方 */
}

/* Joyride高亮层调整 */
.react-joyride__overlay {
  z-index: 9998 !important;
}
/* 控制面板容器 */
.tour_control_container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  flex-direction: row-reverse; /* 反转布局顺序 */
  z-index: 10002;
  display: flex;
  align-items: center;
  gap: 15px;
}

/* 折叠箭头 */
.expand_toggle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #505F98;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  transition: all 0.3s ease;
}

.plus_icon {
  position: relative;
  width: 20px;
  height: 20px;
  transition: all 0.3s ease;
}

.plus_icon::before,
.plus_icon::after {
  content: '';
  position: absolute;
  background: white;
  transition: all 0.3s ease;
}

.plus_icon::before {
  width: 100%;
  height: 2px;
  top: 50%;
  transform: translateY(-50%);
}

.plus_icon::after {
  width: 2px;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}

/* 控制按钮面板 */
.control_panel {
  display: flex;
  gap: 12px;
  opacity: 0;
  transform: translateX(30px);
  transition: all 0.3s ease;
  pointer-events: none;
}

.control_panel.visible {
  opacity: 1;
  transform: translateX(0);
  pointer-events: auto;
}

/* 原有按钮样式保留 */
.control_button {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #505F98;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 1.3;
  text-align: center;
  white-space: pre-wrap;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  transition: transform 0.2s ease;
}

.control_button:hover {
  transform: scale(1.05);
}