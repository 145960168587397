.react-joyride__overlay {
  z-index: 9998 !important;
}

/* 新增的类，确保 tooltip 上移 */
/* .joyride-tooltip {
  transform: translateY(-40%) !important;
} */

/* 控制面板样式 */
.tour_control_container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  flex-direction: row-reverse;
  z-index: 10002;
  display: flex;
  align-items: center;
  gap: 15px;
}

.expand_toggle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #505F98;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  transition: all 0.3s ease;
}

.plus_icon {
  position: relative;
  width: 20px;
  height: 20px;
  transition: all 0.3s ease;
}

.plus_icon::before,
.plus_icon::after {
  content: '';
  position: absolute;
  background: white;
  transition: all 0.3s ease;
}

.plus_icon::before {
  width: 100%;
  height: 2px;
  top: 50%;
  transform: translateY(-50%);
}

.plus_icon::after {
  width: 2px;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.control_panel {
  display: flex;
  gap: 12px;
  opacity: 0;
  transform: translateX(30px);
  transition: all 0.3s ease;
  pointer-events: none;
}

.control_panel.visible {
  opacity: 1;
  transform: translateX(0);
  pointer-events: auto;
}

.control_button {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #505F98;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 1.3;
  text-align: center;
  white-space: pre-wrap;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  transition: transform 0.2s ease;
}

.control_button:hover {
  transform: scale(1.05);
}
