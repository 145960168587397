:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

.main_container {
  overflow: hidden;
}

.main_container,
.main_container * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.main_container {
  position: fixed;
  width: 459px;
  height: 368px;
  margin: 0 auto;
  background: #ffffff;
  top: 230px;      
  /* left: 50%; */
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  z-index: 10000;
}
.frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 392px;
  margin: 30px 0 0 30px;
  padding: 20px 20px 20px 20px;
  background: #dfe1eb;
  border-radius: 15px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
}
.my_points {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  min-width: 0;
  height: 34px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 900;
  line-height: 33.6px;
  text-align: left;
  white-space: nowrap;
  z-index: 1;
}
.points {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  min-width: 0;
  height: 64px;
  color: #505f98;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 64px;
  font-weight: 900;
  line-height: 64px;
  text-align: left;
  white-space: nowrap;
  z-index: 2;
}
.frame_1 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 36px;
  position: relative;
  width: 392px;
  margin: 30px 0 0 30px;
  z-index: 7;
}
.frame_2 {
  flex-shrink: 0;
  position: relative;
  width: 178px;
  height: 66px;
  background: #ffffff;
  /* border: 2px solid #505f98; */
  z-index: 8;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
}
.frame_3 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 150px;
  margin: 11px 0 0 14px;
  padding: 10px 10px 10px 10px;
  z-index: 9;
}
.use_points {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 10;
}
.frame_4 {
  flex-shrink: 0;
  position: relative;
  width: 178px;
  height: 66px;
  background: #ffffff;
  z-index: 11;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
}
.frame_5 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 128px;
  margin: 11px 0 0 25px;
  padding: 10px 10px 10px 10px;
  z-index: 12;
}
.active {
  border: 2px solid #505f98 !important;
}
.use_points_6 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 13;
}
.flex_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 399px;
  height: 45px;
  margin: 23px 0 0 30px;
  z-index: 5;
}
.frame_7 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 100px;
  padding: 10px 10px 10px 10px;
  border: 3px solid #505f98;
  z-index: 3;
  border-radius: 15px;
}
.cancel {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #505f98;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 4;
}
.frame_8 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 100px;
  padding: 10px 10px 10px 10px;
  background: #505f98;
  border: 3px solid #505f98;
  z-index: 5;
  border-radius: 15px;
}
.confirm {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #ffffff;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 6;
}
