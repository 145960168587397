:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

.main_container {
  overflow: hidden;
}

.main_container,
.main_container * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.main_container {
  position: relative;
  width: 978px;
  height: 736px;
  top: -783px;
  left: 120px;
  margin: 0 auto;
  background: #ffffff;
  /* overflow: hidden; */
  border-radius: 15px;
  z-index: 88;
}

.frame_1 {
  position: relative;
  width: 214px;
  height: 120px;
  background: #f7f7fa;
  border: 3px solid transparent; /* 必须基础边框设置 */
  transition: border-color 0.3s;
  z-index: auto;
  /* overflow: hidden; */
  /* cursor: pointer; */
  border-radius: 15px;
}
.frame_1.active {
  border-color: #505f98;
  box-shadow: 0 0 8px rgba(80, 95, 152, 0.2);
}
.flex_column {
  position: absolute;
  width: 164px;
  top: 2px;
  bottom: 7px;
  left: 7px;
  z-index: auto;
}
.frame_2 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 5px;
  position: relative;
  width: 139px;
  margin: 89px 0 0 0;
  z-index: 11;
}
.frame_3 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 40px;
  padding: 3px 5px 3px 5px;
  background: #a353d4;
  z-index: 12;
  border-radius: 15px;
}
.part_1 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 10px;
  color: #ffffff;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 10px;
  font-weight: 700;
  line-height: 10px;
  text-align: left;
  white-space: nowrap;
  z-index: 13;
}
.frame_4 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 33px;
  padding: 3px 5px 3px 5px;
  background: #ffdb8f;
  z-index: 14;
  border-radius: 15px;
}
.new {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 10px;
  color: #ffffff;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 10px;
  font-weight: 700;
  line-height: 10px;
  text-align: left;
  white-space: nowrap;
  z-index: 15;
}
.frame_5 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 56px;
  padding: 3px 5px 3px 5px;
  background: #d453a8;
  z-index: 16;
  border-radius: 15px;
}
.practiced {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 10px;
  color: #ffffff;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 10px;
  font-weight: 700;
  line-height: 10px;
  text-align: left;
  white-space: nowrap;
  z-index: 17;
}
.frame_6 {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  position: absolute;
  width: 46px;
  height: 36px;
  top: 0;
  left: 5px;
  z-index: 9;
}
.frame_7 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 46px;
  height: 36px;
  padding: 0;
  z-index: 10;
}
.frame_8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 4px;
  position: absolute;
  width: 159px;
  /* height: 40px; */
  top: 10px;
  left: 5px;
  z-index: auto;
}
.travel {
  align-self: stretch;
  position: relative;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 14px;
  font-weight: 550;
  line-height: 1.2; /* 调整行高 */
  text-align: left;
  /* 添加换行属性 */
  word-wrap: break-word;
  white-space: normal; /* 覆盖nowrap */
  overflow-wrap: break-word;
  max-width: 100%; /* 防止溢出 */
}
.frame_9 {
  display: flex;
  align-items: center;
  width: 100%; /* 保证宽度 */
  gap: 10px;
  position: relative;
  margin-top: auto; /* 自动顶部间距 */
}

.frame_a {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* 左对齐 */
  width: auto; /* 自动宽度 */
  border-bottom: 1px solid #000000;
  padding-bottom: 2px; /* 添加底部间距 */
}

.questions {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 14px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  white-space: nowrap;
  z-index: 22;
}
.group {
  width: 20px;
  height: 20px;
  background: url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5" stroke="%23000000" stroke-width="2"/></svg>') no-repeat center;
  /* transform: rotate(0deg); */
  /* transition: transform 0.3s; */
}

.star {
  position: absolute;
  cursor: pointer;
  width: 30px;
  height: 30px;
  top: 7px;
  right: 7px;
  background: url(../assets/mock_test/images/9dbc96ad-d34f-4b0b-b474-81c81396b7ef.svg)
    no-repeat center;
  background-size: cover;
  z-index: 8;
  border-radius: 1px;
}

.star.active {
  background: url(../assets/mock_test/images/star_fill.svg)
    no-repeat center;
  background-size: cover;
}
.page_selection {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 20px;
  position: relative;
  width: 179px;
  margin: 10px 0 0 399px;
}
.group_d9 {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  /* background: url(https://static.codia.ai/image/2025-03-10/03d26c76-6512-47ec-9d4a-c8e88dc60af4.svg)
    no-repeat center;
  background-size: cover; */
  background: white;
  border: none;
  z-index: 1;
}
.one {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 12px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  white-space: nowrap;
  z-index: 2;
}
.slash {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 12px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  white-space: nowrap;
  z-index: 3;
}
.five {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 12px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  white-space: nowrap;
  z-index: 4;
}
.group_da {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 40px;
  /* background: url(https://static.codia.ai/image/2025-03-10/2bd2cb37-a63c-4012-aa2a-c1bb216454db.svg)
    no-repeat center;
  background-size: cover; */
  background: white;
  border: none;
  z-index: 5;
}
.frame_db {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 408px;
  height: 45px;
  top: 30px;
  left: 30px;
  /* border-top: 3px solid #dfe1eb; */
  z-index: 240;
}
.frame_dc {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  /* gap: 5px; */
  position: relative;
  z-index: 241;
}
.frame_dd {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 56px;
  padding: 10px 10px 10px 10px;
  /* border-top: 3px solid #505f98; */
  z-index: 242;
}


.latest_questions_all {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #dcdfea;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 243;
}
.frame_de {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 92px;
  padding: 10px 10px 10px 10px;
  /* border-top: 3px solid #dcdfea; */
  z-index: 244;
}
.latest_questions_df {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #dcdfea;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 245;
}
.frame_e0 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 92px;
  padding: 10px 10px 10px 10px;
  /* border-top: 3px solid #dfe1eb; */
  z-index: 246;
}
.latest_questions_e1 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #dfe1eb;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 247;
}
.frame_e2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 74px;
  padding: 10px 10px 10px 10px;
  /* border-top: 3px solid #dfe1eb; */
  z-index: 248;
}
.latest_questions_e3 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #dfe1eb;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 249;
}
.frame_e4 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 74px;
  padding: 10px 10px 10px 10px;
  /* border-top: 3px solid #dfe1eb; */
  z-index: 250;
}

.frame_dd,
.frame_de,
.frame_e0,
.frame_e2,
.frame_e4 {
  border-bottom: 3px solid transparent; /* 保持布局稳定 */
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.active_filter {
  border-bottom: 3px solid #505f98 !important;
}


.latest_questions_e5 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #dfe1eb;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 251;
}

.latest_questions_all,
.latest_questions_df,
.latest_questions_e1,
.latest_questions_e3,
.latest_questions_e5 {
  color: #dfe1eb;
  font-weight: 900;
  font-size: 18px;

}

/* 激活状态文字 */
.active_filter span {
  color: #505f98;
  font-weight: 600;
}

.frame_e6 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 380px;
  height: 80px;
  top: 33px;
  right: 38px;
  padding: 0 15px 0 15px;
  background: #ffffff;
  z-index: 252;
  border-radius: 15px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
}
.frame_e7 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 20px;
  padding: 10px 0 10px 0;
  z-index: 253;
}
.select_questions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 60px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  text-overflow: initial;
  z-index: 254;
  /* overflow: hidden; */
}
.frame_e8 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 5px;
  position: relative;
  width: 106px;
  padding: 10px 10px 10px 10px;
  background: rgba(255, 255, 255, 0.2);
  z-index: 255;
  border-radius: 15px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
}
.check_ring {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;
  /* background: url(https://static.codia.ai/image/2025-03-10/034df4c8-d1ed-4790-9e24-dccfd6e95223.svg) */
  background: url(../assets/mock_test/images/04d5de16-bf44-43c2-83be-9106b3378685.svg)
    no-repeat center;
  background-size: cover;
  z-index: 256;
}
.check_ring.active {
  background: url(../assets/mock_test/images/034df4c8-d1ed-4790-9e24-dccfd6e95223.svg)
  no-repeat center;
  background-size: cover;
}
.part_1_e9 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 20px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  z-index: 257;
}
.frame_ea {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 5px;
  position: relative;
  width: 133px;
  padding: 10px 10px 10px 10px;
  background: rgba(255, 255, 255, 0.2);
  z-index: 258;
  border-radius: 15px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
}
.question {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;
  background: url(../assets/mock_test/images/04d5de16-bf44-43c2-83be-9106b3378685.svg)
    no-repeat center;
  background-size: cover;
  z-index: 259;
}
.question.active {
  background: url(../assets/mock_test/images/034df4c8-d1ed-4790-9e24-dccfd6e95223.svg)
  no-repeat center;
  background-size: cover;
}
.part_2_3 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 20px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  z-index: 260;
}
.frame_eb {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 61px;
  padding: 15px 12px 15px 12px;
  background: #dfe1eb;
  z-index: 261;
  border-radius: 15px;
}
.frame_eb.active {
  background-color: #505f98;
}
.go {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 29px;
  color: #ffffff;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: left;
  white-space: nowrap;
  z-index: 262;
}
.filter_choose_part {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 203px;
  height: 36px;
  top: 95px;
  left: 30px;
  z-index: 233;
}
.filter {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 66px;
  /* border: 2px solid #505f98; */
  z-index: 234;
  border-radius: 15px;
}
.filter.active {
  border: 2px solid #505f98 !important;
}

.frame_ec {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 66px;
  padding: 10px 10px 10px 10px;
  z-index: 235;
}
.part_1_ed {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 16px;
  color: #505f98;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;
  z-index: 236;
}
.filter_ee {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 127px;
  z-index: 237;
  border-radius: 15px;
}
.filter_ee.active {
  border: 2px solid #505f98 !important;
}
.active_text {
  font-weight: 700 !important;
  color: #505f98 !important;
}
.frame_ef {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 127px;
  padding: 10px 10px 10px 10px;
  z-index: 238;
}
.part_2_3_f0 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 16px;
  color: #dcdfea;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;
  z-index: 239;
}

.part_1_ed,
.part_2_3_f0 {
    color: #dcdfea;
    font-weight: 400; 
}

.cards_container {
  display: grid;
  /* grid-template-columns: repeat(4, 1fr); */
  gap: 15px;
  margin: 150px 30px;
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* gap: 20px; */
}

/* 分页按钮样式 */
.page_selection {
  position: absolute;
  top: 680px; 
  left: 85px;
  /* left: 20%; */
  transform: translateX(-50%);
  z-index: 999;
}

.pagination_btn {
  width: 40px;
  height: 40px;
  border: 2px solid #505F98;
  border-radius: 50%;
  background: white;
  color: #505F98;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s;
}

.pagination_btn.active {
  border-color: #505F98;
  color: #505F98;
}

.pagination_btn.active:hover {
  background: #505F98;
  color: white;
}

.pagination_btn.disabled {
  border-color: #DFE1EB;
  color: #DFE1EB;
  cursor: not-allowed;
}
.status_badge {
  display: inline-flex;
  align-items: center;
  padding: 3px 5px;
  border-radius: 15px;
}

.status_part1 {
  background: #A353D4; /* 黄色 */
}

.status_part2_3 {
  background: #A353D4; /* 黄色 */
}

.status_new {
  background: #FFDB8F; /* 黄色 */
}

.status_practiced {
  background: #D453A8; /* 红色 */
}

.status_text {
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 10px;
  font-weight: 700;
  color: #FFFFFF;
  white-space: nowrap;
}
.tooltip_wrapper {
  cursor: pointer;
  position: relative; /* 创建新的定位上下文 */
  width: 100%;
}

.content_tooltip {
  position: absolute;
  top: calc(100% + 5px); /* 向下偏移5px */
  left: 0;
  width: 380px; /* 恢复原始宽度 */
  background: white;
  color: black;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 10px;
  /* font-size: 8; */
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
  z-index: 9999;
  /* 保持原有文本换行设置 */
  white-space: pre-wrap;
  word-wrap: break-word;
}