:root {
    --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
      "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
      "Source Han Sans CN", sans-serif;
  }
  
  .main_container {
    overflow: hidden;
  }
  
  .main_container,
  .main_container * {
    box-sizing: border-box;
  }
  
  input,
  select,
  textarea,
  button {
    outline: 0;
  }
  
  .main_container {
    position: relative;
    width: 1280px;
    height: 832px;
    margin: 0 auto;
  }


  
  .dim_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }

  .direction {
    z-index: 100;
  }
  .macbook_air_home {
    position: absolute;
    width: 1280px;
    height: 832px;
    top: 0;
    left: 0;
    background: #f7f7fa;
    overflow: hidden;
  }
  .frame_1 {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 30px;
    position: relative;
    width: 270px;
    margin: 787px 0 0 505px;
    z-index: 1;
  }
  .about_us {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 72px;
    z-index: 2;
  }
  .about_us_2 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 25px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    z-index: 3;
  }
  .about_us_3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 36px;
    cursor: pointer;
    background: transparent;
    border: none;
    z-index: 4;
  }
  .about_us_terms {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 25px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    z-index: 5;
  }
  .about_us_4 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 36px;
    cursor: pointer;
    background: transparent;
    border: none;
    z-index: 6;
  }
  .about_us_privacy {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 25px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    z-index: 7;
  }
  .about_us_5 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 36px;
    z-index: 8;
  }
  .about_us_help {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 25px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    z-index: 9;
  }
  .logo_tentative {
    position: absolute;
    width: 212px;
    height: 120px;
    top: 30px;
    left: 30px;
    background: url(../assets/mock_test/images/13d134b8c6623a3c97a4772a4dbec54b24cacf65.png)
      no-repeat center;
    background-size: cover;
    z-index: 34;
    border-radius: 15px;
  }
  .flex_row_fab {
    position: absolute;
    width: 1220px;
    height: 746px;
    top: 30px;
    left: 30px;
    z-index: 35;
  }
  .frame_6 {
    position: absolute;
    width: 976px;
    height: 746px;
    top: 0;
    left: 244px;
    background: #f7f7fa;
    z-index: 35;
    overflow: hidden;
    border-radius: 15px;
  }
  .group {
    position: relative;
    width: 916px;
    height: 601px;
    margin: 103px 0 0 30px;
    z-index: 40;
  }
  .flex_column_ad {
    position: absolute;
    height: 601px;
    top: 0;
    right: 2px;
    left: 0;
    z-index: 25;
  }
  .frame_7 {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 60px;
    position: relative;
    width: 668px;
    margin: 0 0 0 0;
    z-index: 42;
  }
  .statistic_banner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 122px;
    padding: 15px 15px 15px 15px;
    cursor: pointer;
    background: #ffffff;
    border: none;
    z-index: 43;
    overflow: hidden;
    border-radius: 15px;
    /* letter-spacing: 5px; */
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  }
  .frame_8 {
    display: inline-flex;
    align-items: baseline;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    min-width: 82px;
    z-index: 44;
  }
  .frame_9 {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: auto;
    margin-right: 0px; /* 新增右间距 */
    padding: 10px 0px 10px 5px;
    z-index: 45;
  }
  .number_3 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: auto; /* 允许数字自然扩展 */
    min-width: 42px; /* 保持最小宽度 */
    height: 35px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 50px;
    font-weight: 900;
    line-height: 35px;
    text-align: center;
    white-space: nowrap;
    z-index: 46;
  }
  .frame_a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    width: 36px;
    padding: 10px 0 10px 0;
    z-index: 47;
  }
  .days_ago {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 36px;
    height: 18px;
    top: -5px;
    left: -5px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 18px;
    text-align: center;
    white-space: nowrap;
    z-index: 48;
  }
  .frame_b {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    padding: 0 10px 0 10px;
    z-index: 49;
  }
  .last_mock_exam {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 72px;
    height: 18px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: center;
    white-space: nowrap;
    letter-spacing: 5px;
    z-index: 50;
  }
  .statistic_banner_c {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 122px;
    padding: 15px 15px 15px 15px;
    cursor: pointer;
    background: #ffffff;
    border: none;
    z-index: 51;
    overflow: hidden;
    border-radius: 15px;
    
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  }

  .frame_d {
    display: flex;
    align-items: flex-end;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    width: 58px;
    z-index: 52;
  }
  .frame_e {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    width: 40px;
    top: 5px;
    left: 5px;
    padding: 10px 5px 10px 5px;
    z-index: 53;
  }
  .number_3_f {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 30px;
    height: 35px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 50px;
    font-weight: 900;
    line-height: 35px;
    text-align: center;
    white-space: nowrap;
    z-index: 54;
  }
  .frame_10 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    width: 18px;
    left: 25px;
    padding: 10px 0 10px 0;
    z-index: 55;
  }
  .text_9 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 18px;
    height: 18px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: center;
    white-space: nowrap;
    z-index: 56;
  }
  .div_frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    padding: 0 10px 0 10px;
    z-index: 57;
  }
  .text_a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 72px;
    height: 18px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: center;
    white-space: nowrap;
    z-index: 58;
  }
.button_5 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 122px;
    padding: 15px 15px 15px 15px;
    cursor: pointer;
    background: #ffffff;
    border: none;
    z-index: 59;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  }
  .div_frame_11 {
    display: flex;
    align-items: flex-end;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    width: 84px;
    z-index: 60;
  }
  .div_frame_12 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    width: 84px;
    padding: 10px 5px 10px 5px;
    z-index: 61;
  }
  .text_b {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 74px;
    height: 35px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 50px;
    font-weight: 900;
    line-height: 35px;
    text-align: center;
    white-space: nowrap;
    z-index: 62;
  }
  .div_frame_13 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    padding: 0 10px 0 10px;
    z-index: 63;
  }
  .text_c {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 72px;
    height: 18px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: center;
    white-space: nowrap;
    z-index: 64;
  }
  .button_6 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 122px;
    padding: 15px 15px 15px 15px;
    cursor: pointer;
    background: #ffffff;
    border: none;
    z-index: 65;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  }
  .div_frame_14 {
    display: flex;
    align-items: flex-end;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    width: 84px;
    z-index: 66;
  }
  .div_frame_15 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    width: 84px;
    padding: 10px 5px 10px 5px;
    z-index: 67;
  }
  .text_d {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 74px;
    height: 35px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 50px;
    font-weight: 900;
    line-height: 35px;
    text-align: center;
    white-space: nowrap;
    z-index: 68;
  }
  .div_frame_16 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    padding: 0 10px 0 10px;
    z-index: 69;
  }
  .text_e {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 72px;
    height: 18px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: center;
    white-space: nowrap;
    z-index: 70;
  }
  .div_group {
    position: relative;
    width: 911px;
    height: 385px;
    margin: 39px 0 0 3px;
    z-index: 75;
    border-radius: 15px;
  }
  .div_frame_17 {
    position: absolute;
    width: 425px;
    height: 385px;
    top: 0;
    left: 0;
    background: #ffffff;
    z-index: 76;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  }
  .div_frame_18 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: relative;
    width: 121px;
    height: 56px;
    margin: 32px 0 0 153px;
    padding: 10px 10px 10px 10px;
    z-index: 77;
  }
  .text_f {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 42px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    text-align: left;
    white-space: nowrap;
    z-index: 78;
  }
  .wrapper_6 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 307px;
    margin: 27px 0 0 60px;
    z-index: 79;
  }
  .div_frame_19 {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    padding: 5px 10px 5px 10px;
    z-index: 80;
  }
  .text_10 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 22px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    white-space: nowrap;
    z-index: 81;
  }
  .div_frame_1a {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 307px;
    padding: 5px 10px 5px 10px;
    z-index: 82;
  }
  .wrapper_8 {
    flex-shrink: 0;
    position: relative;
    width: 233px;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 83;
  }
  .text_11 {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
  }
  .text_12 {
    cursor: pointer;
    position: relative;
    color: #505f98;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 900;
    line-height: 22.4px;
    text-align: left;
  }
  .text_12:hover {
    text-decoration: underline; /* 添加下划线 */
  }
  .text_13 {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
  }
  .div_frame_1b {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 307px;
    padding: 5px 10px 5px 10px;
    z-index: 84;
  }
  .text_14 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 22px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    white-space: nowrap;
    z-index: 85;
  }
  .text_with_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 5px;
    position: relative;
    width: 295px;
    height: 27px;
    margin: 13px 0 0 66px;
    z-index: 86;
  }
  .light_bulb {
    flex-shrink: 0;
    position: relative;
    width: 20px;
    height: 20px;
    z-index: 87;
  }
  .group_1c {
    position: relative;
    width: 20px;
    height: 9.851px;
    margin: 0 0 0 0;
    z-index: 89;
    overflow: visible auto;
  }
  .line {
    position: relative;
    width: 1px;
    height: 1.791px;
    margin: 0 0 0 9.667px;
    background: url(../assets/mock_test/images/8eeaf837-6556-46b2-bfe7-fc37ec917304.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 92;
  }
  .line_1d {
    position: relative;
    width: 1.723px;
    height: 1.543px;
    margin: 1.054px 0 0 2.099px;
    background: url(../assets/mock_test/images/2c1af508-5602-4cee-8894-169b0e571254.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 94;
  }
  .line_1e {
    position: relative;
    width: 1.723px;
    height: 1.543px;
    margin: -1.613px 0 0 16.1px;
    background: url(../assets/mock_test/images/bfb0e6aa-9f35-4cc1-b62c-f0ead1abebfa.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 93;
  }
  .flex_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 20px;
    margin: 5.532px 0 0 0;
    z-index: 91;
  }
  .line_1f {
    flex-shrink: 0;
    position: relative;
    width: 2px;
    height: 1px;
    background: url(../assets/mock_test/images/2e8ddb7c-30e3-4e0f-b3cd-2e7341c2058c.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 90;
  }
  .line_20 {
    flex-shrink: 0;
    position: relative;
    width: 2px;
    height: 1px;
    background: url(../assets/mock_test/images/956cf2f2-b464-428e-a37d-613c061fcc6c.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 91;
  }
  .group_21 {
    position: relative;
    width: 14.719px;
    height: 17.612px;
    margin: -7.463px 0 0 2.69px;
    background: url(../assets/mock_test/images/52638b3f-7561-4ac0-ad05-dc2409df4065.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 88;
  }
  .practice_experience {
    flex-shrink: 0;
    position: relative;
    width: 270px;
    height: 27px;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 95;
  }
  .suitable {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .last_minute_review {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 900;
    line-height: 24px;
    text-align: left;
  }
  .suitable_22 {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .last_minute_review_23 {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 900;
    line-height: 24px;
    text-align: left;
  }
  .frame_24 {
    position: relative;
    width: 184px;
    height: 76px;
    margin: 31px 0 0 121px;
    cursor: pointer;
    background: transparent;
    border: none;
    border-radius: 15px;
    transition: transform 0.2s ease, box-shadow 0.2s ease; 
    z-index: 96;
  }
  .frame_24:hover {
    transform: scale(0.95); /* 按下去的缩放效果 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 模拟按下的阴影效果 */
  }
  .small_button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 184px;
    height: 76px;
    top: 0;
    left: 0;
    padding: 15px 20px 15px 20px;
    background: #505f98;
    z-index: 98;
    border-radius: 15px;
  }
  .start_simulation_test {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 34px;
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 24px;
    font-weight: 900;
    line-height: 34px;
    text-align: left;
    white-space: nowrap;
    z-index: 99;
  }
  .rectangle {
    position: absolute;
    width: 184px;
    height: 74px;
    top: 3px;
    left: 0;
    background: #3e476f;
    z-index: 97;
    border-radius: 15px;
  }
  .frame_25 {
    position: absolute;
    width: 425px;
    height: 385px;
    top: 0;
    left: 486px;
    background: #ffffff;
    z-index: 100;
    overflow: hidden;
    border-radius: 15px;
    /* opacity: 0.3; */
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  }
  
  .frame_26 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: relative;
    width: 121px;
    height: 56px;
    margin: 32px 0 0 153px;
    padding: 10px 10px 10px 10px;
    z-index: 101;
  }
  .simulation_test {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 42px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    text-align: left;
    white-space: nowrap;
    z-index: 102;
  }
  .type_explanation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    position: relative;
    width: 307px;
    margin: 27px 0 0 60px;
    z-index: 103;
  }
  .frame_27 {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    padding: 5px 10px 5px 10px;
    z-index: 104;
  }
  .simulation_test_details {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 22px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    white-space: nowrap;
    z-index: 105;
  }
  .frame_28 {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 307px;
    padding: 5px 10px 5px 10px;
    z-index: 106;
  }
  .simulation_test_details_29 {
    flex-shrink: 0;
    position: relative;
    width: 232px;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 107;
  }
  .get_all_recordings {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
  }
  .no_score_report {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 900;
    line-height: 22.4px;
    text-align: left;
  }
  .no_score_report_2a {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
  }
  .frame_2b {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 307px;
    padding: 5px 10px 5px 10px;
    z-index: 108;
  }
  .recreate_ielts_speaking_process {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 22px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    white-space: nowrap;
    z-index: 109;
  }
  .text_with_icon_2c {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 5px;
    position: relative;
    width: 295px;
    height: 27px;
    margin: 13px 0 0 66px;
    z-index: 110;
  }
  .light_bulb_2d {
    flex-shrink: 0;
    position: relative;
    width: 20px;
    height: 20px;
    z-index: 111;
  }
  .group_2e {
    position: relative;
    width: 20px;
    height: 9.851px;
    margin: 0 0 0 0;
    z-index: 113;
    overflow: visible auto;
  }
  .line_2f {
    position: relative;
    width: 1px;
    height: 1.791px;
    margin: 0 0 0 9.667px;
    background: url(../assets/mock_test/images/b68070e2-ccce-4780-9ded-6975b22b6f11.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 116;
  }
  .line_30 {
    position: relative;
    width: 1.723px;
    height: 1.543px;
    margin: 1.054px 0 0 2.099px;
    background: url(../assets/mock_test/images/f4016e69-14d5-48fa-a33c-cf3689b67dc6.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 118;
  }
  .line_31 {
    position: relative;
    width: 1.723px;
    height: 1.543px;
    margin: -1.613px 0 0 16.1px;
    background: url(../assets/mock_test/images/919092b3-d898-4f3a-8500-10a7b2ec68fa.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 117;
  }
  .flex_row_bb {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 20px;
    margin: 5.532px 0 0 0;
    z-index: 115;
  }
  .line_32 {
    flex-shrink: 0;
    position: relative;
    width: 2px;
    height: 1px;
    background: url(../assets/mock_test/images/6ea164cf-4ca6-4600-8fbb-83918b27d025.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 114;
  }
  .line_33 {
    flex-shrink: 0;
    position: relative;
    width: 2px;
    height: 1px;
    background: url(../assets/mock_test/images/974c5245-9213-4956-ac2a-8ff967f53d4b.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 115;
  }
  .group_34 {
    position: relative;
    width: 14.719px;
    height: 17.612px;
    margin: -7.463px 0 0 2.69px;
    background: url(../assets/mock_test/images/b6bd8ee7-42d5-45cc-b724-65109b21c400.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 112;
  }
  .random_question_bank {
    flex-shrink: 0;
    position: relative;
    width: 270px;
    height: 27px;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 119;
  }
  .suitable_35 {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .understand_exam_process {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 900;
    line-height: 24px;
    text-align: left;
  }
  .suitable_36 {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .understand_exam_process_37 {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 900;
    line-height: 24px;
    text-align: left;
  }
  .small_button_38 {
    position: relative;
    width: 184px;
    height: 76px;
    margin: 31px 0 0 121px;
    cursor: pointer;
    background: transparent;
    border: none;
    border-radius: 15px;
    transition: transform 0.2s ease, box-shadow 0.2s ease; 
    z-index: 96;
  }
  .small_button_38:hover {
    transform: scale(0.95); /* 按下去的缩放效果 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 模拟按下的阴影效果 */
  }
  .check_practice_record {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 184px;
    height: 76px;
    top: 0;
    left: 0;
    padding: 15px 20px 15px 20px;
    background: #505f98;
    z-index: 122;
    border-radius: 15px;
  }
  .rectangle_39 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 34px;
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 24px;
    font-weight: 900;
    line-height: 34px;
    text-align: left;
    white-space: nowrap;
    z-index: 123;
  }
  .want_to_practice_specific_parts {
    position: absolute;
    width: 184px;
    height: 74px;
    top: 3px;
    left: 0;
    background: #3e476f;
    z-index: 121;
    border-radius: 15px;
  }
  .go_to_speaking_practice_question_bank {
    position: relative;
    width: 488px;
    height: 25px;
    margin: 39px 0 0 17px;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 41;
  }
  .want_to_practice_specific_parts_3a {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25.2px;
    text-align: left;
  }
  .go_to_speaking_practice_question_bank_3b {
    cursor: pointer;
    position: relative;
    color: #505f98;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25.2px;
    text-align: left;
  }
  .go_to_speaking_practice_question_bank_3b:hover{
    text-decoration: underline;
  }
  .see_it {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25.2px;
    text-align: left;
  }
  .button_frame {
    position: absolute;
    width: 184px;
    height: 77px;
    top: 17px;
    left: 732px;
    cursor: pointer;
    background: transparent;
    border: none;
    z-index: 71;
    border-radius: 15px;
    transition: transform 0.2s ease, box-shadow 0.2s ease; 
  }
  .button_frame:hover {
    transform: scale(0.95); /* 按下去的缩放效果 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 模拟按下的阴影效果 */
  }
  .small_button_3c {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 184px;
    height: 77px;
    top: 0;
    left: 0;
    padding: 15px 20px 15px 20px;
    background: #505f98;
    z-index: 73;
    border-radius: 15px;
  }
  .check_practice_record_3d {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 34px;
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 24px;
    font-weight: 900;
    line-height: 34px;
    text-align: left;
    white-space: nowrap;
    z-index: 74;
  }
  .rectangle_3e {
    position: absolute;
    width: 184px;
    height: 74px;
    top: 3px;
    left: 0;
    background: #3e476f;
    z-index: 72;
    border-radius: 15px;
  }
  .frame_3f {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 254px;
    height: 62px;
    top: 50%;
    left: 50%;
    padding: 10px 67px 10px 67px;
    background: #f7f7fa;
    transform: translate(-50%, -577.42%);
    z-index: 38;
  }
  .mock_exam {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 42px;
    color: #505f98;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    text-align: left;
    white-space: nowrap;
    z-index: 39;
  }
  .divider {
    position: absolute;
    width: 916px;
    height: 5px;
    top: 46px;
    left: 50%;
    transform: translate(-49.67%, 0);
    z-index: 35;
  }
  .line_40 {
    position: rela;
    width: 100%;
    height: 100%; /* 或者指定具体的高度值 */
    top: 0; /* 或者使用合适的负值，例如 -10px */
    left: -0.16%;
    background: url(../assets/mock_test/images/cf649d28-462c-4dc1-8dc7-cf5f106be00f.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 30;
}
  .sidebar {
    position: absolute;
    width: 212px;
    height: 601px;
    top: 139px;
    left: 0;
    background: #ffffff;
    z-index: 10;
    border-radius: 15px;
  }
  .frame_41 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 10px;
    position: relative;
    width: 185px;
    height: 211px;
    margin: 20px 0 0 14px;
    z-index: 18;
  }
  .sidebar_home {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 15px;
    position: relative;
    width: 185px;
    padding: 10px 15px 10px 15px;
    z-index: 19;
    border-radius: 15px;
  }
  .sidebar_home:hover {
    /* opacity: 0.1; */
    background-color: rgba(83, 95, 148, 0.1);
  }
  .home {
    flex-shrink: 0;
    position: relative;
    width: 20px;
    height: 20px;
    z-index: 20;
    overflow: hidden;
  }
  .icon {
    position: relative;
    width: 18.667px;
    height: 17.625px;
    margin: 0.88px 0 0 0.67px;
    background: url(../assets/mock_test/images/95f2bfea-2529-437b-9f4d-f5089857b0da.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 21;
  }
  .my_home {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 120px;
    height: 18px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: left;
    white-space: nowrap;
    z-index: 22;
  }
  .sidebar_my_home {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 15px;
    position: relative;
    width: 185px;
    padding: 10px 15px 10px 15px;
    cursor: pointer;
    background: #dcdfea;
    border: none;
    z-index: 23;
    border-radius: 15px;
  }
  .sidebar_my_home_45:hover {
    /* opacity: 0.1; */
    background-color: rgba(83, 95, 148, 0.1);
  }
  .mock_exam_42 {
    flex-shrink: 0;
    position: relative;
    width: 20px;
    height: 20px;
    z-index: 24;
  }
  .trophy {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 25;
    overflow: hidden;
  }
  .icon_43 {
    position: relative;
    width: 18.667px;
    height: 18.667px;
    margin: 0.67px 0 0 0.67px;
    background: url(../assets/mock_test/images/5da60b12-a346-426f-87f2-7b8f444d1df0.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 26;
  }
  .mock_exam_44 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 120px;
    height: 18px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: left;
    white-space: nowrap;
    z-index: 27;
  }
  .sidebar_my_home_45 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 15px;
    position: relative;
    width: 185px;
    padding: 10px 15px 10px 15px;
    z-index: 28;
    border-radius: 15px;
  }
  .oral_practice {
    flex-shrink: 0;
    position: relative;
    width: 20px;
    height: 20px;
    background: url(../assets/mock_test/images/00545adf-3b5f-458d-8452-7e1e936e50c6.png)
      no-repeat center;
    background-size: cover;
    z-index: 29;
  }
  .my_home_46 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 120px;
    height: 18px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: left;
    white-space: nowrap;
    z-index: 30;
  }
  .sidebar_my_home_47 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 15px;
    position: relative;
    width: 185px;
    padding: 10px 15px 10px 15px;
    z-index: 31;
    border-radius: 15px;
  }
  .sidebar_my_home_47:hover {
    /* opacity: 0.1; */
    background-color: rgba(83, 95, 148, 0.1);
  }
  .mock_record {
    flex-shrink: 0;
    position: relative;
    width: 20px;
    height: 20px;
    background: url(../assets/mock_test/images/5bb0da40-4d34-441f-bdc4-622f86d87d77.png)
      no-repeat center;
    background-size: cover;
    z-index: 32;
  }
  .buy_points {
    flex-shrink: 0;
    position: relative;
    width: 20px;
    height: 20px;
    background: url(../assets/main_page/images/buy.png)
      no-repeat center;
    background-size: cover;
    z-index: 32;
  }
  .my_home_48 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 120px;
    height: 18px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: left;
    white-space: nowrap;
    z-index: 33;
  }
  .divider_49 {
    position: relative;
    width: 150px;
    margin: 167px 0 0 25px;
    background: #f7f7fa;
    z-index: 16;
  }
  .line_4a {
    position: absolute;
    width: 102%;
    height: Infinity%;
    top: -Infinity%;
    left: -1%;
    background: url(../assets/mock_test/images/83244dd3-3aa8-4136-95c4-1e5d67b4ba8b.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 17;
  }
  .sidebar_my_home_4b {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    gap: 15px;
    position: relative;
    width: 190px;
    height: 61px;
    margin: 15px 0 0 11px;
    padding: 10px 15px 10px 15px;
    z-index: 13;
    border-radius: 15px;
  }
  .sidebar_my_home_4b:hover {
    /* opacity: 0.1; */
    background-color: rgba(83, 95, 148, 0.1);
  }
  .my_account {
    flex-shrink: 0;
    position: relative;
    width: 20px;
    height: 20px;
    background: url(../assets/mock_test/images/f811bf0b-bf33-4d39-a5ed-67e35605c483.png)
      no-repeat center;
    background-size: cover;
    z-index: 14;
  }
  .my_home_4c {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 120px;
    height: 18px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: left;
    white-space: nowrap;
    z-index: 15;
  }
  .sidebar_my_home_4d {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    gap: 15px;
    position: relative;
    width: 50px;
    margin: 83px 0 0 159px;
    padding: 10px 15px 10px 15px;
    z-index: 11;
    border-radius: 15px;
  }
  .help {
    flex-shrink: 0;
    position: relative;
    width: 20px;
    height: 20px;
    background: url(../assets/mock_test/images/8a85ab1b-9c51-4b13-ae82-376b4431c2e4.png)
      no-repeat center;
    background-size: cover;
    z-index: 12;
  }

  .jump_main_container {
    position: relative;
    top: 220px;
    left: 120px;
    width: 578px;
    height: 356px;
    margin: 0 auto;
    background: #ffffff;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    z-index: 100;
  }
  .jump_frame {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: relative;
    width: 308px;
    margin: 0 0 0 135px;
    padding: 10px 10px 10px 10px;
    z-index: 81;
  }
   .jump_ready_to_go {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 54px;
    color: #535f94;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 36px;
    font-weight: 900;
    line-height: 54px;
    text-align: left;
    white-space: nowrap;
    z-index: 82;
  }
  .jump_line {
    position: relative;
    width: 521px;
    height: 5px;
    margin: -0.5px 0 0 28.5px;
    top: -10px;
    background-color: #505F98;
    background: url(../assets/main_page/images/line.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 100;
  }
   .jump_text_2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 520px;
    height: 81px;
    margin: 13.5px 0 0 29px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
  }
   .jump_frame_button {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    width: 253px;
    margin: 10px 0 0 162px;
    cursor: pointer;
    background: #f7f7fa;
    border: none;
    z-index: 83;
    border-radius: 15px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  }
   .jump_frame_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 164px;
    padding: 5px 0 5px 0;
    z-index: 84;
  }
   .jump_my_points {
    flex-shrink: 0;
    position: relative;
    width: 164px;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 85;
  }
   .jump_my_points_1 {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
  }
  .jump_points {
    position: relative;
    color: #535f94;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
  }
   .jump_frame_2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 110px;
    padding: 0 10px 0 10px;
    z-index: 86;
  }
   .jump_frame_3 {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 72px;
    z-index: 87;
  }
   .jump_purchase_score {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 25px;
    color: #505f98;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    z-index: 88;
  }
   .jump_flex_row_a {
    position: relative;
    width: 473px;
    height: 52px;
    margin: 22px 0 0 53px;
    z-index: 98;
  }
   .jump_small_button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 47.15%;
    height: 88.71%;
    top: 0;
    left: 0;
    padding: 10px 20px 10px 20px;
    background: #505f98;
    z-index: 94;
    border-radius: 15px;
  }
   .jump_i_am_thinking {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 25px;
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    z-index: 95;
  }
   .jump_small_button_4 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 47.15%;
    height: 88.71%;
    top: 0;
    left: 52.85%;
    padding: 10px 20px 10px 20px;
    background: #d453a8;
    z-index: 98;
    border-radius: 15px;
  }
   .jump_start_mock_exam {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 25px;
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    z-index: 99;
  }
  .jump_rectangle {
    position: absolute;
    width: 47.15%;
    height: 95.16%;
    top: 4.84%;
    left: 0;
    background: #3e476f;
    z-index: 93;
    border-radius: 15px;
  }
  .jump_rectangle_5 {
  position: absolute;
  width: 47.15%;
  height: 95.16%;
  top: 4.84%;
  left: 52.85%;
  background: #782c5e;
  z-index: 97;
  border-radius: 15px;
  }
  .help_main_container {
    left: 100px;
    top: 220px;
    position: relative;
    width: 346px;
    height: 491px;
    margin: 0 auto;
    background: #ffffff;
    overflow: hidden;
    border-radius: 15px;
    z-index: 80;
  }
  .help_close {
    cursor: pointer;
    position: relative;
    width: 20px;
    height: 20.085px;
    margin: 16px 0 0 310px;
    background: url(../assets/main_page/images/help_close.png)
      no-repeat center;
    background-size: cover;
    z-index: 3;
  }
  .help_close:hover {
    border: 2px solid red; /* 红色边框 */
  }
  .help_add_wechat {
    display: block;
    position: relative;
    height: 25px;
    margin: 8.915px 0 0 119px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    z-index: 2;
  }
  .help_get_materials {
    display: block;
    position: relative;
    height: 25px;
    margin: 10px 0 0 47px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    z-index: 1;
  }
  .help_image {
    position: relative;
    width: 266px;
    height: 363px;
    margin: 23px 0 0 40px;
    background: url(../assets/main_page/images/help_image.png)
      no-repeat center;
    background-size: cover;
  }