:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

.main_container {
  overflow: hidden;
}

.main_container,
.main_container * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.main_container {
  position: relative;
  width: 1280px;
  height: 832px;
  margin: 0 auto;
}
.macbook_air_home {
  position: absolute;
  width: 1280px;
  height: 832px;
  top: 0;
  left: 0;
  background: #f7f7fa;
  overflow: hidden;
}

.help_info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 224px;
  margin: 0 auto;
  padding: 15px 15px 15px 15px;
  top: 560px;
  left: 480px;
  background: #ffffff;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  z-index: 80;
}
.help_info_content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  width: 194px;
  height: 96px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.frame_1 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 30px;
  position: relative;
  width: 270px;
  margin: 787px 0 0 505px;
  z-index: 1;
}
.about_us {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 72px;
  z-index: 2;
}
.about_us_2 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 3;
}
.about_us_3 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 36px;
  z-index: 4;
}
.about_us_terms {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 5;
}
.about_us_4 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 36px;
  z-index: 6;
}
.about_us_privacy {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 7;
}
.about_us_help {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 36px;
  z-index: 8;
}
.about_us_5 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 9;
}
.logo_tentative {
  position: absolute;
  width: 212px;
  height: 120px;
  top: 30px;
  left: 30px;
  background: url(../assets/self_account/images/13d134b8c6623a3c97a4772a4dbec54b24cacf65.png)
    no-repeat center;
  background-size: cover;
  z-index: 34;
  border-radius: 15px;
}
.flex_row_d {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 1222px;
  height: 740px;
  top: 30px;
  left: 30px;
  z-index: 67;
}
.regroup_i {
  flex-shrink: 0;
  position: relative;
  width: 722px;
  height: 740px;
  z-index: 67;
}
.frame_6 {
  position: absolute;
  width: 480px;
  height: 737px;
  top: 0;
  left: 242px;
  background: #ffffff;
  z-index: 67;
  overflow: hidden;
  border-radius: 15px;
}
.frame_7 {
  position: relative;
  width: 336px;
  height: 384px;
  margin: 81px 0 0 72px;
  background: #ffffff;
  z-index: 72;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}
.image {
  position: relative;
  width: 100px;
  height: 95.025px;
  margin: 19px 0 0 118px;
  z-index: 73;
}
.paper_duotone {
  position: absolute;
  width: 100px;
  height: 95.025px;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 74;
  background: url(../assets/self_account/images/Paper_duotone.png)
    no-repeat center;
  background-size: 100% 100%;
}
.vector {
  position: absolute;
  width: 62.5%;
  height: 75%;
  top: 12.5%;
  left: 12.5%;
  background: url(../assets/self_account/images/3253d4ab-8fda-44cb-b8f4-ed8bcd8bbf2c.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 75;
}
.rectangle {
  position: absolute;
  width: 25%;
  height: 25%;
  top: 12.5%;
  left: 62.5%;
  background: url(../assets/self_account/images/139e2c81-18ba-4fa7-9057-06f259e0a912.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 76;
}
.edit_duotone {
  position: absolute;
  width: 66.72%;
  height: 70.21%;
  top: 17.12%;
  left: 36.27%;
  background: url(../assets/self_account/images/20ea9834-6b76-4756-99a0-17beb669fe8d.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 80;
}
.group {
  position: absolute;
  width: 12.5%;
  height: 20.52%;
  top: 17.89%;
  left: 18.5%;
  background: url(../assets/self_account/images/4418aff7-5e17-4ee6-b0f5-cd88679229ec.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 82;
}
.vector_8 {
  position: absolute;
  width: 29.17%;
  height: 0%;
  top: 42.74%;
  left: 22.92%;
  background: url(../assets/self_account/images/540532cf-e1de-4b29-9c12-5ea5eb72ea42.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 77;
}
.vector_9 {
  position: absolute;
  width: 16.67%;
  height: 0%;
  top: 53.14%;
  left: 22.92%;
  background: url(../assets/self_account/images/d3348751-a0be-40ee-ad88-a8fce872bb21.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 78;
}
.ai {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  height: 31.57%;
  top: 61.04%;
  left: 58%;
  color: transparent;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 30px;
  font-weight: 900;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
  background: linear-gradient(180deg, #535f94, #dcdfea);
  z-index: 81;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 2px #ffffff;
}
.vector_a {
  position: absolute;
  width: 25%;
  height: 0%;
  top: 64.58%;
  left: 22.92%;
  background: url(../assets/self_account/images/cf0c87c0-0f8d-44dd-8b8a-6c5f414ec4b0.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 79;
}
.frame_b {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 262px;
  margin: 5.975px 0 0 37px;
  padding: 10px 20px 10px 20px;
  z-index: 83;
}
.frame_c {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 222px;
  z-index: 84;
}
.latest_ai_ielts_speaking_technology {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  z-index: 85;
}
.frame_d {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 27px;
  position: relative;
  width: 221px;
  margin: 20px 0 0 58px;
  z-index: 89;
}
.frame_e {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 97px;
  z-index: 90;
}
.text_with_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 5px;
  position: relative;
  min-width: 0;
  z-index: 91;
}
.done_ring_round {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(../assets/self_account/images/2a0de908-7043-47d3-b9b3-22699cc1e6a7.png)
    no-repeat center;
  background-size: cover;
  z-index: 92;
}
.random_topic_bank {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 27px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  white-space: nowrap;
  z-index: 93;
}
.text_with_icon_f {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 5px;
  position: relative;
  min-width: 0;
  z-index: 94;
}
.done_ring_round_10 {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(../assets/self_account/images/5c9441e3-1cf8-4aa2-8d44-4f23c298d66e.png)
    no-repeat center;
  background-size: cover;
  z-index: 95;
}
.random_topic_bank_11 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 27px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  white-space: nowrap;
  z-index: 96;
}
.frame_12 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 97px;
  z-index: 97;
}
.text_with_icon_13 {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 5px;
  position: relative;
  min-width: 0;
  z-index: 98;
}
.done_ring_round_14 {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(../assets/self_account/images/82d993e8-c9f8-4595-aa5b-492f06f32b58.png)
    no-repeat center;
  background-size: cover;
  z-index: 99;
}
.random_topic_bank_15 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 27px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  white-space: nowrap;
  z-index: 100;
}
.text_with_icon_16 {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 5px;
  position: relative;
  min-width: 0;
  z-index: 101;
}
.done_ring_round_17 {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(../assets/self_account/images/e3e6e8b1-41c4-42d8-9d50-b705f297d0ea.png)
    no-repeat center;
  background-size: cover;
  z-index: 102;
}
.random_topic_bank_18 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 27px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  white-space: nowrap;
  z-index: 103;
}
.frame_19 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 185px;
  margin: 20px 0 0 76px;
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  background: transparent;
  border: none;
  z-index: 86;
}
.my_ai_score_voucher {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 18px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  z-index: 87;
}
.text_c {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 18px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  z-index: 88;
}
.frame_1a {
  position: relative;
  width: 190px;
  height: 42px;
  margin: 14px 0 0 76px;
  cursor: pointer;
  background: transparent;
  border: none;
  z-index: 104;
  overflow: visible auto;
}
.small_button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 174px;
  margin: 0 0 0 0;
  left: -3px;
  padding: 5px 20px 5px 20px;
  background: #505f98;
  z-index: 106;
  border-radius: 15px;
}
.view_sample_score_report {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #ffffff;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 107;
}
.rectangle_1b {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #3e476f;
  z-index: 105;
  border-radius: 15px;
}
.group_1c {
  position: relative;
  width: 166px;
  height: 135px;
  margin: 20px 0 0 30px;
  z-index: 108;
  overflow: visible auto;
}
.frame_1d {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 110px;
  margin: 0 0 0 0;
  padding: 10px 10px 10px 10px;
  z-index: 109;
}
.frame_1e {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 90px;
  z-index: 110;
}
.frame_1e:hover{
  text-decoration: underline;
}
.purchase_score {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #505f98;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 111;
}
.group_1f {
  position: relative;
  width: 166px;
  height: 45px;
  margin: 0 0 0 0;
  z-index: 112;
}
.frame_20 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 146px;
  height: 45px;
  top: 0;
  left: 0;
  padding: 10px 10px 10px 10px;
  z-index: 113;
}
.frame_21 {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 126px;
  z-index: 114;
}
.frame_21:hover{
  text-decoration: underline;
}
.copy_invite_code {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #505f98;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 115;
}
.info {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 12px;
  left: 146px;
  background: url(../assets/self_account/images/abf05b19-a140-401f-add5-bd6770dbfca8.png)
    no-repeat center;
  background-size: cover;
  z-index: 116;
}
.frame_22 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 92px;
  margin: 0 0 0 0;
  padding: 10px 10px 10px 10px;
  z-index: 117;
}
.frame_23 {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 72px;
  z-index: 118;
}
.customer_service_appeal {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #505f98;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 119;
}
.frame_24 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 122px;
  height: 75px;
  top: 0;
  left: 0;
  padding: 25px 25px 25px 25px;
  z-index: 68;
}
.my_subscription {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 72px;
  height: 25px;
  color: #505f98;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 69;
}
.divider {
  position: absolute;
  width: 420px;
  top: 61px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 70;
}
.line {
  position: absolute;
  width: 100.71%;
  height: Infinity%;
  top: -Infinity%;
  left: -0.36%;
  background: url(../assets/self_account/images/52291d8a-2cb9-4bf9-9149-2768ce5234a5.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 71;
}
.sidebar {
  position: absolute;
  width: 212px;
  height: 601px;
  top: 139px;
  left: 0;
  background: #ffffff;
  z-index: 10;
  border-radius: 15px;
}
.frame_25 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 185px;
  height: 211px;
  margin: 20px 0 0 14px;
  z-index: 18;
}
.sidebar_my_homepage {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 15px;
  position: relative;
  width: 185px;
  padding: 10px 15px 10px 15px;
  z-index: 19;
  border-radius: 15px;
}
.sidebar_my_homepage:hover {
  /* opacity: 0.1; */
  background-color: rgba(83, 95, 148, 0.1);
}
.home {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  z-index: 20;
  overflow: hidden;
}
.icon {
  position: relative;
  width: 18.667px;
  height: 17.625px;
  margin: 0.88px 0 0 0.67px;
  background: url(../assets/self_account/images/7b54f114-4bce-488d-bd9e-fdaf1940a840.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 21;
}
.my_homepage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 120px;
  height: 18px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  z-index: 22;
}
.sidebar_my_homepage_26 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 15px;
  position: relative;
  width: 185px;
  padding: 10px 15px 10px 15px;
  z-index: 23;
  border-radius: 15px;
}
.sidebar_my_homepage_26:hover {
  /* opacity: 0.1; */
  background-color: rgba(83, 95, 148, 0.1);
}
.mock_exam {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  z-index: 24;
}
.trophy {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 25;
  overflow: hidden;
}
.icon_27 {
  position: relative;
  width: 18.667px;
  height: 18.667px;
  margin: 0.67px 0 0 0.67px;
  background: url(../assets/self_account/images/83e27fff-1a83-4c6d-8075-4ad722cb79f9.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 26;
}
.my_homepage_28 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 120px;
  height: 18px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  z-index: 27;
}
.sidebar_my_homepage_29 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 15px;
  position: relative;
  width: 185px;
  padding: 10px 15px 10px 15px;
  z-index: 28;
  border-radius: 15px;
}
.sidebar_my_homepage_29:hover {
  /* opacity: 0.1; */
  background-color: rgba(83, 95, 148, 0.1);
}
.oral_practice {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(../assets/self_account/images/763b3adf-0bad-4422-89ea-951f4a991090.png)
    no-repeat center;
  background-size: cover;
  z-index: 29;
}
.my_homepage_oral_practice {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 120px;
  height: 18px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  z-index: 30;
}
.sidebar_my_homepage_2a {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 15px;
  position: relative;
  width: 185px;
  padding: 10px 15px 10px 15px;
  z-index: 31;
  border-radius: 15px;
}
.sidebar_my_homepage_2a:hover {
  /* opacity: 0.1; */
  background-color: rgba(83, 95, 148, 0.1);
}
.mock_exam_record {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(../assets/self_account/images/4973af65-c565-46a7-954e-10167a42cba6.png)
    no-repeat center;
  background-size: cover;
  z-index: 32;
}
.buy_points {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(../assets/main_page/images/buy.png)
    no-repeat center;
  background-size: cover;
  z-index: 32;
}
.my_homepage_mock_exam_history {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 120px;
  height: 18px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  z-index: 33;
}
.divider_2b {
  position: relative;
  width: 150px;
  margin: 167px 0 0 25px;
  background: #f7f7fa;
  z-index: 16;
}
.line_2c {
  position: absolute;
  width: 102%;
  height: Infinity%;
  top: -Infinity%;
  left: -1%;
  background: url(../assets/self_account/images/e70f5447-a8b3-4c8d-a749-6b356dd4b76b.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 17;
}
.sidebar_my_homepage_2d {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  gap: 15px;
  position: relative;
  width: 190px;
  height: 61px;
  margin: 15px 0 0 11px;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
  background: #dcdfea;
  border: none;
  z-index: 13;
  border-radius: 15px;
}
.my_account {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(../assets/self_account/images/0c6e7082-3989-4f98-a7b9-86673b28f460.png)
    no-repeat center;
  background-size: cover;
  z-index: 14;
}
.my_homepage_my_account {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 120px;
  height: 18px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  z-index: 15;
}
.sidebar_my_homepage_2e {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  gap: 15px;
  position: relative;
  width: 50px;
  margin: 83px 0 0 159px;
  padding: 10px 15px 10px 15px;
  z-index: 11;
  border-radius: 15px;
}
.help {
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  background: url(../assets/self_account/images/57a94e90-5174-474d-bed0-dc0e25741e9d.png)
    no-repeat center;
  background-size: cover;
  z-index: 12;
}
.frame_2f {
  flex-shrink: 0;
  position: relative;
  width: 480px;
  height: 737px;
  background: #ffffff;
  z-index: 36;
  overflow: hidden;
  border-radius: 15px;
}
.my_account_30 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 420px;
  margin: 81px 0 0 30px;
  z-index: 41;
}
.frame_31 {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  min-width: 0;
  z-index: 42;
}
.register_email {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 18px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  z-index: 43;
}
.frame_32 {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  min-width: 0;
  height: 48px;
  padding: 15px 10px 15px 10px;
  background: #f7f7f7;
  border: 2px solid #d9d9d9;
  z-index: 44;
  overflow: hidden;
  border-radius: 15px;
}
.frame_input {
  flex-shrink: 0;
  position: absolute;
  width: 420px;
  height: 48px;
  top: -2px;
  left: -2px;
  background: transparent;
  border: none;
  z-index: 46;
  outline: none;
}
.register_email_33 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 18px;
  color: #d9d9d9;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  z-index: 45;
}
.group_34 {
  position: relative;
  width: 92px;
  height: 90px;
  margin: 116px 0 0 30px;
  z-index: 60;
  overflow: visible auto;
}
.frame_35 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 92px;
  margin: 0 0 0 0;
  padding: 10px 10px 10px 10px;
  z-index: 61;
}
.frame_36 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 72px;
  z-index: 62;
}
.purchase_score_37 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #505f98;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 63;
}
.frame_38 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 92px;
  margin: 0 0 0 0;
  padding: 10px 10px 10px 10px;
  z-index: 64;
}
.frame_39 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 72px;
  z-index: 65;
}
.purchase_score_3a {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #505f98;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 66;
}
.frame_3b {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 122px;
  height: 75px;
  top: 0;
  left: 0;
  padding: 25px 25px 25px 25px;
  z-index: 37;
}
.my_account_3c {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 72px;
  height: 25px;
  color: #505f98;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 38;
}
.divider_3d {
  position: absolute;
  width: 420px;
  top: 61px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 39;
}
.line_3e {
  position: absolute;
  width: 100.71%;
  height: Infinity%;
  top: -Infinity%;
  left: -0.36%;
  background: url(../assets/self_account/images/4ceed7d9-8054-4518-b7f3-30e3864c083e.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 40;
}
.my_account_3f {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 420px;
  height: 76px;
  top: 177px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 47;
}
.frame_40 {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  min-width: 0;
  z-index: 48;
}
.register_email_41 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 18px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  z-index: 49;
}
.frame_42 {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  min-width: 0;
  height: 48px;
  padding: 15px 10px 15px 10px;
  background: #f7f7f7;
  border: 2px solid #d9d9d9;
  z-index: 50;
  overflow: hidden;
  border-radius: 15px;
}
.frame_input_43 {
  flex-shrink: 0;
  position: absolute;
  width: 420px;
  height: 48px;
  top: -2px;
  left: -2px;
  background: transparent;
  border: none;
  z-index: 52;
  outline: none;
}
.register_email_44 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 18px;
  color: #d9d9d9;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  text-align: left;
  white-space: nowrap;
  z-index: 51;
}
.component {
  position: absolute;
  cursor: pointer;
  width: 25px;
  height: 20.792px;
  top: 220px;
  left: 409px;
  background: url(../assets/login/images/open.png)
    no-repeat center;
  background-size: contain;
  z-index: 53;
}
.group_45 {
  position: relative;
  width: 20.982px;
  height: 6.234px;
  margin: 0 0 0 2.313px;
  z-index: 54;
}
.ellipse {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../assets/self_account/images/7fdb357a-5adb-4755-87d9-adefd439284c.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 55;
}
.line_46 {
  position: relative;
  width: 4.64px;
  height: 4.64px;
  margin: -3.193px 0 0 0;
  background: url(../assets/self_account/images/ae46ae95-ad51-466f-bb85-8af3ecbdeeb2.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 56;
}
.line_47 {
  position: relative;
  width: 4.64px;
  height: 4.64px;
  margin: -4.64px 0 0 20.36px;
  background: url(../assets/self_account/images/c6a3c5d5-e234-48a6-8257-4345709b3922.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 57;
}
.line_48 {
  position: relative;
  width: 3.112px;
  height: 4.924px;
  margin: -1.827px 0 0 15.223px;
  background: url(../assets/self_account/images/755a80f4-6040-4bf3-873e-d3a26d1a27f5.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 59;
}
.line_49 {
  position: relative;
  width: 3.112px;
  height: 4.924px;
  margin: -4.909px 0 0 7.375px;
  background: url(../assets/self_account/images/c7f3b0b5-9590-46f0-88e4-8ab60b229ceb.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 58;
}
.jump_main_container {
  position: relative;
  top: 220px;
  left: 120px;
  width: 578px;
  height: 356px;
  margin: 0 auto;
  background: #ffffff;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  z-index: 100;
}
.jump_frame {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: relative;
  width: 308px;
  margin: 0 0 0 135px;
  padding: 10px 10px 10px 10px;
  z-index: 81;
}
 .jump_ready_to_go {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 54px;
  color: #535f94;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 36px;
  font-weight: 900;
  line-height: 54px;
  text-align: left;
  white-space: nowrap;
  z-index: 82;
}
.jump_line {
  position: relative;
  width: 521px;
  height: 3px;
  margin: -0.5px 0 0 28.5px;
  /* background: url(../assets/main_page/images/1136faa2-3059-4018-90f1-a03906521e42.png) */
    /* no-repeat center; */
  background-size: 100% 100%;
  z-index: 90;
}
 .jump_text_2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 520px;
  height: 81px;
  margin: 13.5px 0 0 29px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
}
 .jump_frame_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  width: 253px;
  margin: 10px 0 0 162px;
  cursor: pointer;
  background: #f7f7fa;
  border: none;
  z-index: 83;
  border-radius: 15px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}
 .jump_frame_div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 164px;
  padding: 5px 0 5px 0;
  z-index: 84;
}
 .jump_my_points {
  flex-shrink: 0;
  position: relative;
  width: 164px;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 85;
}
 .jump_my_points_1 {
  position: relative;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
}
.jump_points {
  position: relative;
  color: #535f94;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
}
 .jump_frame_2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 110px;
  padding: 0 10px 0 10px;
  z-index: 86;
}
 .jump_frame_3 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 72px;
  z-index: 87;
}
 .jump_purchase_score {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #505f98;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 88;
}
 .jump_flex_row_a {
  position: relative;
  width: 473px;
  height: 52px;
  margin: 22px 0 0 53px;
  z-index: 98;
}
 .jump_small_button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 47.15%;
  height: 88.71%;
  top: 0;
  left: 0;
  padding: 10px 20px 10px 20px;
  background: #505f98;
  z-index: 94;
  border-radius: 15px;
}
 .jump_i_am_thinking {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #ffffff;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 95;
}
 .jump_small_button_4 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  position: absolute;
  width: 47.15%;
  height: 88.71%;
  top: 0;
  left: 52.85%;
  padding: 10px 20px 10px 20px;
  background: #d453a8;
  z-index: 98;
  border-radius: 15px;
}
 .jump_start_mock_exam {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 25px;
  color: #ffffff;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 99;
}
.jump_rectangle {
  position: absolute;
  width: 47.15%;
  height: 95.16%;
  top: 4.84%;
  left: 0;
  background: #3e476f;
  z-index: 93;
  border-radius: 15px;
}
.jump_rectangle_5 {
position: absolute;
width: 47.15%;
height: 95.16%;
top: 4.84%;
left: 52.85%;
background: #782c5e;
z-index: 97;
border-radius: 15px;
}
.help_main_container {
  left: 100px;
  top: 150px;
  position: relative;
  width: 346px;
  height: 491px;
  margin: 0 auto;
  background: #ffffff;
  overflow: hidden;
  border-radius: 15px;
  z-index: 80;
}
.help_close {
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 20.085px;
  margin: 16px 0 0 310px;
  background: url(../assets/main_page/images/help_close.png)
    no-repeat center;
  background-size: cover;
  z-index: 3;
}
.help_close:hover {
  border: 2px solid red; /* 红色边框 */
}
.help_add_wechat {
  display: block;
  position: relative;
  height: 25px;
  margin: 8.915px 0 0 119px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 2;
}
.help_get_materials {
  display: block;
  position: relative;
  height: 25px;
  margin: 10px 0 0 47px;
  color: #000000;
  font-family: Nunito Sans, var(--default-font-family);
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  z-index: 1;
}
.help_image {
  position: relative;
  width: 266px;
  height: 363px;
  margin: 23px 0 0 40px;
  background: url(../assets/main_page/images/help_image.png)
    no-repeat center;
  background-size: cover;
}