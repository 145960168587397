:root {
    --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
      "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
      "Source Han Sans CN", sans-serif;
  }
  
  .main_container {
    overflow: hidden;
  }
  
  .main_container,
  .main_container * {
    box-sizing: border-box;
  }
  
  input,
  select,
  textarea,
  button {
    outline: 0;
  }
  
  .main_container {
    position: relative;
    width: 1440px;
    height: 1024px;
    margin: 0 auto;
    background: #ffffff;
    overflow: hidden;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }
  .rectangle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 1440px;
    height: 100px;
    margin: 0 0 0 1px;
    background: #505f98;
    z-index: 24;
  }
  .koi_speak {
    flex-shrink: 0;
    position: relative;
    height: 55px;
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 40px;
    font-weight: 900;
    line-height: 54.56px;
    text-align: left;
    white-space: nowrap;
    z-index: 24;
  }
  .part_one {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    width: 207px;
    height: 55px;
    margin: 30px 0 0 617px;
    color: #505f98;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 40px;
    font-weight: 900;
    line-height: 54.56px;
    text-align: center;
    white-space: nowrap;
    z-index: 2;
  }
  .interview_and_introduction {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    width: 333px;
    height: 27px;
    margin: 10px 0 0 554px;
    color: #505f98;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 27px;
    text-align: center;
    white-space: nowrap;
    z-index: 1;
  }
  .rectangle_1 {
    position: relative;
    width: 411.429px;
    height: 240px;
    margin: 50px 0 0 514px;
    background: #d9d9d9;
    z-index: 4;
    overflow: visible auto;
  }
  .ellipse {
    position: relative;
    width: 99.318px;
    height: 100px;
    margin: 40px 0 0 154.397px;
    background: url(../assets/record_pages/part1/2622cbfd-be5c-4781-89c8-632bdcf14d6b.png)
      no-repeat center;
    background-size: cover;
    z-index: 6;
  }
  .rectangle_2 {
    position: relative;
    width: 173.798px;
    height: 87.5px;
    margin: 12.5px 0 0 117.157px;
    background: url(../assets/record_pages/part1/7bb9505f-12cb-432e-82c5-a68edc297142.png)
      no-repeat center;
    background-size: cover;
    z-index: 7;
  }
  .rectangle_3 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 1354px;
    height: 83px;
    margin: 50px 0 0 50px;
    /* justify-content: center;  */
    border: 5px solid #505f98;
    z-index: 11;
    border-radius: 15px;
  }
  .click_to_show {
    flex-shrink: 0;
    position: relative;
    height: 30.621px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 24px;
    font-weight: 900;
    line-height: 30.621px;
    text-align: left;
    white-space: nowrap;
    z-index: 11;
  }
  .flex_row {
    position: relative;
    overflow: hidden;
    width: 896px;
    height: 96px;
    margin: 55px 0 0 272px;
    z-index: 21;
  }
  .mic {
    position: absolute;
    height: 96px;
    top: 0;
    right: 400px;
    left: 400px;
    background: url(../assets/record_pages/part1/2b4b258a-bd46-427b-823d-787be7bddb97.png)
      no-repeat center;
    background-size: cover;
    z-index: 28;
  }
  .line {
    position: absolute;
    width: 899px;
    height: 3px;
    top: 47px;
    left: 50%;
    background: url(../assets/record_pages/part1/b5e052c0-085f-49f0-a2fb-7eaa920e436c.png)
      no-repeat center;
    background-size: cover;
    transform: translate(-50%, 0);
    z-index: 20;
  }
  .span {
    display: block;
    position: relative;
    height: 55px;
    margin: 19px 0 0 679px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 40px;
    font-weight: 900;
    line-height: 54.56px;
    text-align: left;
    white-space: nowrap;
    z-index: 8;
  }
  .flex_row_cc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 810px;
    height: 60px;
    margin: 30px 0 0 540px;
    z-index: 15;
  }
  .frame {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 360px;
    height: 60px;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    background: #505F98;
    border: none;
    z-index: 55;
    border-radius: 30px;
  }
  .start_recording {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 41px;
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 30px;
    font-weight: 900;
    line-height: 40.92px;
    text-align: left;
    white-space: nowrap;
    z-index: 13;
  }
  .rectangle_4 {
    flex-shrink: 0;
    position: relative;
    width: 150px;
    height: 60px;
    background: #d9d9d9;
    z-index: 15;
    border-radius: 30px;
  }
  .next {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 30px;
    top: calc(50% - 15px);
    left: calc(50% - 46px);
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 24px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
    white-space: nowrap;
    z-index: 17;
  }
  .flex_column {
    position: absolute;
    width: 9px;
    height: 18px;
    top: 21px;
    left: 113px;
    z-index: 19;
  }
  .line_5 {
    position: relative;
    width: 11.385px;
    height: 12.081px;
    margin: -1.754px 0 0 -1.192px;
    background: url(../assets/record_pages/part1/c841a140-9680-437f-99d1-c6cde1178b53.png)
      no-repeat center;
    background-size: cover;
    z-index: 18;
  }
  .line_6 {
    position: relative;
    width: 10.906px;
    height: 12.1px;
    margin: -2.662px 0 0 -1.178px;
    background: url(../assets/record_pages/part1/09d01957-f602-40c4-891b-1a13b76945a8.png)
      no-repeat center;
    background-size: cover;
    z-index: 19;
  }
  .finish_main_container {
    /* position: relative; */
    width: 767px;
    height: 501px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* 将元素移动到其自身的中心 */
    z-index: 100;
  }
  .finish_icon {
    position: relative;
    width: 134px;
    height: 105px;
    margin: 50px 0 0 317px;
    z-index: 4;
  }
  .finish_group {
    position: absolute;
    width: 100%;
    height: 97.14%;
    top: 0;
    left: 0;
    background: url(../assets/record_pages/part3/50b4f265-ba9c-4fcd-ac3e-861e5ae1a2c6.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 5;
  }
  .finish_koi_speak {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    width: 86px;
    height: 60px;
    top: 45px;
    left: 0;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 24px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
    text-overflow: initial;
    z-index: 6;
    overflow: hidden;
  }
  .finish_recordings_uploaded {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    width: 605px;
    height: 114px;
    margin: 52px 0 0 81px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 28px;
    font-weight: 400;
    line-height: 38.192px;
    text-align: left;
    z-index: 1;
  }
  .finish_close_page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: relative;
    width: 308px;
    height: 60px;
    margin: 70px 0 0 230px;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    background: #505f98;
    border: none;
    z-index: 2;
    border-radius: 15px;
  }
  .finish_close_page_1 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 33px;
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 24px;
    font-weight: 900;
    line-height: 32.736px;
    text-align: left;
    white-space: nowrap;
    z-index: 3;
  }
  .finish_rectangle {
    position: absolute;
    width: 767px;
    height: 501px;
    top: 50%;
    left: 50%;
    background: #dfe1eb;
    border: 1px solid #505f98;
    transform: translate(-50%, -50%);
    border-radius: 20px;
  }
  