:root {
    --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
      "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
      "Source Han Sans CN", sans-serif;
  }
  
  .main_container {
    overflow: hidden;
  }
  
  .main_container,
  .main_container * {
    box-sizing: border-box;
  }
  
  input,
  select,
  textarea,
  button {
    outline: 0;
  }
  
  .main_container {
    position: relative;
    top: -650px;
    left: 120px;
    width: 672px;
    height: 561px;
    margin: 0 auto;
    background: #ffffff;
    overflow: hidden;
    border-radius: 15px;
    opacity: 1;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    z-index: 100;
  }
  .frame {
    position: relative;
    width: 596px;
    height: 162px;
    margin: 40px 0 0 40px;
    background: #dfe1eb;
    z-index: 8;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  }
  .flex_row_f {
    position: absolute;
    height: 130px;
    top: 12px;
    right: 20px;
    left: 20px;
    z-index: 15;
  }
  .info {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 120px;
    background: url(../assets/direction/images/c4fc8b5e-fb21-469c-9a93-2bd2389b19c3.png)
      no-repeat center;
    background-size: cover;
    z-index: 15;
  }
  .your_points {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    height: 42px;
    top: 4px;
    left: 0;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 30px;
    font-weight: 900;
    line-height: 42px;
    text-align: left;
    white-space: nowrap;
    z-index: 9;
  }
  .group {
    position: absolute;
    width: 137px;
    height: 126px;
    top: 50%;
    right: 0;
    transform: translate(0, -48.41%);
    z-index: 11;
  }
  .buy_points {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 100%;
    height: 88.71%;
    top: 0;
    left: 0;
    padding: 10px 20px 10px 20px;
    background: #505f98;
    z-index: 13;
    border-radius: 15px;
  }
  .buy_points_1 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 25px;
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    z-index: 14;
  }
  .rectangle {
    position: absolute;
    width: 100%;
    height: 95.16%;
    top: 4.84%;
    left: 0;
    background: #3e476f;
    z-index: 12;
    border-radius: 15px;
  }
  .span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    height: 90px;
    top: 71px;
    left: 20px;
    color: #505f98;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 64px;
    font-weight: 900;
    line-height: 89.6px;
    text-align: left;
    white-space: nowrap;
    z-index: 10;
  }
  .flex_row_f_2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 596px;
    height: 186px;
    margin: 43px 0 0 40px;
    z-index: 29;
  }
  .frame_3 {
    flex-shrink: 0;
    position: relative;
    width: 180px;
    height: 186px;
    background: #ffffff;
    z-index: 16;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  }
  .frame_4 {
    position: relative;
    width: 77.571px;
    height: 65px;
    margin: 30px 0 0 51px;
    z-index: 17;
  }
  .icon_ai {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 18;
  }
  .group_5 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 21;
    border-radius: 1px;
  }
  .vector {
    position: relative;
    width: 69.5px;
    height: 12.269px;
    margin: -2.5px 0 0 3.071px;
    background: url(../assets/direction/images/de2f33ee-da20-491e-89bb-2ad0017ac041.png)
      no-repeat center;
    background-size: cover;
    z-index: 24;
  }
  .vector_6 {
    position: relative;
    width: 82.501px;
    height: 10.137px;
    margin: 6.548px 0 0 -2.429px;
    background: url(../assets/direction/images/ccc1f39d-0241-4eac-ba37-bcc37ee88bf2.png)
      no-repeat center;
    background-size: cover;
    z-index: 22;
  }
  .ai {
    display: block;
    position: relative;
    height: 16px;
    margin: 22.546px 0 0 53.072px;
    font-family: Oxanium, var(--default-font-family);
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    background: linear-gradient(180deg, #505f98, #d4d7dd);
    z-index: 23;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .group_7 {
    position: absolute;
    width: 44.158px;
    height: 44.158px;
    top: 2px;
    left: 17.072px;
    background: url(../assets/direction/images/1f1a8f4a-caba-4759-9f22-e5e6ff73199c.png)
      no-repeat center;
    background-size: cover;
    z-index: 20;
  }
  .rectangle_8 {
    position: absolute;
    width: 22px;
    height: 25px;
    top: 36px;
    left: 28px;
    background: #505f98;
    z-index: 19;
    border-radius: 5px;
  }
  .ai_score_report {
    position: relative;
    width: 148px;
    height: 48px;
    margin: 19px 0 0 16px;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    z-index: 25;
  }
  .obtain {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .ai_score_report_9 {
    position: relative;
    color: #505f98;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 900;
    line-height: 24px;
    text-align: left;
  }
  .improvement_suggestions {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .frame_a {
    flex-shrink: 0;
    position: relative;
    width: 180px;
    height: 186px;
    background: #ffffff;
    z-index: 29;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  }
  .group_b {
    position: relative;
    width: 104.529px;
    height: 67px;
    margin: 29px 0 0 38px;
    z-index: 31;
  }
  .ellipse {
    position: absolute;
    width: 99.529px;
    height: 63.951px;
    top: 0;
    left: 50%;
    background: url(../assets/direction/images/248912f0-3d9e-46a5-8689-397836b8e3a1.png)
      no-repeat center;
    background-size: cover;
    transform: translate(-47.49%, 0);
    z-index: 33;
  }
  .ellipse_c {
    position: absolute;
    width: 83px;
    height: 54px;
    top: 13px;
    left: 50%;
    background: url(../assets/direction/images/b7cee5bf-40f0-4839-bcd1-6ca58533c8f1.png)
      no-repeat center;
    background-size: cover;
    transform: translate(-62.97%, 0);
    z-index: 34;
  }
  .ielts {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    height: 30px;
    top: 25px;
    left: calc(50% - 30.26px);
    color: #000000;
    font-family: Nunito, var(--default-font-family);
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
    white-space: nowrap;
    z-index: 32;
  }
  .ielts_speaking_exam {
    position: relative;
    width: 148px;
    height: 48px;
    margin: 17px 0 0 16px;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    z-index: 30;
  }
  .one_on_one {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .ielts_speaking {
    position: relative;
    color: #505f98;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 900;
    line-height: 24px;
    text-align: left;
  }
  .mock_exam_15_minutes {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .frame_d {
    flex-shrink: 0;
    position: relative;
    width: 180px;
    height: 186px;
    background: #ffffff;
    z-index: 26;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  }
  .frame_e {
    position: relative;
    width: 72px;
    height: 58px;
    margin: 30px 0 0 54px;
    background: url(../assets/direction/images/f212861b-7ae1-401f-a3f2-7a68a825e6f7.png)
      no-repeat center;
    background-size: cover;
    z-index: 27;
  }
  .quiet_stable_network {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 148px;
    height: 48px;
    margin: 25px 0 0 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    z-index: 28;
  }
  .flex_row_ae {
    position: relative;
    width: 592px;
    height: 52px;
    margin: 38px 0 0 40px;
    z-index: 6;
  }
  .cancel_button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 17.23%;
    height: 88.71%;
    cursor: pointer;
    top: 0;
    left: 0;
    padding: 10px 20px 10px 20px;
    background: #505f98;
    z-index: 2;
    border-radius: 15px;
  }
  .cancel {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 25px;
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    z-index: 3;
  }
  .small_button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 46.62%;
    height: 88.71%;
    top: 0;
    left: 53.38%;
    padding: 10px 20px 10px 20px;
    background: #d453a8;
    z-index: 6;
    border-radius: 15px;
  }
  .prepare_microphone_test {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 25px;
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    z-index: 7;
  }
  .rectangle_f {
    position: absolute;
    width: 17.23%;
    height: 95.16%;
    top: 4.84%;
    left: 0;
    background: #3e476f;
    z-index: 1;
    border-radius: 15px;
  }
  .rectangle_10 {
    position: absolute;
    width: 46.62%;
    height: 95.16%;
    top: 4.84%;
    left: 53.38%;
    background: #782c5e;
    z-index: 5;
    border-radius: 15px;
  }
  .cancel_button:hover,
  .small_button:hover,
  .buy_points:hover {
    transform: scale(0.95); /* 移除translate位移 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s ease; /* 添加过渡动画 */
  }