:root {
    --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
      "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
      "Source Han Sans CN", sans-serif;
  }
  
  .main_container {
    overflow: hidden;
  }
  
  .main_container,
  .main_container * {
    box-sizing: border-box;
  }
  
  input,
  select,
  textarea,
  button {
    outline: 0;
  }
  
  .main_container {
    position: relative;
    width: 1440px;
    height: 1024px;
    margin: 0 auto;
    background: #ffffff;
    overflow: hidden;
  }
  .rectangle {
    position: relative;
    width: 1440px;
    height: 100px;
    margin: 0 0 0 0;
    background: #505f98;
    z-index: 2;
  }
  .koi_speak {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 55px;
    top: calc(50% - 28px);
    left: calc(50% - 110px);
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 40px;
    font-weight: 900;
    line-height: 54.56px;
    text-align: left;
    white-space: nowrap;
    z-index: 23;
  }
  .koi_speak_1 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 55px;
    top: calc(50% - 28px);
    left: calc(50% - 110px);
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 40px;
    font-weight: 900;
    line-height: 54.56px;
    text-align: left;
    white-space: nowrap;
    z-index: 24;
  }
  .koi_speak_2 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 55px;
    top: calc(50% - 28px);
    left: calc(50% - 110px);
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 40px;
    font-weight: 900;
    line-height: 54.56px;
    text-align: left;
    white-space: nowrap;
    z-index: 25;
  }
  .ielts_mock_test {
    display: block;
    position: relative;
    height: 41px;
    margin: 50px 0 0 587px;
    color: #6a77a7;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 30px;
    font-weight: 900;
    line-height: 40.92px;
    text-align: left;
    white-space: nowrap;
  }
  .button_frame {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: relative;
    width: 340px;
    height: 80px;
    left: 500px;
    top: 42px;
    margin: 663px 0 0 567px;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    background: #505f98;
    border: none;
    opacity: 0.3;
    z-index: 49;
    border-radius: 15px;
  }
  .troubleshoot_wrapper {
    position: absolute;
    /* bottom: 100px; */
    left: 32%;
    top: -125px;
    transform: translateX(-50%);
    text-align: left;
    margin-bottom: 20px; /* 与按钮保持间距 */
    z-index: 1000;
  }
  .troubleshoot_tip {
    display: inline-block;
    cursor: help;
    font-weight: 700;
    text-decoration: underline;
    color: black;
    font-size: 28px;
  }
  
  /* 调整提示框位置 */
  .troubleshoot_tip:hover::after {
    content: "如果耳机或者麦克风无法正常收音或者录音，请尝试以下步骤：\A 1. 确保你的音响/耳机/麦克风已正确连接\A 2. 请确认浏览器是否允许使用耳机/麦克风\A 3.尝试更换浏览器";
    white-space: pre-wrap;
    position: absolute;
    width: 300px;
    background: #ffffcc;
    border: 1px solid #ffd700;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.2);
    left: 200px;
    top: 0;
    font-weight: normal;
    text-decoration: none;
    color: #333;
    font-size: 14px;
    line-height: 1.6;
    z-index: 9999;
  }
  .back_to_main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: relative;
    width: 340px;
    height: 80px;
    margin: 663px 0 0 567px;
    top: -700px;
    left: -510px;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    background: #505f98;
    border: none;
    z-index: 49;
    border-radius: 15px;
    z-index: 100;
  }
  .bottom_section {
    position: relative;
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: -280px;
    gap: 20px;
  }
  .enter_interview_room {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 30px;
    color: #ffffff;
    font-family: Nunito, var(--default-font-family);
    font-size: 30px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    white-space: nowrap;
    z-index: 50;
  }
  .rectangle_3 {
    position: absolute;
    width: 1070px;
    height: 260px;
    top: 214px;
    left: 50%;
    background: #f7f7fa;
    transform: translate(-63.08%, 0);
    z-index: 10;
    border-radius: 20px;
  }
  .flex_row_e {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 394px;
    height: 49.677px;
    margin: 27px 0 0 35px;
    z-index: 22;
  }
  .headphone_icon {
    flex-shrink: 0;
    position: relative;
    width: 55px;
    height: 49.677px;
    background: url(../assets/record_pages/test_equipment/6a6cf7fb-d62e-4bf4-ba58-3d7034350b1c.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 22;
  }
  .headphone_check {
    flex-shrink: 0;
    position: relative;
    height: 41px;
    color: #505f98;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 30px;
    font-weight: 900;
    line-height: 40.92px;
    text-align: left;
    white-space: nowrap;
    z-index: 21;
  }
  .flex_row_e_4 {
    position: relative;
    width: 973px;
    height: 60px;
    margin: 14.323px 0 0 35px;
    z-index: 19;
    overflow: visible auto;
  }
  .play_button {
    position: relative;
    width: 24px;
    height: 24px;
    /* left: 5px; */
    margin: 0 0 0 717px;
    background: url(../assets/record_pages/test_equipment/06c18be2-acd9-4db3-960d-37ba41bc74d4.png)
      no-repeat center;
    background-size: cover;
    z-index: 19;
  }
  .sound_quality_test {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    width: 973px;
    height: 54px;
    top: 0;
    left: 0;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 27.28px;
    text-align: left;
    z-index: 18;
  }
  .rectangle_5 {
    position: relative;
    width: 1000px;
    height: 60px;
    margin: 25px 0 0 35px;
    background: #dfe1eb;
    z-index: 12;
    border-radius: 45px;
  }
  .play_button_6 {
    position: absolute;
    cursor: pointer;
    width: 40px;
    height: 40px;
    top: 9.305px;
    left: 20.855px;
    background: url(../assets/record_pages/test_equipment/2b95b266-9993-4f78-9353-663e8f3720f7.png)
      no-repeat center;
    background-size: cover;
    z-index: 13;
  }
  .play_button_6.playing {
    background: url(../assets/record_pages/test_equipment/start.png) 
    no-repeat center;
    background-size: cover;
  }
  .time_display {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 17px;
    top: 21px;
    left: 70.854px;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    white-space: nowrap;
    z-index: 16;
  }
  .ellipse {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 21px;
    left: 196.594px;
    background: url(../assets/record_pages/test_equipment/32eac836-989d-4201-aa4c-78126e8d6dd3.png)
      no-repeat center;
    background-size: cover;
    z-index: 15;
    transition: left 0.2s ease;
    border-radius: 50%;
  }
  
  .line {
    position: absolute;
    width: 758px;
    height: 8px;
    top: 27px;
    left: 197.584px;
    background: url(../assets/record_pages/test_equipment/a24a7cb8-b7bc-4623-ab8b-7dc863e2a677.png)
      no-repeat center;
    background-size: cover;
    z-index: 14;
  }
  .rectangle_7 {
    position: absolute;
    cursor: pointer;
    width: 254px;
    height: 76px;
    top: 398px;
    left: 1150px;
    background: #f7f7fa;
    z-index: 5;
    border-radius: 15px;
  }
  .headphone {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 30px;
    top: calc(50% - 15px);
    left: 44px;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 30px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
    white-space: nowrap;
    z-index: 7;
  }
  .rectangle_8 {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 12px;
    border: 2px solid #505f98;
    transform: translate(0, -50%);
    z-index: 8;
    border-radius: 5px;
  }
  .rectangle_8.checked::after {
    content: '✔';  /* 使用勾号作为打钩的标识 */
    position: absolute;
    top: 50%;  /* 垂直居中 */
    left: 50%; /* 水平居中 */
    font-size: 16px; /* 调整勾号的大小 */
    color: black;  /* 勾选的颜色 */
    transform: translate(-50%, -50%);  /* 精确居中 */
  }
  .line_9 {
    position: absolute;
    width: 205px;
    height: 10px;
    top: 36px;
    left: 41px;
    background: url(../assets/record_pages/test_equipment/ce9f9576-f99b-4460-9d35-9af7d6b83337.png)
      no-repeat center;
    background-size: cover;
    z-index: 6;
  }
  .rectangle_a {
    position: absolute;
    width: 1070px;
    height: 292px;
    top: 512px;
    left: 45px;
    background: #f7f7fa;
    opacity: 0.3;
    z-index: 34;
    border-radius: 20px;
  }
  .rectangle_b {
    position: relative;
    width: 1000px;
    height: 60px;
    margin: 202px 0 0 30px;
    background: #dfe1eb;
    z-index: 36;
    border-radius: 45px;
  }
  .play_button_c {
    position: absolute;
    cursor: pointer;
    width: 40px;
    height: 40px;
    top: 9px;
    left: 75px;
    background: url(../assets/record_pages/test_equipment/a1744274-000a-40b2-bafe-0cbdcf342ce3.png)
      no-repeat center;
    background-size: cover;
    z-index: 40;
  }
  .record_button {
    position: absolute;
    cursor: pointer;
    width: 40px;
    height: 40px;
    top: 9px;
    left: 20px;
    background: url(../assets/record_pages/test_equipment/00ba4996-f30a-4a5a-a02a-2748d6e13779.png)
      no-repeat center;
    background-size: cover;
    z-index: 41;
  }
  .ellipse_d {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 251px;
    background: url(../assets/record_pages/test_equipment/93280881-cdc6-4f07-9e52-018abe291a57.png)
      no-repeat center;
    background-size: cover;
    transform: translate(0, -50%);
    z-index: 38;
    border-radius: 50%;
  }
  .time_display_e {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 17px;
    top: calc(50% - 9px);
    left: 130px;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    white-space: nowrap;
    z-index: 39;
  }
  .line_f {
    position: absolute;
    width: 690px;
    height: 8px;
    top: 50%;
    left: 266px;
    background: url(../assets/record_pages/test_equipment/53aa30b4-0924-4804-b723-e302183ebe76.png)
      no-repeat center;
    background-size: cover;
    transform: translate(0, -50%);
    z-index: 37;
  }
  .mic_icon {
    position: absolute;
    width: 3.74%;
    height: 20.55%;
    top: 7.4%;
    left: 3.27%;
    background: url(../assets/record_pages/test_equipment/b8126e59-db58-429b-af40-f84c2f4360f4.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 47;
    border-radius: 20px;
  }
  .microphone_check {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 42.64px;
    top: 31.2px;
    left: 113px;
    color: #505f98;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 30px;
    font-weight: 900;
    line-height: 40.92px;
    text-align: left;
    white-space: nowrap;
    z-index: 48;
  }
  .record_play_buttons {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    width: 995px;
    height: 81px;
    top: 99px;
    left: 35px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 20px;
    font-weight: 400;
    line-height: 27.28px;
    text-align: left;
    z-index: 43;
  }
  .record_button_10 {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 101px;
    left: 490px;
    background: url(../assets/record_pages/test_equipment/627c12ae-5395-413b-9738-9f409a5a9411.png)
      no-repeat center;
    background-size: cover;
    z-index: 45;
  }
  .play_button_11 {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 127px;
    left: 454px;
    background: url(../assets/record_pages/test_equipment/22c4875d-aca0-4af7-8a79-c958397b5fb0.png)
      no-repeat center;
    background-size: cover;
    z-index: 44;
  }
  .rectangle_12 {
    position: absolute;
    cursor: pointer;
    width: 265px;
    height: 76px;
    top: 728px;
    left: 1150px;
    background: #f7f7fa;
    opacity: 0.3;
    z-index: 27;
    border-radius: 15px;
  }
  .microphone {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    height: 30px;
    top: calc(50% - 15px);
    left: 44px;
    color: #505f98;
    font-family: Nunito, var(--default-font-family);
    font-size: 30px;
    font-weight: 900;
    line-height: 30px;
    text-align: left;
    white-space: nowrap;
    z-index: 29;
  }
  .rectangle_13 {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 26px;
    left: 12px;
    border: 2px solid #505f98;
    z-index: 31;
    border-radius: 5px;
  }
  .rectangle_13.checked::after {
    content: '✔';  /* 使用勾号作为打钩的标识 */
    position: absolute;
    top: 50%;  /* 垂直居中 */
    left: 50%; /* 水平居中 */
    font-size: 16px; /* 调整勾号的大小 */
    color: black;  /* 勾选的颜色 */
    transform: translate(-50%, -50%);  /* 精确居中 */
  }
  .line_14 {
    position: absolute;
    width: 210px;
    height: 10px;
    top: 36px;
    left: 41px;
    background: url(../assets/record_pages/test_equipment/29b5e0a4-5d48-4cb9-9bc3-c0f231d8fbad.png)
      no-repeat center;
    background-size: cover;
    z-index: 28;
  }
  