:root {
    --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
      "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
      "Source Han Sans CN", sans-serif;
  }
  
  .main_container {
    overflow: hidden;
  }
  
  .main_container,
  .main_container * {
    box-sizing: border-box;
  }
  
  input,
  select,
  textarea,
  button {
    outline: 0;
  }
  
  .main_container {
    position: relative;
    width: 1280px;
    height: 832px;
    margin: 0 auto;
  }

  .dim_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }
  .macbook_air_home_page {
    position: absolute;
    width: 1280px;
    height: 832px;
    top: 0;
    left: 0;
    background: #f7f7fa;
    overflow: hidden;
  }
  .frame_1 {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 30px;
    position: relative;
    width: 270px;
    margin: 787px 0 0 505px;
    z-index: 1;
  }
  .about_us {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 72px;
    z-index: 2;
  }
  .about_us_2 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 25px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    z-index: 3;
  }
  .about_us_3 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 36px;
    z-index: 4;
  }
  .about_us_terms {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 25px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    z-index: 5;
  }
  .about_us_privacy {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 36px;
    z-index: 6;
  }
  .about_us_help {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 25px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    z-index: 7;
  }
  .about_us_4 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 36px;
    z-index: 8;
  }
  .logo_tentative {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 25px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    z-index: 9;
  }
  .flex_row_de {
    position: absolute;
    width: 212px;
    height: 120px;
    top: 30px;
    left: 30px;
    background: url(../assets/test_history/images/13d134b8c6623a3c97a4772a4dbec54b24cacf65.png)
      no-repeat center;
    background-size: cover;
    z-index: 34;
    border-radius: 15px;
  }
  .frame_5 {
    position: absolute;
    width: 1220px;
    height: 746px;
    top: 30px;
    left: 30px;
    z-index: 35;
  }
  .frame_6 {
    position: absolute;
    width: 976px;
    height: 746px;
    top: 0;
    left: 244px;
    background: #ffffff;
    z-index: 35;
    overflow: hidden;
    border-radius: 15px;
  }
  .my_mock_exam_records {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 5px;
    position: relative;
    width: 173px;
    margin: 5px 0 0 5px;
    padding: 20px 20px 20px 20px;
    z-index: 36;
  }
  .info {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 18px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: left;
    white-space: nowrap;
    z-index: 37;
  }
  .frame_7 {
    flex-shrink: 0;
    position: relative;
    width: 20px;
    height: 20px;
    background: url(../assets/test_history/images/d811028d-f6b8-4afb-ba76-cce745d89022.png)
      no-repeat center;
    background-size: cover;
    z-index: 88;
  }
  .help_info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 224px;
    margin: 0 auto;
    padding: 15px 15px 15px 15px;
    top: 45px;
    left: 440px;
    background: #ffffff;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
    z-index: 80;
  }
  .help_info_content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    width: 194px;
    height: 96px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .frame_8 {
    position: relative;
    width: 936px;
    height: 612px;
    margin: 4px 0 0 20px;
    background: #f7f7fa;
    z-index: 39;
    overflow: hidden;
    border-radius: 15px;
  }
  .time {
    position: relative;
    width: 849px;
    height: 18px;
    margin: 34px 0 0 44px;
    z-index: 40;
  }
  .test_paper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    height: 18px;
    top: 0;
    left: 0;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: left;
    white-space: nowrap;
    z-index: 41;
  }
  .section_topic {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    height: 18px;
    top: 0;
    left: 280px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: left;
    white-space: nowrap;
    z-index: 42;
  }
  .score {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    height: 18px;
    top: 0;
    left: 500px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: left;
    white-space: nowrap;
    z-index: 43;
  }
  .frame_9 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    height: 18px;
    top: 0;
    left: 773px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: left;
    white-space: nowrap;
    z-index: 44;
  }
  .frame_a {
    position: relative;
    width: 936px;
    height: 49px;
    margin: 21px 0 0 0;
    /* background: #dfe1eb; */
    /* z-index: 45; */
  }
  .frame_a:hover {
    background-color: #dfe1eb; /* 鼠标悬停时的背景色 */
}
  .hover_tooltip {
    display: none;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    opacity: 1;
    width: 224px;
    height: 126px;
    border-radius: 15px;
    border: 2px solid gray; /* 红色边框 */
    top: -30px;
    left: 45%;
    background-color: white;
    padding: 10px 10px 10px 10px;
    /* transform: translate(802.33%, -48.61%); */
    z-index: 9999;
  }

  .frame_a:hover .hover_tooltip {
    display: block; /* 悬停时显示 */
  }


  .date_time {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 133px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-323.31%, -48.61%);
    z-index: 46;
  }
  .cambridge_ielts_test {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    left: -20px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 47;
  }
  .frame_b {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 152px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-192.11%, -48.61%);
    z-index: 48;
  }
  .travel_school_memory_childhood {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    left: 90px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 49;
  }
  .frame_c {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 260px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-41.54%, -48.61%);
    z-index: 50;
  }
  .score_d {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    left: 120px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 51;
  }
  .frame_e {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 43px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(802.33%, -48.61%);
    z-index: 52;
  }
  .lock_icon {
    width: 24px;
    height: 24px;
    /* border: none; */
    /* background: url(../assets/test_history/images/Lock_fill.png)
      no-repeat center;
    background-size: cover; */
  }
  .text_d {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 53;
  }
  .hidden_text {
    font-size: 0;
    color: transparent;
  }
  .group_3 {
    position: relative;
    width: 936px;
    height: 49px;
    margin: 0 0 0 0;
    background: #f7f7fa;
    z-index: 54;
  }
  .frame_f {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 133px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-323.31%, -48.61%);
    z-index: 55;
  }
  .span {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 56;
  }
  .frame_10 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 152px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-192.11%, -48.61%);
    z-index: 57;
  }
  .span_11 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 58;
  }
  .frame_12 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 260px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-41.54%, -48.61%);
    z-index: 59;
  }
  .span_13 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 60;
  }
  .frame_14 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 48px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(713.54%, -48.61%);
    z-index: 61;
  }
  .span_15 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 62;
  }
  .flex_row_ffa {
    position: relative;
    width: 936px;
    height: 49px;
    margin: 0 0 0 0;
    z-index: 72;
  }
  .frame_16 {
    position: absolute;
    width: 936px;
    height: 49px;
    top: 0;
    left: 50%;
    background: #f7f7fa;
    transform: translate(-50%, 0);
    z-index: 63;
  }
  .frame_17 {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 133px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-323.31%, -48.61%);
    z-index: 64;
  }
  .span_18 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 65;
  }
  .frame_19 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 152px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-192.11%, -48.61%);
    z-index: 66;
  }
  .span_1a {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 67;
  }
  .frame_1b {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 260px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-41.54%, -48.61%);
    z-index: 68;
  }
  .span_1c {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 69;
  }
  .frame_1d {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 43px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(802.33%, -48.61%);
    z-index: 70;
  }
  .span_1e {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 71;
  }
  .frame_1f {
    position: absolute;
    width: 936px;
    height: 49px;
    top: 0;
    left: 50%;
    background: #f7f7fa;
    transform: translate(-50%, 0);
    z-index: 72;
  }
  .frame_20 {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 133px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-323.31%, -48.61%);
    z-index: 73;
  }
  .span_21 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 74;
  }
  .frame_22 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 152px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-192.11%, -48.61%);
    z-index: 75;
  }
  .span_23 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 76;
  }
  .frame_24 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 260px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-41.54%, -48.61%);
    z-index: 77;
  }
  .span_25 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 78;
  }
  .frame_26 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 43px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(802.33%, -48.61%);
    z-index: 79;
  }
  .span_27 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 80;
  }
  .frame_28 {
    position: relative;
    width: 936px;
    height: 49px;
    margin: 0 0 0 0;
    background: #f7f7fa;
    z-index: 81;
  }
  .frame_29 {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 133px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-323.31%, -48.61%);
    z-index: 82;
  }
  .span_2a {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 83;
  }
  .frame_2b {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 152px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-192.11%, -48.61%);
    z-index: 84;
  }
  .span_2c {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 85;
  }
  .frame_2d {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 260px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-41.54%, -48.61%);
    z-index: 86;
  }
  .text_1c {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 87;
  }
  .wrapper_b {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 43px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(802.33%, -48.61%);
    z-index: 88;
  }
  .text_1d {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 89;
  }
  .section_8 {
    position: relative;
    width: 936px;
    height: 49px;
    margin: 0 0 0 0;
    background: #f7f7fa;
    z-index: 90;
  }
  .wrapper_c {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 133px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-323.31%, -48.61%);
    z-index: 91;
  }
  .text_1e {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 92;
  }
  .box_c {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 152px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-192.11%, -48.61%);
    z-index: 93;
  }
  .text_1f {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 94;
  }
  .section_9 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 260px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-41.54%, -48.61%);
    z-index: 95;
  }
  .text_20 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 96;
  }
  .group_9 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 43px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(802.33%, -48.61%);
    z-index: 97;
  }
  .text_21 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 98;
  }
  .group_a {
    position: relative;
    width: 936px;
    height: 49px;
    margin: 0 0 0 0;
    background: #f7f7fa;
    z-index: 99;
  }
  .group_b {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 133px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-323.31%, -48.61%);
    z-index: 100;
  }
  .text_22 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 101;
  }
  .group_c {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 152px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-192.11%, -48.61%);
    z-index: 102;
  }
  .text_23 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 103;
  }
  .group_d {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 260px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-41.54%, -48.61%);
    z-index: 104;
  }
  .text_24 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 105;
  }
  .group_e {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 43px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(802.33%, -48.61%);
    z-index: 106;
  }
  .text_25 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 107;
  }
  .wrapper_d {
    position: relative;
    width: 936px;
    height: 49px;
    margin: 0 0 0 0;
    background: #f7f7fa;
    z-index: 108;
  }
  .section_a {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 133px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-323.31%, -48.61%);
    z-index: 109;
  }
  .text_26 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 110;
  }
  .section_b {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 152px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-192.11%, -48.61%);
    z-index: 111;
  }
  .text_27 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 112;
  }
  .section_c {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 260px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-41.54%, -48.61%);
    z-index: 113;
  }
  .text_28 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 114;
  }
  .wrapper_e {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 43px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(802.33%, -48.61%);
    z-index: 115;
  }
  .text_29 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 116;
  }
  .wrapper_f {
    position: relative;
    width: 936px;
    height: 49px;
    margin: 0 0 0 0;
    background: #f7f7fa;
    z-index: 117;
  }
  .wrapper_10 {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 133px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-323.31%, -48.61%);
    z-index: 118;
  }
  .text_2a {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 119;
  }
  .frame_2e {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 152px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-192.11%, -48.61%);
    z-index: 120;
  }
  .cambridge_ielts_test_2f {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 121;
  }
  .frame_30 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 260px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-41.54%, -48.61%);
    z-index: 122;
  }
  .travel_school_memory {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 123;
  }
  .frame_31 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 43px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(802.33%, -48.61%);
    z-index: 124;
  }
  .score_32 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 125;
  }
  .frame_33 {
    position: relative;
    width: 936px;
    height: 49px;
    margin: 0 0 0 0;
    background: #f7f7fa;
    z-index: 126;
  }
  .frame_34 {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 133px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-323.31%, -48.61%);
    z-index: 127;
  }
  .date_time_35 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 128;
  }
  .frame_36 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 152px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-192.11%, -48.61%);
    z-index: 129;
  }
  .cambridge_ielts_test_37 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 130;
  }
  .frame_38 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 260px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-41.54%, -48.61%);
    z-index: 131;
  }
  .travel_school_memory_39 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 132;
  }
  .frame_3a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 43px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(802.33%, -48.61%);
    z-index: 133;
  }
  .score_3b {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 134;
  }
  .frame_3c {
    position: relative;
    width: 936px;
    height: 49px;
    margin: 0 0 0 0;
    background: #f7f7fa;
    z-index: 135;
  }
  .frame_3d {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 133px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-323.31%, -48.61%);
    z-index: 136;
  }
  .date_time_3e {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 137;
  }
  .frame_3f {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 152px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-192.11%, -48.61%);
    z-index: 138;
  }
  .cambridge_ielts_test_40 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 139;
  }
  .frame_41 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 260px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-41.54%, -48.61%);
    z-index: 140;
  }
  .travel_school_memory_42 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 141;
  }
  .frame_43 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 43px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(802.33%, -48.61%);
    z-index: 142;
  }
  .score_44 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 143;
  }
  .frame_45 {
    position: relative;
    width: 936px;
    height: 49px;
    margin: 0 0 0 0;
    background: #f7f7fa;
    z-index: 144;
  }
  .frame_46 {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 133px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-323.31%, -48.61%);
    z-index: 145;
  }
  .date_time_47 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 146;
  }
  .frame_48 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 152px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-192.11%, -48.61%);
    z-index: 147;
  }
  .cambridge_ielts_test_49 {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 148;
  }
  .frame_4a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 260px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(-41.54%, -48.61%);
    z-index: 149;
  }
  .travel_school_memory_4b {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 150;
  }
  .frame_4c {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 43px;
    height: 36px;
    top: 50%;
    left: 50%;
    padding: 10px 10px 10px 10px;
    transform: translate(802.33%, -48.61%);
    z-index: 151;
  }
  .score_4d {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 16px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 152;
  }
  .frame_4e {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 30px;
    position: relative;
    width: 205px;
    margin: 15px 0 0 386px;
    z-index: 153;
  }
  .group {
    flex-shrink: 0;
    position: relative;
    width: 40px;
    height: 40px;
    background: url(../assets/test_history/images/left_yes.png)
      no-repeat center;
    background-size: cover;
    /* background-size: 100% 10%; */
    z-index: 154;
  }
  .group.disabled {
    background-image: url(../assets/test_history/images/left_no.png);
  }
  .frame_4f {
    
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 15px;
    position: relative;
    width: 85px;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 12px;
    font-weight: 600;
    z-index: 155;
  }

  .group_53 {
    flex-shrink: 0;
    right: 60px;
    position: relative;
    width: 40px;
    height: 40px;
    background: url(../assets/test_history/images/right_yes.png)
      no-repeat center;
    background-size: cover;
    z-index: 160;
  }
  .group_53.disabled {
    background-image: url(../assets/test_history/images/right_no.png);
  }
  .sidebar {
    position: absolute;
    width: 212px;
    height: 601px;
    top: 139px;
    left: 0;
    background: #ffffff;
    z-index: 10;
    border-radius: 15px;
  }
  .frame_54 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 10px;
    position: relative;
    width: 185px;
    height: 211px;
    margin: 20px 0 0 14px;
    z-index: 18;
  }
  .sidebar_home {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 15px;
    position: relative;
    width: 185px;
    padding: 10px 15px 10px 15px;
    z-index: 19;
    border-radius: 15px;
  }
  .sidebar_home:hover {
    /* opacity: 0.1; */
    background-color: rgba(83, 95, 148, 0.1);
  }
  .home {
    flex-shrink: 0;
    position: relative;
    width: 20px;
    height: 20px;
    z-index: 20;
    overflow: hidden;
  }
  .icon {
    position: relative;
    width: 18.667px;
    height: 17.625px;
    margin: 0.88px 0 0 0.67px;
    background: url(../assets/test_history/images/7dedd3da-526c-4072-967d-fe0672c38c4f.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 21;
  }
  .my_page {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 120px;
    height: 18px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: left;
    white-space: nowrap;
    z-index: 22;
  }
  .sidebar_home_55 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 15px;
    position: relative;
    width: 185px;
    padding: 10px 15px 10px 15px;
    z-index: 23;
    border-radius: 15px;
  }
  .sidebar_home_55:hover {
    /* opacity: 0.1; */
    background-color: rgba(83, 95, 148, 0.1);
  }
  .mock_exam {
    flex-shrink: 0;
    position: relative;
    width: 20px;
    height: 20px;
    z-index: 24;
  }
  .trophy {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 25;
    overflow: hidden;
  }
  .icon_56 {
    position: relative;
    width: 18.667px;
    height: 18.667px;
    margin: 0.67px 0 0 0.67px;
    background: url(../assets/test_history/images/4fa5cf1c-0a11-4679-8bcb-865a422f9f60.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 26;
  }
  .my_page_57 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 120px;
    height: 18px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: left;
    white-space: nowrap;
    z-index: 27;
  }
  .sidebar_home_58 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 15px;
    position: relative;
    width: 185px;
    padding: 10px 15px 10px 15px;
    z-index: 28;
    border-radius: 15px;
  }
  .sidebar_home_58:hover {
    /* opacity: 0.1; */
    background-color: rgba(83, 95, 148, 0.1);
  }
  .oral_practice {
    flex-shrink: 0;
    position: relative;
    width: 20px;
    height: 20px;
    background: url(../assets/test_history/images/37190013-ca33-4414-8d32-00c2765122bd.png)
      no-repeat center;
    background-size: cover;
    z-index: 29;
  }
  .my_page_59 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 120px;
    height: 18px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: left;
    white-space: nowrap;
    z-index: 30;
  }
  .sidebar_home_5a {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 15px;
    position: relative;
    width: 185px;
    padding: 10px 15px 10px 15px;
    cursor: pointer;
    background: #dcdfea;
    border: none;
    z-index: 31;
    border-radius: 15px;
  }
  .mock_exam_record {
    flex-shrink: 0;
    position: relative;
    width: 20px;
    height: 20px;
    background: url(../assets/test_history/images/280fd0ca-18b2-47a1-8797-7ad98f4cde16.png)
      no-repeat center;
    background-size: cover;
    z-index: 32;
  }
  .buy_points {
    flex-shrink: 0;
    position: relative;
    width: 20px;
    height: 20px;
    background: url(../assets/main_page/images/buy.png)
      no-repeat center;
    background-size: cover;
    z-index: 32;
  }
  .my_page_5b {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 120px;
    height: 18px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: left;
    white-space: nowrap;
    z-index: 33;
  }
  .divider {
    position: relative;
    width: 150px;
    margin: 167px 0 0 25px;
    background: #f7f7fa;
    z-index: 16;
  }
  .line {
    position: absolute;
    width: 102%;
    height: Infinity%;
    top: -Infinity%;
    left: -1%;
    background: url(../assets/test_history/images/bd6f3a7a-d7ad-4cd0-b08e-657d8b70c14a.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 17;
  }
  .sidebar_home_5c {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    gap: 15px;
    position: relative;
    width: 190px;
    height: 61px;
    margin: 15px 0 0 11px;
    padding: 10px 15px 10px 15px;
    z-index: 13;
    border-radius: 15px;
  }
  .sidebar_home_5c:hover {
    /* opacity: 0.1; */
    background-color: rgba(83, 95, 148, 0.1);
  }
  .my_account {
    flex-shrink: 0;
    position: relative;
    width: 20px;
    height: 20px;
    background: url(../assets/test_history/images/9f423df6-e6eb-4732-971e-f912cdc3e7c5.png)
      no-repeat center;
    background-size: cover;
    z-index: 14;
  }
  .my_page_5d {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    width: 120px;
    height: 18px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: left;
    white-space: nowrap;
    z-index: 15;
  }
  .sidebar_home_5e {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    gap: 15px;
    position: relative;
    width: 50px;
    margin: 83px 0 0 159px;
    padding: 10px 15px 10px 15px;
    z-index: 11;
    border-radius: 15px;
  }
  .help {
    flex-shrink: 0;
    position: relative;
    width: 20px;
    height: 20px;
    background: url(../assets/test_history/images/d9aec345-7cdd-47cd-be58-fe4a75418c54.png)
      no-repeat center;
    background-size: cover;
    z-index: 12;
  }
  .jump_main_container {
    position: relative;
    top: 220px;
    left: 120px;
    width: 578px;
    height: 356px;
    margin: 0 auto;
    background: #ffffff;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    z-index: 100;
  }
  .jump_frame {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: relative;
    width: 308px;
    margin: 0 0 0 135px;
    padding: 10px 10px 10px 10px;
    z-index: 81;
  }
   .jump_ready_to_go {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 54px;
    color: #535f94;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 36px;
    font-weight: 900;
    line-height: 54px;
    text-align: left;
    white-space: nowrap;
    z-index: 82;
  }
  .jump_line {
    position: relative;
    width: 521px;
    height: 3px;
    margin: -0.5px 0 0 28.5px;
    /* background: url(../assets/main_page/images/1136faa2-3059-4018-90f1-a03906521e42.png) */
      /* no-repeat center; */
    background-size: 100% 100%;
    z-index: 90;
  }
   .jump_text_2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 520px;
    height: 81px;
    margin: 13.5px 0 0 29px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
  }
   .jump_frame_button {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    width: 253px;
    margin: 10px 0 0 162px;
    cursor: pointer;
    background: #f7f7fa;
    border: none;
    z-index: 83;
    border-radius: 15px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  }
   .jump_frame_div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 164px;
    padding: 5px 0 5px 0;
    z-index: 84;
  }
   .jump_my_points {
    flex-shrink: 0;
    position: relative;
    width: 164px;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    text-overflow: initial;
    white-space: nowrap;
    z-index: 85;
  }
   .jump_my_points_1 {
    position: relative;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
  }
  .jump_points {
    position: relative;
    color: #535f94;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
  }
   .jump_frame_2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 110px;
    padding: 0 10px 0 10px;
    z-index: 86;
  }
   .jump_frame_3 {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 72px;
    z-index: 87;
  }
   .jump_purchase_score {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 25px;
    color: #505f98;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    z-index: 88;
  }
   .jump_flex_row_a {
    position: relative;
    width: 473px;
    height: 52px;
    margin: 22px 0 0 53px;
    z-index: 98;
  }
   .jump_small_button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 47.15%;
    height: 88.71%;
    top: 0;
    left: 0;
    padding: 10px 20px 10px 20px;
    background: #505f98;
    z-index: 94;
    border-radius: 15px;
  }
   .jump_i_am_thinking {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 25px;
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    z-index: 95;
  }
   .jump_small_button_4 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    position: absolute;
    width: 47.15%;
    height: 88.71%;
    top: 0;
    left: 52.85%;
    padding: 10px 20px 10px 20px;
    background: #d453a8;
    z-index: 98;
    border-radius: 15px;
  }
   .jump_start_mock_exam {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 25px;
    color: #ffffff;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    z-index: 99;
  }
  .jump_rectangle {
    position: absolute;
    width: 47.15%;
    height: 95.16%;
    top: 4.84%;
    left: 0;
    background: #3e476f;
    z-index: 93;
    border-radius: 15px;
  }
  .jump_rectangle_5 {
  position: absolute;
  width: 47.15%;
  height: 95.16%;
  top: 4.84%;
  left: 52.85%;
  background: #782c5e;
  z-index: 97;
  border-radius: 15px;
  }
  .help_main_container {
    left: 100px;
    top: 150px;
    position: relative;
    width: 346px;
    height: 491px;
    margin: 0 auto;
    background: #ffffff;
    overflow: hidden;
    border-radius: 15px;
    z-index: 80;
  }
  .help_close {
    cursor: pointer;
    position: relative;
    width: 20px;
    height: 20.085px;
    margin: 16px 0 0 310px;
    background: url(../assets/main_page/images/help_close.png)
      no-repeat center;
    background-size: cover;
    z-index: 3;
  }
  .help_close:hover {
    border: 2px solid red; /* 红色边框 */
  }
  .help_add_wechat {
    display: block;
    position: relative;
    height: 25px;
    margin: 8.915px 0 0 119px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    z-index: 2;
  }
  .help_get_materials {
    display: block;
    position: relative;
    height: 25px;
    margin: 10px 0 0 47px;
    color: #000000;
    font-family: Nunito Sans, var(--default-font-family);
    font-size: 18px;
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    z-index: 1;
  }
  .help_image {
    position: relative;
    width: 266px;
    height: 363px;
    margin: 23px 0 0 40px;
    background: url(../assets/main_page/images/help_image.png)
      no-repeat center;
    background-size: cover;
  }

  